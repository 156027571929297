<template>
	<block-sidebar title="Riepilogo" v-if="!!datas && !!datas.length">
		<template v-slot:icon>
			<font-awesome-icon :icon="['fad', 'info']"></font-awesome-icon>
		</template>

		<table class="table border">
			<tbody>
				<tr v-for="(item, index) in datas" :key="index">
					<td :colspan="item.value == null ? 2 : 1">
						<b v-if="item.label != null">{{ item.label }}</b>
					</td>
					<td v-html="item.value" v-if="item.value != null"></td>
				</tr>
			</tbody>
		</table>
	</block-sidebar>
</template>

<script>
export default {
	data: () => ({
		datas: null,
	}),
	props: {
		refTable: {
			type: String,
			default: '',
			required: true,
		},
	},
	mounted: function() {
		if (typeof this.getTable !== 'undefined') this.datas = this.getTable.summary

		let _that = this

		setInterval(function() {
			if (typeof _that.getTable !== 'undefined') {
				_that.datas = _that.getTable.summary
			}
		}, 250)
	},
	computed: {
		getTable: function() {
			return this.$root.$refs[this.refTable]
		},
	},
}
</script>
