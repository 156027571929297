<template>
	<li :class="classObject" :data-row="rowIndex">
		<b v-if="!!col.title">{{ col.title }}:</b>
		<span v-if="!!custom && !!custom.name && !!custom.props">
			<component v-bind:is="custom.name" v-bind="custom.props"></component>
			<span v-if="!!custom.html" v-html="custom.html"></span>
		</span>
		<span v-else v-html="!!custom || custom == '' ? custom : item"></span>
	</li>
</template>

<script>
export default {
	props: {
		row: {
			type: Object,
			default: null,
			required: true,
		},
		col: {
			type: Object,
			default: null,
			required: true,
		},
		rowIndex: {
			type: Number,
			default: 0,
			required: true,
		},
		colIndex: {
			type: Number,
			default: 0,
			required: true,
		},
		customRender: {
			type: Function,
			default: null,
			required: false,
		},
	},
	computed: {
		classObject: function() {
			return {
				[this.col.class]: !!this.col.class,
				icon:
					!!this.custom &&
					!!this.custom.name &&
					this.custom.name.toLowerCase() == 'font-awesome-icon',
			}
		},
		item: function() {
			if (!!this.col.data && typeof this.row[this.col.data] !== 'undefined')
				return this.row[this.col.data]
			return null
		},
		custom: function() {
			if (typeof this.customRender === 'function') {
				return this.customRender(this.item, this.row, {
					row: this.rowIndex,
					col: this.colIndex,
				})
			}
			return null
		},
	},
}
</script>
