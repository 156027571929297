<template>
	<div id="brand" :class="classObject">
		<img class="logo" :src="getLogoWhite" :alt="sitename" v-if="!!white && !!logowhite" />
		<img class="logo" :src="getLogo" :alt="sitename" v-else-if="!white && !!logo" />

		<template v-else>
			<span class="sitename headline-font">{{ sitename }}</span>
			<div class="separator" v-if="!!sitesub"></div>
			<span class="sitesub headline-font" v-if="!!sitesub">{{ sitesub }}</span>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		sitename: {
			type: String,
			default: '',
			required: true,
		},
		sitesub: {
			type: String,
			default: '',
			required: false,
		},
		logo: {
			type: String,
			default: '',
			required: false,
		},
		logowhite: {
			type: String,
			default: '',
			required: false,
		},
		logoicon: {
			type: String,
			default: '',
			required: false,
		},
		logoiconwhite: {
			type: String,
			default: '',
			required: false,
		},
		full: {
			type: Boolean,
			default: false,
			required: false,
		},
		white: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	computed: {
		classObject: function() {
			return {
				small: !!this.getSmall,
				white: !!this.white,
				full: !!this.full,
			}
		},
		getSmall: function() {
			return !this.full && !!this.$root.asideCompressed && !this.$root.asideHover
		},
		getLogo: function() {
			return !!this.getSmall && !!this.logoicon ? this.logoicon : this.logo
		},
		getLogoWhite: function() {
			return !!this.getSmall && !!this.logoiconwhite ? this.logoiconwhite : this.logowhite
		},
	},
}
</script>
