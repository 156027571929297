<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Allegati al Rilascio">
		<form :id="refForm" :ref="refForm">
			<form-file
				label="File"
				id="file_id"
				name="file_id"
				:value="getValue('', {})"
				required
			></form-file>
		</form>

		<template v-slot:footer>
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'notes',
		refForm: '',
		urlupdate: '',
		id: '',
		datas: {},
	}),
	props: {
		urlstore: {
			type: String,
			default: '',
			required: true,
		},
		reftable: {
			type: String,
			default: '',
			required: false,
		},
	},
	created: function() {
		this.refForm = this.ref + 'DataForm'
	},
	methods: {
		close: function() {
			this.assignData({})
			this.urlupdate = ''

			this.$refs[this.ref].close()
		},
		open: function(data) {
			if (!!data && typeof data === 'object') {
				if (!!data.urlupdate) this.urlupdate = data.urlupdate
				if (!!data.datas) this.assignData(data.datas)
			} else if (!!data && Array.isArray(data)) {
				this.assignData(data)
			} else {
				this.assignData({})
			}

			this.$refs[this.ref].open()
		},
		getValue: function(key, defaultValue) {
			if (key === '') return this.datas
			else if (!!this.datas[key]) return this.datas[key]
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		getAction: function() {
			return !!this.id ? this.urlupdate : this.urlstore
		},
		getMethod: function() {
			return !!this.id ? 'PATCH' : 'POST'
		},
		assignData: function(datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
				if (!!datas.id) this.id = datas.id
			} else {
				this.datas = {}
				this.id = ''
			}
		},
		submitForm: function() {
			return this.$root.request(
				this,
				this.getAction(),
				this.getMethod(),
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function(data) {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function() {
				self.close()
			})
		},
	},
}
</script>
