<template>
	<div v-if="!!show" :key="keybase">
		<form-select
			v-if="col.formType.toLowerCase() == 'select'"
			:name="name"
			:label="label"
			:options="col.formOptions"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:multiple="col.formMultiple"
			:value="value()"
			:values="values()"
			:table-ref="refTable"
			:table-filter-type="col.filterType"
			ref="filter_1"
		></form-select>
		<form-autocomplete
			v-else-if="col.formType.toLowerCase() == 'autocomplete'"
			:name="name"
			:label="label"
			:options="col.formOptions"
			:url="col.formOptionsUrl"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:multiple="col.formMultiple"
			:value="value()"
			:values="values()"
			:table-ref="refTable"
			:table-filter-type="col.filterType"
			ref="filter_1"
		></form-autocomplete>
		<form-segment
			v-else-if="col.formType.toLowerCase() == 'segment'"
			:name="name"
			:label="label"
			:options="col.formOptions"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:value="value()"
			:table-ref="refTable"
			:table-filter-type="col.filterType"
			ref="filter_1"
		></form-segment>
		<form-checkbox
			v-else-if="col.formType.toLowerCase() == 'checkbox'"
			:name="name"
			:label="label"
			:options="col.formOptions"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:value="value()"
			:values="values()"
			:table-ref="refTable"
			:table-filter-type="col.filterType"
			ref="filter_1"
		></form-checkbox>
		<form-radio
			v-else-if="col.formType.toLowerCase() == 'radio'"
			:name="name"
			:label="label"
			:options="col.formOptions"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:value="value()"
			:table-ref="refTable"
			:table-filter-type="col.filterType"
			ref="filter_1"
		></form-radio>
		<form-textarea
			v-else-if="col.formType.toLowerCase() == 'textarea'"
			:name="name"
			:label="label"
			:value="value()"
			:table-ref="refTable"
			:table-filter-type="col.filterType"
			ref="filter_1"
		></form-textarea>
		<div v-else-if="col.formType.toLowerCase() == 'date'">
			<form-input
				:type="col.formType"
				:name="name + '[0]'"
				placeholder="Da"
				:showFormLink="false"
				:value="value(name + '[0]')"
				:table-ref="refTable"
				table-filter-type="GREATER"
				:label="label"
				ref="filter_1"
			></form-input>
			<form-input
				:type="col.formType"
				:name="name + '[1]'"
				placeholder="A"
				:showFormLink="false"
				:value="value(name + '[1]')"
				:table-ref="refTable"
				table-filter-type="LESS"
				ref="filter_2"
			></form-input>
		</div>
		<div v-else-if="col.formType.toLowerCase() == 'date_single'">
			<form-input
				type="date"
				:name="name"
				:label="label"
				placeholder="Data"
				:showFormLink="false"
				:value="value()"
				:table-ref="refTable"
				:table-filter-type="col.filterType"
				ref="filter_1"
			></form-input>
		</div>
		<form-input
			v-else
			:type="col.formType"
			:name="name"
			:label="label"
			:showFormLink="false"
			:value="value()"
			:min="col.formNumberMin"
			:max="col.formNumberMax"
			:step="col.formNumberStep"
			:table-ref="refTable"
			:table-filter-type="col.filterType"
			ref="filter_1"
		></form-input>
	</div>
</template>

<script>
export default {
	data: () => ({
		keybase: 0,
	}),
	props: {
		refTable: {
			type: String,
			default: '',
			required: true,
		},
		col: {
			type: Object,
			default: null,
			required: true,
		},
		params: {
			type: Array,
			default: null,
			required: false,
		},
		keybaseChange: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	computed: {
		show: function() {
			if (!this.col.formData && !this.col.data) return false
			if (!!this.col.filterHidden) return false
			if (!this.col.title && !this.col.filterTitle) return false

			return true
		},
		name: function() {
			return !!this.col.formData ? this.col.formData : !!this.col.data ? this.col.data : ''
		},
		label: function() {
			return !!this.col.filterTitle
				? this.col.filterTitle
				: !!this.col.title
				? this.col.title
				: ''
		},
	},
	methods: {
		value: function(name = null) {
			if (typeof name === 'undefined' || !name) name = this.name

			let index = this.$root.issetValueInArray(this.params, 'field_original', name)

			if (index === false || typeof this.params[index].value === 'undefined') {
				name = this.name
				index = this.$root.issetValueInArray(this.params, 'field_original', name)

				if (index === false || typeof this.params[index].value === 'undefined') return null
			}

			/*if (!!this.keybaseChange) {
				this.keybase = Math.random() * Date.now()
			}*/

			return typeof this.params[index].value === 'string' ||
				typeof this.params[index].value === 'number'
				? this.params[index].value
				: null
		},
		values: function(name = null) {
			if (typeof name === 'undefined' || !name) name = this.name

			let index = this.$root.issetValueInArray(this.params, 'field_original', name)

			if (index === false || typeof this.params[index].value === 'undefined') {
				name = this.name
				index = this.$root.issetValueInArray(this.params, 'field_original', name)

				if (index === false || typeof this.params[index].value === 'undefined') return null
			}

			/*if (!!this.keybaseChange) {
				this.keybase = Math.random() * Date.now()
			}*/

			return typeof this.params[index].value === 'array' ? this.params[index].value : null
		},
		getFilters: function() {
			return Object.keys(this.$refs).filter(element =>
				element.toUpperCase().indexOf('FILTER_') !== -1 ? true : false
			)
		},
		reset: function() {
			let filters = this.getFilters()

			for (let index = 0; index < filters.length; index++) {
				if (typeof this.$refs[filters[index]] === 'undefined') continue

				if (
					typeof this.$refs[filters[index]][0] !== 'undefined' &&
					this.$refs[filters[index]][0].reset instanceof Function
				) {
					this.$refs[filters[index]][0].reset()
				} else if (this.$refs[filters[index]].reset instanceof Function) {
					this.$refs[filters[index]].reset()
				}
			}
		},
	},
}
</script>
