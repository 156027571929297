<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Anagrafica" large>
		<form :id="refForm" :ref="refForm">
			<input type="hidden" name="pa" value="0" />
			<div class="row">
				<div class="col-lg-4">
					<form-select
						id="registry_type"
						name="registry_type"
						label="Tipo"
						:options="[
							{ id: '0', label: 'Inquilino' },
							{ id: '1', label: 'Autorizzato' },
						]"
						item-value="id"
						item-label="label"
						required
						v-if="!!showType && !id"
					></form-select>
					<form-input
						type="hidden"
						id="type"
						name="type"
						:value="getValue('type', '1')"
						required
						v-else
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-4">
					<form-segment
						id="persona_giuridica"
						name="persona_giuridica"
						label="Persona Giuridica"
						:options="[
							{ value: '1', label: 'Si' },
							{ value: '0', label: 'No' },
						]"
						:value="getValue('persona_giuridica')"
						required
					></form-segment>
				</div>
				<div class="col-lg-8">
					<form-input
						label="Ragione Sociale"
						id="ragione_sociale"
						name="ragione_sociale"
						:value="getValue('ragione_sociale')"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-4">
					<form-input
						label="Nome"
						id="first_name"
						name="first_name"
						:value="getValue('first_name')"
						required
					></form-input>
				</div>
				<div class="col-lg-4">
					<form-input
						label="Cognome"
						id="last_name"
						name="last_name"
						:value="getValue('last_name')"
						required
					></form-input>
				</div>
				<div class="col-lg-4">
					<form-select
						id="sesso"
						name="sesso"
						label="Sesso"
						:options="[
							{ id: 'M', label: 'Maschio' },
							{ id: 'F', label: 'Femmina' },
						]"
						item-value="id"
						item-label="label"
						:value="getValue('sesso')"
					></form-select>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-4">
					<form-input
						type="date"
						placeholder="YYYY-MM-DD"
						label="Data di Nascita"
						id="data_nascita"
						name="data_nascita"
						:value="getValue('data_nascita_formatted')"
					></form-input>
				</div>
				<div class="col-lg-4">
					<form-autocomplete
						id="luogo_nascita_raw"
						name="luogo_nascita_raw"
						label="Luogo di Nascita"
						:value="getValue('luogo_nascita_raw')"
					></form-autocomplete>
				</div>
				<div class="col-lg-4">
					<form-input
						label="Telefono"
						id="phone"
						name="phone"
						:value="getValue('phone')"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-4">
					<form-input
						label="Codice Fiscale"
						id="codice_fiscale"
						name="codice_fiscale"
						:value="getValue('codice_fiscale')"
					></form-input>
				</div>
				<div class="col-lg-8">
					<form-textarea
						id="note"
						name="note"
						label="Note"
						:value="getValue('note')"
					></form-textarea>
				</div>
			</div>
		</form>

		<template v-slot:footer>
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'tenant',
		refForm: '',
		urlupdate: '',
		id: '',
		datas: {},
	}),
	props: {
		urlstoreT: {
			type: String,
			default: '',
			required: true,
		},
		urlstoreA: {
			type: String,
			default: '',
			required: true,
		},
		municipalities: {
			type: String,
			default: '',
			required: true,
		},
		showType: {
			type: Boolean,
			default: true,
			required: false,
		},
		reftable: {
			type: String,
			default: '',
			required: false,
		},
	},
	created: function () {
		this.refForm = this.ref + 'DataForm'
	},
	computed: {
		store: function () {
			let type = document.getElementById('registry_type').value
			return !!parseInt(type) ? this.urlstoreA : this.urlstoreT
		},
		method: function () {
			return !!this.id ? 'PATCH' : 'POST'
		},
		action: function () {
			return !!this.id ? this.urlupdate : this.store
		},
	},
	methods: {
		close: function () {
			this.assignData({})
			this.urlupdate = ''

			this.$refs[this.ref].close()
		},
		open: function (data) {
			if (!!data && typeof data === 'object') {
				if (!!data.urlupdate) this.urlupdate = data.urlupdate
				if (!!data.datas) this.assignData(data.datas)
			} else if (!!data && Array.isArray(data)) {
				this.assignData(data)
			} else {
				this.assignData({})
			}

			this.$refs[this.ref].open()
		},
		getValue: function (key, defaultValue) {
			if (!!this.datas[key] || this.datas[key] === 0) return this.datas[key].toString()
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		assignData: function (datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
				if (!!datas.id) this.id = datas.id
			} else {
				this.datas = {}
				this.id = ''
			}
		},
		submitForm: function () {
			return this.$root.request(
				this,
				this.action,
				this.method,
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function (data) {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function () {
				self.close()
			})
		},
	},
}
</script>
