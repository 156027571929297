var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('file-modal',{ref:"file_modal",attrs:{"afterupload":_vm.afterUpload,"multiple":_vm.multiple}}),_vm._v(" "),_c('label',[_vm._v(_vm._s(_vm.label)+" "),(!!_vm.required)?_c('span',{staticClass:"form-required"},[_vm._v("*")]):_vm._e()]),_vm._v(" "),(
			(!_vm.multiple && !!_vm.selectedItems) ||
				(!!Array.isArray(_vm.selectedItems) && !!_vm.selectedItems.length)
		)?_c('div',{staticClass:"custom-file-upload",class:{
			'is-invalid': !!_vm.error,
			readonly: !!_vm.readonly,
			disabled: !!_vm.disabled,
			multiple: !!_vm.multiple,
		},attrs:{"id":_vm.getId}},[(!!_vm.multiple)?_c('div',_vm._l((_vm.selectedItems),function(element,index){return _c('span',{staticClass:"custom-file-element"},[_c('a',{attrs:{"href":element.url}},[_vm._v(_vm._s(element.filename))]),_vm._v(" "),(!!_vm.canRemove && !_vm.disabled && !_vm.readonly)?_c('button',{staticClass:"custom-file-element-remove",attrs:{"title":"Rimuovi"},on:{"click":() => _vm.removeFile(index)}},[_c('font-awesome-icon',{attrs:{"icon":['fad', 'times']}})],1):_vm._e()])}),0):_c('span',{staticClass:"custom-file-element"},[_c('a',{attrs:{"href":_vm.selectedItems.url}},[_vm._v(_vm._s(_vm.selectedItems.filename))]),_vm._v(" "),(!!_vm.canRemove && !_vm.disabled && !_vm.readonly)?_c('btn',{attrs:{"type":"danger","light":"","icon":['fad', 'times'],"label":"Rimuovi","action":() => _vm.removeFile(false)}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),(
			!!_vm.multiple ||
				!_vm.selectedItems ||
				(!!Array.isArray(_vm.selectedItems) && !_vm.selectedItems.length)
		)?_c('div',{staticClass:"custom-file-upload",class:{
			'is-invalid': !!_vm.error,
			readonly: !!_vm.readonly,
			disabled: !!_vm.disabled,
		},attrs:{"id":_vm.getId}},[_c('span',{staticClass:"text-muted"},[_vm._v("Selezionare...")]),_vm._v(" "),(!!_vm.canUpload && !_vm.disabled && !_vm.readonly)?_c('btn',{attrs:{"light":"","icon":['fad', 'file-search'],"label":"Scegli","action":_vm.openModal}}):_vm._e()],1):_vm._e(),_vm._v(" "),(!!_vm.small)?_c('small',{staticClass:"form-text text-muted",attrs:{"id":_vm.getId + 'Help'},domProps:{"innerHTML":_vm._s(_vm.small)}}):_vm._e(),_vm._v(" "),(!!_vm.error)?_c('span',{staticClass:"invalid-feedback",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(_vm.error))])]):_vm._e(),_vm._v(" "),(!_vm.multiple)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],attrs:{"type":"hidden","id":_vm.getId,"name":_vm.name,"required":_vm.required,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":(_vm.internalValue)},on:{"input":function($event){if($event.target.composing)return;_vm.internalValue=$event.target.value}}}):_c('select',{staticStyle:{"display":"none","height":"0","width":"0"},attrs:{"id":_vm.getId,"name":_vm.name + '[]',"required":_vm.required,"readonly":_vm.readonly,"multiple":""}},_vm._l((_vm.internalValue),function(element){return _c('option',{attrs:{"selected":""},domProps:{"value":element}})}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }