<template>
	<btn
		:type="type"
		:icon="icon"
		:href="href"
		:title="title"
		:action="action"
		:postform="postform"
		:delform="delform"
		:formid="formid"
		:target="target"
		light
		bar
	></btn>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: 'primary',
			required: false,
		},
		icon: {
			type: Array,
			default: () => [],
			required: false,
		},
		href: {
			type: String,
			default: '',
			required: false,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		action: {
			type: Function,
			default: null,
			required: false,
		},
		postform: {
			type: Boolean,
			default: false,
			required: false,
		},
		delform: {
			type: Boolean,
			default: false,
			required: false,
		},
		formid: {
			type: String,
			default: '',
			required: false,
		},
		target: {
			type: String,
			default: null,
			required: false,
		},
	},
}
</script>
