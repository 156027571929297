<template>
	<aside
		:class="classObject"
		@mouseenter="this.$root.toogleAsideHover"
		@mouseleave="this.$root.toogleAsideHover"
	>
		<slot></slot>
	</aside>
</template>

<script>
export default {
	computed: {
		classObject: function() {
			return {
				aside: true,
				'aside-collapsed': !!this.$root.asideCompressed,
				'aside-hover': !!this.$root.asideHover,
			}
		},
	},
}
</script>
