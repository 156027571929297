<template>
	<div :id="id" class="block fullscreen" v-if="!!fullscreen">
		<div class="block-content fullscreen" :class="{ 'block-padding': !nopad }">
			<slot></slot>
		</div>
	</div>
	<div
		:id="id"
		class="block"
		:class="{ 'block-compressed': !open, 'block-hide': !!hidden }"
		v-else
	>
		<div class="block-head" @click="toggle" v-if="!!title || !!$slots.icon">
			<div class="head-icon" v-if="!!$slots.icon">
				<slot name="icon"></slot>
			</div>
			<div class="head-title headline-font" v-if="!!title">{{ title }}</div>
			<div class="head-arrow">
				<font-awesome-icon :icon="['fad', 'chevron-up']" v-if="!!open"></font-awesome-icon>
				<font-awesome-icon :icon="['fad', 'chevron-down']" v-else></font-awesome-icon>
			</div>
		</div>

		<div
			class="block-content"
			:class="{ 'block-padding': !nopad, 'no-head': !title && !$slots.icon }"
		>
			<slot></slot>
		</div>

		<div class="block-foot" v-if="!!$slots.footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		open: true,
		hidden: false,
	}),
	props: {
		id: {
			type: String,
			default: '',
			required: false,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		nopad: {
			type: Boolean,
			default: false,
			required: false,
		},
		compressedDefault: {
			type: Boolean,
			default: false,
			required: false,
		},
		hiddenDefault: {
			type: Boolean,
			default: false,
			required: false,
		},
		fullscreen: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	methods: {
		toggle: function() {
			this.open = !!this.open ? false : true
		},
		toggleHide: function() {
			this.hidden = !!this.hidden ? false : true
		},
		hide: function() {
			if (!!this.hidden) this.hidden = false
		},
		show: function() {
			if (!this.hidden) this.hidden = true
		},
	},
	created: function() {
		this.open = !this.compressedDefault
		this.hidden = !!this.hiddenDefault
	},
}
</script>
