/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap'
import bsCustomFileInput from 'bs-custom-file-input'
import Helper from './default/plugins/Helper.js'
import Custom from './plugins/Custom.js'

window.Vue = require('vue')

/**
 * Font Awesome Icons
 */

import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from '@fortawesome/pro-solid-svg-icons'
//import { far } from '@fortawesome/pro-regular-svg-icons'
//import { fal } from '@fortawesome/pro-light-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//library.add(fas, far, fal, fab, fad)
library.add(fab, fad)

Vue.component('font-awesome-icon', FontAwesomeIcon)

/**
 * Full Calendar
 */

import FullCalendar from '@fullcalendar/vue'

Vue.component('full-calendar', FullCalendar)

/**
 * ApexCharts
 */

import VueApexCharts from 'vue-apexcharts'
import it from 'apexcharts/dist/locales/it.json'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Apex.chart = {
	locales: [it],
	defaultLocale: 'it',
}

/**
 * Sentry
 */
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

Sentry.init({
	dsn: process.env.MIX_SENTRY_VUE_DSN,
	integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
})

/**
 * Datepicker
 */

import Datepicker from 'vuejs-datepicker'

Vue.component('datepicker', Datepicker)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key =>
	Vue.component(
		key
			.split('/')
			.pop()
			.split('.')[0],
		files(key).default
	)
)

/**
 * CKEditor
 */

import CKEditor from '@ckeditor/ckeditor5-vue'

Vue.use(CKEditor)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.use(Helper)
Vue.use(Custom)

const app = new Vue({
	el: '#root',
	mounted: function() {
		bsCustomFileInput.init()

		$(function() {
			$('[data-toggle="tooltip"]').tooltip()
		})

		this.$refs['rootLoader'].style.display = 'none'
	},
})
