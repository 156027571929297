var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{ref:_vm.ref,attrs:{"id":_vm.ref,"closeProp":_vm.close,"title":"Anagrafica","large":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('btn',{attrs:{"type":"success","icon":['fad', 'check'],"title":"Salva","action":_vm.submitForm}})]},proxy:true}])},[_c('form',{ref:_vm.refForm,attrs:{"id":_vm.refForm}},[_c('input',{attrs:{"type":"hidden","name":"pa","value":"0"}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[(!!_vm.showType && !_vm.id)?_c('form-select',{attrs:{"id":"registry_type","name":"registry_type","label":"Tipo","options":[
						{ id: '0', label: 'Inquilino' },
						{ id: '1', label: 'Autorizzato' },
					],"item-value":"id","item-label":"label","required":""}}):_c('form-input',{attrs:{"type":"hidden","id":"type","name":"type","value":_vm.getValue('type', '1'),"required":""}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('form-segment',{attrs:{"id":"persona_giuridica","name":"persona_giuridica","label":"Persona Giuridica","options":[
						{ value: '1', label: 'Si' },
						{ value: '0', label: 'No' },
					],"value":_vm.getValue('persona_giuridica'),"required":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('form-input',{attrs:{"label":"Ragione Sociale","id":"ragione_sociale","name":"ragione_sociale","value":_vm.getValue('ragione_sociale')}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('form-input',{attrs:{"label":"Nome","id":"first_name","name":"first_name","value":_vm.getValue('first_name'),"required":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-lg-4"},[_c('form-input',{attrs:{"label":"Cognome","id":"last_name","name":"last_name","value":_vm.getValue('last_name'),"required":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-lg-4"},[_c('form-select',{attrs:{"id":"sesso","name":"sesso","label":"Sesso","options":[
						{ id: 'M', label: 'Maschio' },
						{ id: 'F', label: 'Femmina' },
					],"item-value":"id","item-label":"label","value":_vm.getValue('sesso')}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('form-input',{attrs:{"type":"date","placeholder":"YYYY-MM-DD","label":"Data di Nascita","id":"data_nascita","name":"data_nascita","value":_vm.getValue('data_nascita_formatted')}})],1),_vm._v(" "),_c('div',{staticClass:"col-lg-4"},[_c('form-autocomplete',{attrs:{"id":"luogo_nascita_raw","name":"luogo_nascita_raw","label":"Luogo di Nascita","value":_vm.getValue('luogo_nascita_raw')}})],1),_vm._v(" "),_c('div',{staticClass:"col-lg-4"},[_c('form-input',{attrs:{"label":"Telefono","id":"phone","name":"phone","value":_vm.getValue('phone')}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('form-input',{attrs:{"label":"Codice Fiscale","id":"codice_fiscale","name":"codice_fiscale","value":_vm.getValue('codice_fiscale')}})],1),_vm._v(" "),_c('div',{staticClass:"col-lg-8"},[_c('form-textarea',{attrs:{"id":"note","name":"note","label":"Note","value":_vm.getValue('note')}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }