<template>
	<full-calendar
		ref="fullCalendar"
		default-view="timeGridWeek"
		:plugins="calendarPlugins"
		:locale="locale"
		:events="events"
		:header="header"
		:event-limit="true"
		:views="views"
		@eventClick="eventClick"
	></full-calendar>
</template>

<script>
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import itLocale from '@fullcalendar/core/locales/it'
import interactionPlugin from '@fullcalendar/interaction'

export default {
	data: () => ({
		calendarPlugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
		locale: itLocale,
		header: {
			left: 'prev,today,next',
			center: 'title',
			right: 'timeGridWeek,dayGridMonth,listMonth',
		},
		views: {
			dayGridMonth: {
				eventLimit: true,
			},
		},
	}),
	props: {
		url: {
			type: String,
			default: null,
			required: true,
		},
		editModal: {
			type: String,
			default: null,
			required: true,
		},
	},
	computed: {
		events: function(info, successCallback, failureCallback) {
			let _that = this
			return function(info, successCallback, failureCallback) {
				_that.$root.request(
					_that,
					_that.url,
					'GET',
					{
						start: info.start.valueOf(),
						end: info.end.valueOf(),
					},
					function(data) {
						successCallback(data)
					}
				)
			}
		},
		calendarApi: function() {
			return this.$refs.fullCalendar.getApi()
		},
	},
	methods: {
		refreshTable: function() {
			this.calendarApi.refetchEvents()

			return true
		},
		eventClick: function(arg) {
			if (typeof arg.event === 'undefined') return false

			let event = arg.event

			if (
				typeof event.extendedProps.source === 'undefined' ||
				typeof event.extendedProps.event_edit === 'undefined' ||
				typeof event.extendedProps.update_url === 'undefined' ||
				typeof event.extendedProps.destroy_url === 'undefined'
			)
				return false

			let data = {
				datas: event.extendedProps.source,
				editable: event.extendedProps.event_edit,
				urlupdate: event.extendedProps.update_url,
				urldestroy: event.extendedProps.destroy_url,
			}

			this.$root.showModalWithData(this.editModal, data)
		},
	},
}
</script>
