<template>
	<apexchart
		width="100%"
		:height="height"
		:type="type"
		:options="{ ...options, ...httpOptions }"
		:series="series"
	></apexchart>
</template>

<script>
export default {
	data: () => ({
		title: '',
		series: [],
		labels: [],
		colors: null,
		xaxisCategories: [],
		legend: true,
		httpOptions: {},
		tooltip: true,
		tooltipFormatter: value => value,
	}),
	props: {
		id: {
			type: String,
			default: null,
			required: false,
		},
		url: {
			type: String,
			default: null,
			required: true,
		},
		type: {
			type: String,
			default: null,
			required: true,
		},
		height: {
			type: [String, Number],
			default: 'auto',
			required: false,
		},
	},
	computed: {
		options: function() {
			var options = {
				title: {
					text: this.title,
					align: 'center',
				},
				chart: {
					id: this.id,
				},
				theme: {
					mode: 'light',
					palette: 'palette2',
				},
				legend: {
					show: this.legend,
				},
				labels: this.labels,
				chart: {
					fontFamily: 'Fira Sans, sans-serif',
					toolbar: {
						show: true,
						offsetX: 0,
						offsetY: 0,
						tools: {
							download: true,
							selection: false,
							zoom: false,
							zoomin: false,
							zoomout: false,
							pan: false,
							reset: false | '<img src="/static/icons/reset.png" width="20">',
						},
						autoSelected: 'zoom',
					},
					zoom: {
						enabled: false,
					},
				},
				plotOptions: {
					bar: {
						horizontal: true,
					},
				},
				xaxis: {
					categories: this.xaxisCategories,
				},
				dataLabels: {
					enabled: true,
					textAnchor: 'start',
				},
				tooltip: {
					enabled: this.tooltip,
					fillSeriesColor: false,
					y: {
						formatter: this.tooltipFormatter,
					},
				},
			}
			if (!!this.colors) options['colors'] = this.colors
			return options
		},
	},
	methods: {
		init: function() {
			return this.$root.requestGet(this, this.url, this.processResponse)
		},
		processResponse: function(response) {
			if (typeof response !== 'object') return false

			if (!!response.title) this.title = response.title

			if (!!response.series) this.series = response.series

			if (Array.isArray(response.xaxis_categories))
				this.xaxisCategories = response.xaxis_categories

			if (Array.isArray(response.labels)) this.labels = response.labels

			if (!!response.colors && Array.isArray(response.colors) && response.colors.length)
				this.colors = response.colors

			if (typeof response.options === 'object') this.httpOptions = response.options

			if (typeof response.legend === 'boolean') this.legend = response.legend

			if (typeof response.tooltip === 'boolean') this.tooltip = response.tooltip

			if (!!response.dataFormat) {
				switch (response.dataFormat) {
					case 'decimal':
						this.series = this.series.map(item => parseFloat(item))
						break
					default:
						break
				}
			}

			if (!!response.tooltipFormat) {
				switch (response.tooltipFormat) {
					case 'currency':
						this.tooltipFormatter = function(value) {
							var formatter = Intl.NumberFormat('it-IT', {
								style: 'currency',
								currency: 'EUR',
							})
							return formatter.format(value)
						}
						break
					default:
						break
				}
			}
		},
	},
	created: function() {
		this.init()
	},
}
</script>
