<template>
	<div :id="id" class="widget" :class="classObject">
		<div class="widget-icon" :class="{ big: !!iconBig }" v-if="!!icon">
			<font-awesome-icon :icon="icon"></font-awesome-icon>
		</div>
		<div
			class="widget-text headline-font"
			:class="{ small: !!small, xsmall: !!smallText }"
			v-if="!!text || text == 0"
		>
			{{ text }}
		</div>
		<div class="widget-title headline-font" v-if="!!title">{{ title }}</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: '',
			required: false,
		},
		color: {
			type: String,
			default: '',
			required: false,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		icon: {
			type: Array,
			default: () => [],
			required: false,
		},
		text: {
			type: String,
			default: '',
			required: false,
		},
		small: {
			type: Boolean,
			default: false,
			required: false,
		},
		smallText: {
			type: Boolean,
			default: false,
			required: false,
		},
		iconBig: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	computed: {
		classObject: function() {
			return {
				['widget-' + this.color]: !!this.color,
			}
		},
	},
}
</script>
