<template>
	<btn
		light
		bar
		:icon="['fad', !!this.$root.sidebarHidden ? 'arrow-to-left' : 'arrow-to-right']"
		:action="toogleSidebar"
	></btn>
</template>

<script>
export default {
	methods: {
		toogleSidebar: function() {
			this.$root.toogleSidebar()
		},
	},
}
</script>
