<template>
	<li class="nav-item">
		<span class="nav-link disabled">
			<div
				class="nav-icon disabled"
				:class="{ full: !!this.$root.asideCompressed && !this.$root.asideHover }"
			>
				<font-awesome-icon :icon="icon" v-if="icon && icon.length"></font-awesome-icon>
			</div>
			<div class="nav-title">{{ title }}</div>
		</span>
	</li>
</template>

<script>
export default {
	props: {
		icon: {
			type: Array,
			default: null,
			required: false,
		},
		title: {
			type: String,
			default: null,
			required: true,
		},
	},
}
</script>
