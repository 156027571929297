<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Fatturazione">
		<form :id="refForm" :ref="refForm" v-if="!running">
			<form-input label="Mese" :value="month" readonly></form-input>

			<form-input
				type="number"
				id="adeguamento_istat"
				name="adeguamento_istat"
				step="0.001"
				min="0"
				label="Adeguamento ISTAT (100%)"
				:value="getValue('adeguamento_istat', '0.75')"
				append-text="%"
				required
				v-if="!adeguamento"
			></form-input>
			<form-input label="Adeguamento ISTAT" :value="adeguamento" readonly v-else></form-input>

			<div v-if="!saldoActive">
				<form-segment
					id="saldo_amministrazione"
					name="saldo_amministrazione"
					label="Saldo Amministrazione"
					:options="[
						{ id: 1, value: 'Si' },
						{ id: 0, value: 'No' },
					]"
					item-value="id"
					item-label="value"
					:value="getValue('saldo_amministrazione', '0')"
					required
				></form-segment>

				<form-input
					type="number"
					id="rate"
					name="rate"
					step="1"
					min="1"
					label="Rate"
					:value="getValue('rate', '6')"
					required
				></form-input>
			</div>

			<form-input label="Saldo Amministrazione" value="Si" readonly v-else></form-input>
		</form>

		<div v-else>
			<h5>Generazione fatture in corso!</h5>
			<p>
				La finestra si aggiornerà quando l'operazione sarà completata.
			</p>
		</div>

		<template v-slot:footer v-if="!running">
			<btn type="success" :icon="['fad', 'check']" title="Genera" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'billing',
		refForm: '',
		urlupdate: '',
		id: '',
		datas: {},
		running: false,
		interval: null,
	}),
	props: {
		urlstore: {
			type: String,
			default: '',
			required: true,
		},
		billingStatusUrl: {
			type: String,
			default: '',
			required: true,
		},
		reftable: {
			type: String,
			default: '',
			required: false,
		},
		month: {
			type: String,
			default: '',
			required: true,
		},
		saldoActive: {
			type: Boolean,
			default: false,
			required: true,
		},
		adeguamento: {
			type: String,
			default: '',
			required: false,
		},
	},
	created: function() {
		this.refForm = this.ref + 'DataForm'
	},
	mounted: function() {
		this.runStatus(false, false)
	},
	methods: {
		runStatus: function(fast = false, reset = false) {
			let time = 5000
			if (!!fast) time = 3000

			if (!!reset) this.stopStatus()

			this.getStatus
			this.interval = setInterval(this.getStatus, time)
		},
		stopStatus: function() {
			clearInterval(this.interval)
		},
		getStatus: function() {
			return this.$root.requestGet(this, this.billingStatusUrl, this.processCallback)
		},
		processCallback: function(response) {
			let oldValue = this.running

			if (response instanceof Object && typeof response.data !== 'undefined') {
				this.running = !!response.data
			} else {
				this.running = false
			}

			if (oldValue != this.running) {
				this.refreshTable()

				if (!!oldValue) this.close()
			}
		},
		close: function() {
			this.runStatus(false, true)

			this.assignData({})
			this.urlupdate = ''

			this.$refs[this.ref].close()
		},
		open: function(data) {
			this.runStatus(true, true)

			if (!!data && typeof data === 'object') {
				if (!!data.urlupdate) this.urlupdate = data.urlupdate
				if (!!data.datas) this.assignData(data.datas)
			} else if (!!data && Array.isArray(data)) {
				this.assignData(data)
			} else {
				this.assignData({})
			}

			this.$refs[this.ref].open()
		},
		getValue: function(key, defaultValue) {
			if (!!this.datas[key]) return this.datas[key]
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		getAction: function() {
			return !!this.id ? this.urlupdate : this.urlstore
		},
		getMethod: function() {
			return !!this.id ? 'PATCH' : 'POST'
		},
		assignData: function(datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
				if (!!datas.id) this.id = datas.id
			} else {
				this.datas = {}
				this.id = ''
			}
		},
		submitForm: function() {
			return this.$root.request(
				this,
				this.getAction(),
				this.getMethod(),
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		refreshTable: function() {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)
		},
		submitFormCallback: function(data) {
			this.getStatus()

			/*let self = this
			Vue.nextTick().then(function() {
				self.close()
			})*/
		},
	},
}
</script>
