<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Unità Immobiliare">
		<form :id="refForm" :ref="refForm">
			<form-input
				label="Unità Immobiliare"
				:value="getValue('real_estate_address')"
				readonly
				v-if="!!id"
			></form-input>

			<div class="row">
				<div class="col-md-8">
					<form-input
						id="zona"
						name="zona"
						label="Zona"
						:value="getValue('zona')"
						:readonly="!!closed"
					></form-input>
				</div>
				<div class="col-md-4" v-if="!!id">
					<form-input
						label="Tipo"
						:value="getValue('real_estate_type')"
						readonly
						v-if="!!id"
					></form-input>
				</div>
			</div>

			<div class="row" v-if="!!id">
				<div class="col-md-6">
					<form-input
						label="Valore Magazzino"
						:value="getValue('real_estate_valore_magazzino')"
						readonly
						v-if="!!id"
					></form-input>
				</div>
				<div class="col-md-6">
					<form-input
						label="Prezzo Perizia"
						:value="getValue('prezzo_base_display')"
						readonly
						v-if="!!id"
					></form-input>
				</div>
			</div>

			<form-input
				type="number"
				step="0.01"
				min="0"
				id="prezzo_base"
				name="prezzo_base"
				label="Prezzo Base"
				append-text="EUR"
				:value="getValue('prezzo_base')"
				required
				v-if="!closed"
			></form-input>
			<form-input
				label="Prezzo Base"
				:value="getValue('prezzo_base_display')"
				readonly
				v-else
			></form-input>

			<form-textarea
				id="descrizione"
				name="descrizione"
				label="Descrizione"
				:value="getValue('descrizione')"
				:readonly="!!closed"
			></form-textarea>

			<form-input
				label="Esito"
				:value="getValue('offers_esito')"
				readonly
				v-if="!!id"
			></form-input>

			<div class="row" v-if="!!id">
				<div class="col-md-6">
					<form-input
						label="Acquirente"
						:value="getValue('offers_buyer')"
						readonly
						v-if="!!id"
					></form-input>
				</div>
				<div class="col-md-6">
					<form-input
						label="Importo Offerta"
						:value="getValue('offers_offerta')"
						readonly
						v-if="!!id"
					></form-input>
				</div>
			</div>

			<form-file
				label="Verbale Rilancio"
				id="verbale_id"
				name="verbale_id"
				:value="getValue('verbale', {})"
				v-if="!!id"
				:readonly="!!closed"
			></form-file>
		</form>

		<template v-slot:footer v-if="!closed">
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'realestate',
		refForm: '',
		urlupdate: '',
		id: '',
		datas: {},
	}),
	props: {
		urlstore: {
			type: String,
			default: null,
			required: false,
		},
		reftable: {
			type: String,
			default: null,
			required: false,
		},
		closed: {
			type: Boolean,
			default: false,
			required: true,
		},
	},
	created: function() {
		this.refForm = this.ref + 'DataForm'
	},
	methods: {
		close: function() {
			this.assignData({})
			this.urlupdate = ''

			this.$refs[this.ref].close()
		},
		open: function(data) {
			if (!!data && typeof data === 'object') {
				if (!!data.urlupdate) this.urlupdate = data.urlupdate
				if (!!data.datas) this.assignData(data.datas)
			} else if (!!data && Array.isArray(data)) {
				this.assignData(data)
			} else {
				this.assignData({})
			}

			this.$refs[this.ref].open()
		},
		getValue: function(key, defaultValue) {
			if (!!this.datas[key]) return this.datas[key]
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		getAction: function() {
			return !!this.id ? this.urlupdate : this.urlstore
		},
		getMethod: function() {
			return !!this.id ? 'PATCH' : 'POST'
		},
		assignData: function(datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
				if (!!datas.id) this.id = datas.id
			} else {
				this.datas = {}
				this.id = ''
			}
		},
		submitForm: function() {
			return this.$root.request(
				this,
				this.getAction(),
				this.getMethod(),
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function(data) {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function() {
				self.close()
			})
		},
	},
}
</script>
