<template>
	<div :id="id" :class="classObject" style="display:flex;height:100%" v-if="!link && !action">
		<btn
			:action="toggleOpen"
			:active="open"
			:type="type"
			light
			header
			:title="title"
			:icon="icon"
			:src="src"
			:iconSize="!!iconBig ? 'large' : null"
		/>
		<dropdown v-if="!!openDropdown" :items="items" :header="header" :footer="footer"></dropdown>
	</div>
	<btn
		:type="type"
		light
		header
		:title="title"
		:icon="icon"
		:href="link"
		:src="src"
		:action="action"
		:iconSize="!!iconBig ? 'large' : null"
		v-else
	/>
</template>

<script>
export default {
	data: () => ({
		open: false,
	}),
	props: {
		id: {
			type: String,
			default: null,
			required: false,
		},
		icon: {
			type: Array,
			default: () => [],
			required: false,
		},
		link: {
			type: String,
			default: null,
			required: false,
		},
		title: {
			type: String,
			default: null,
			required: false,
		},
		src: {
			type: String,
			default: null,
			required: false,
		},
		items: {
			type: Array,
			default: () => [],
			required: false,
		},
		header: {
			type: Object,
			default: null,
			required: false,
		},
		footer: {
			type: Object,
			default: null,
			required: false,
		},
		iconBig: {
			type: Boolean,
			default: false,
			required: false,
		},
		type: {
			type: String,
			default: 'light',
			required: false,
		},
		action: {
			type: Function,
			default: null,
			required: false,
		},
	},
	computed: {
		classObject: function() {
			return {
				dropdown: !!this.items && !!this.items.length,
			}
		},
		openDropdown: function() {
			return !!this.open && !!this.items && !!this.items.length
		},
	},
	methods: {
		toggleOpen: function() {
			if (!!this.link) return

			this.open = !!this.open ? false : true
		},
	},
}
</script>
