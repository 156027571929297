<template>
	<aside class="sidebar" :class="classObject" v-if="!this.$root.sidebarHidden">
		<slot></slot>
	</aside>
</template>

<script>
export default {
	computed: {
		classObject: function() {
			return {
				'aside-collapsed': !!this.$root.asideCompressed,
			}
		},
	},
	created: function() {
		this.$root.sidebarHidden = false
	},
}
</script>
