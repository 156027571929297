<template>
	<block-sidebar title="Esporta" v-if="!!exports && !!Object.keys(exports).length">
		<template v-slot:icon>
			<font-awesome-icon :icon="['fad', 'file-export']"></font-awesome-icon>
		</template>

		<div class="btn-group btn-block">
			<btn block title="PDF" :action="exportPDF" v-if="!!exports.pdf"></btn>
			<btn block title="Excel" :action="exportExcel" v-if="!!exports.excel"></btn>
		</div>
	</block-sidebar>
</template>

<script>
export default {
	data: () => ({
		exports: null,
	}),
	props: {
		refTable: {
			type: String,
			default: '',
			required: true,
		},
	},
	mounted: function() {
		if (typeof this.getTable !== 'undefined') this.exports = this.getTable.exports
	},
	computed: {
		getTable: function() {
			return this.$root.$refs[this.refTable]
		},
	},
	methods: {
		exportPDF: function() {
			this.getTable.showLoader()

			let body = this.getTable.exportParams()
			if (!body) return this.getTable.hideLoader()

			let newWindow = window.open('about:blank', '_blank')

			this.$root.request(
				this,
				this.exports.pdf,
				'POST',
				body,
				response => {
					if (response instanceof Object == false) {
						newWindow.close()
						return this.getTable.hideLoader()
					}

					if (typeof response.data === 'string') newWindow.location.href = response.data
					this.getTable.hideLoader()
				},
				({ response, message }) => {
					newWindow.close()
					this.getTable.hideLoader()
				}
			)
		},
		exportExcel: function() {
			this.getTable.showLoader()

			let body = this.getTable.exportParams()
			if (!body) return this.getTable.hideLoader()

			let newWindow = window.open('about:blank', '_blank')

			this.$root.request(
				this,
				this.exports.excel,
				'POST',
				body,
				response => {
					if (response instanceof Object == false) {
						newWindow.close()
						return this.getTable.hideLoader()
					}

					if (typeof response.data === 'string') newWindow.location.href = response.data
					this.getTable.hideLoader()
				},
				({ response, message }) => {
					newWindow.close()
					this.getTable.hideLoader()
				}
			)
		},
	},
}
</script>
