<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Verbale">
		<form :id="refForm" :ref="refForm">
			<form-file
				label="Verbale Rilancio"
				id="verbale_trattativa_diretta_id"
				name="verbale_trattativa_diretta_id"
				:value="getValue('verbale_trattativa_diretta', {})"
				:readonly="!urlupdate"
			></form-file>
		</form>

		<template v-slot:footer v-if="!!urlupdate">
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'verbale',
		refForm: '',
		datas: {},
	}),
	props: {
		urlupdate: {
			type: String,
			default: null,
			required: false,
		},
		url: {
			type: String,
			default: null,
			required: true,
		},
	},
	created: function() {
		this.refForm = this.ref + 'DataForm'
	},
	methods: {
		close: function() {
			this.assignData({})

			this.$refs[this.ref].close()
		},
		open: function() {
			let _that = this

			this.$root.request(this, this.url, 'GET', {}, data => {
				if (!!data && typeof data === 'object') {
					if (!!data.data) _that.assignData(data.data)
				} else if (!!data && Array.isArray(data)) {
					_that.assignData(data)
				} else {
					_that.assignData({})
				}

				_that.$refs[_that.ref].open()
			})
		},
		getValue: function(key, defaultValue) {
			if (this.datas[key] != null) return this.datas[key]
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		assignData: function(datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
			} else {
				this.datas = {}
			}
		},
		submitForm: function() {
			return this.$root.request(
				this,
				this.urlupdate,
				'PATCH',
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function(data) {
			let self = this
			Vue.nextTick().then(function() {
				self.close()
			})
		},
	},
}
</script>
