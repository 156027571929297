<template>
	<div v-if="show">
		<div class="modal" :id="id" tabindex="-1" role="dialog">
			<div
				class="modal-dialog modal-dialog-centered"
				:class="{ 'modal-sm': !!small, 'modal-xl': !!large }"
				role="document"
			>
				<div class="modal-content">
					<div v-if="!!title" class="modal-header">
						<span class="modal-title headline-font">
							{{ title }}
						</span>
						<btn
							id="close"
							:icon="['fad', 'times']"
							:action="closeProp"
							light
							v-if="close"
						/>
					</div>

					<div class="modal-body">
						<slot></slot>
					</div>

					<div class="modal-footer" v-if="!!$slots.footer">
						<slot name="footer"></slot>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-backdrop show" />
	</div>
</template>

<script>
export default {
	data: () => ({
		show: false,
	}),
	props: {
		id: {
			type: String,
			default: '',
			required: true,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		closeProp: {
			type: Function,
			default: null,
			required: true,
		},
		small: {
			type: Boolean,
			default: false,
			required: false,
		},
		large: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	methods: {
		close: function() {
			document.getElementsByTagName('BODY')[0].classList.remove('overflowHidden')
			this.show = false
		},
		open: function() {
			document.getElementsByTagName('BODY')[0].classList.add('overflowHidden')
			this.show = true
		},
	},
}
</script>
