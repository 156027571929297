<template>
	<td class="actions" v-if="!!actions && !!actions.length">
		<div :style="{ display: 'flex' }">
			<div class="dropdown" :style="{ margin: 'auto' }" v-click-outside="closeDropdown">
				<btn
					:action="toggleOpen"
					:active="!!open"
					light
					table
					label="Azioni"
					:icon="['fad', 'ellipsis-h']"
				/>
				<dropdown v-if="!!openDropdown" :items="normalizeChildrens()" small></dropdown>
			</div>
		</div>
	</td>
	<td v-else></td>
</template>

<script>
export default {
	data: () => ({
		open: false,
	}),
	props: {
		actions: {
			type: Array,
			default: () => [],
			required: true,
		},
		row: {
			type: Object,
			default: null,
			required: true,
		},
		editRefmodal: {
			type: String,
			default: '',
			required: false,
		},
		row_index: {
			type: Number,
			default: null,
			required: false,
		},
		httpAppend: {
			type: Object,
			default: null,
			required: false,
		},
	},
	computed: {
		openDropdown: function () {
			return !!this.open && !!this.actions && !!this.actions.length
		},
	},
	methods: {
		returnIcon: function (icon) {
			return Array.isArray(icon) ? icon : icon.split(' ')
		},
		getIconByType: function (type) {
			switch (type.toUpperCase()) {
				case 'VIEW':
				case 'VIEW_MODAL':
					return ['fad', 'eye']
					break
				case 'EDIT':
				case 'EDIT_MODAL':
				case 'EDIT_ROW':
					return ['fad', 'pencil']
					break
				case 'CREATE':
				case 'DEARCHIVE':
					return ['fad', 'plus']
					break
				case 'DELETE':
					return ['fad', 'trash']
					break
				case 'CLONE':
				case 'CLONE_REDIRECT':
					return ['fad', 'clone']
					break
				case 'CONTRACT':
					return ['fad', 'file-contract']
					break
				case 'ARCHIVE':
					return ['fad', 'archive']
					break
				case 'REMOVE':
					return ['fad', 'times']
					break
				case 'EDIT_RE':
				case 'REAL_ESTATE':
					return ['fad', 'building']
					break
				case 'REGISTRY':
					return ['fad', 'address-card']
					break
				case 'GENERATE_INVOICE':
					return ['fad', 'file-invoice']
					break
				case 'RENEW_CONTRACT':
					return ['fad', 'redo']
					break
				case 'MAP':
					return ['fad', 'map-pin']
					break
				case 'CONTENTIOUS':
					return ['fad', 'balance-scale-left']
					break
				case 'NOTICE':
					return ['fad', 'flag']
					break
				case 'LIST':
					return ['fad', 'list']
					break
				case 'UP':
					return ['fad', 'level-up-alt']
				case 'DOWN':
					return ['fad', 'level-down-alt']
				case 'ESTIMATE':
					return ['fad', 'clipboard-list-check']
					break
				case 'CONSUMPTIVE':
					return ['fad', 'receipt']
					break
				case 'EXCEL':
					return ['fad', 'file-excel']
					break
				case 'XML':
				case 'GENERATE_XML':
					return ['fad', 'file-code']
					break
				case 'TXT':
					return ['fad', 'file-alt']
					break
				case 'CSV':
					return ['fad', 'file-csv']
					break
				case 'PDF':
				case 'PDF_POST':
					return ['fad', 'file-pdf']
					break
				case 'SUMMARY':
					return ['fad', 'book-open']
					break
				case 'CHANGE':
					return ['fad', 'exchange']
				case 'REAL_ESTATE_TECHNICAL':
					return ['fad', 'door-open']
				case 'REAL_ESTATE_COMMERCIAL':
					return ['fad', 'store']
				case 'REAL_ESTATE_ADMINISTRATION':
					return ['fad', 'landmark']
				case 'ADD_RE_CONTEST':
					return ['fad', 'plus']
				case 'REMOVE_RE_CONTEST':
					return ['fad', 'times']
				case 'REMOVE_CONDITION':
					return ['fad', 'unlink']
				case 'TEST_MAIL':
					return ['fad', 'paper-plane']
				case 'OPEN_NEGOTIATION':
					return ['fad', 'poll-people']
				case 'EXERCISE_APPROVE':
					return ['fad', 'check']
				case 'APARTMENT_ROOM':
					return ['fad', 'bed']
				case 'APARTMENT_EXPENSE':
					return ['fad', 'file-invoice']
				case 'APARTMENT_GAS':
					return ['fad', 'burn']
				default:
					return []
			}
		},
		getIcon: function (item) {
			return this.getIconByType(!!item.type ? item.type : '')
		},
		getTitle: function (item) {
			return !!item.title ? item.title : ''
		},
		getMethod: function (item) {
			return !!item.method ? item.method.toUpperCase() : 'GET'
		},
		isDestructive: function (item) {
			if (!!item.destructive) return true
			else if (!item.type) return false

			switch (item.type.toUpperCase()) {
				case 'DELETE':
				case 'REMOVE':
					return true
					break
				default:
					return false
			}
		},
		openModal: function (item) {
			if (!this.editRefmodal) return false

			let data = {
				datas: this.row,
				urlupdate: item.link,
			}

			this.$root.showModalWithData(this.editRefmodal, data)
		},
		editRow: function (item) {
			this.$parent.selectEditRow(item.link, this.row_index)
		},
		reqDel: function (link) {
			this.$root.requestDelete(this, link, function (data) {
				this.$emit('refreshTable')
			})
		},
		reqPost: function (link, message) {
			if (message && confirm(message))
				this.$root.requestPost(this, link, this.httpAppend, function (data) {
					this.$emit('refreshTable')
				})
			else if (!message)
				this.$root.requestPost(this, link, this.httpAppend, function (data) {
					this.$emit('refreshTable')
				})
			else return false
		},
		exportFile: function (link) {
			let newWindow = window.open('about:blank', '_blank')

			this.$root.request(
				this,
				link,
				'POST',
				this.httpAppend,
				(response) => {
					if (response instanceof Object == false) return newWindow.close()

					if (typeof response.data === 'string') newWindow.location.href = response.data
				},
				({ response, message }) => {
					newWindow.close()
				}
			)
		},
		newWindow: function (link) {
			let newWindow = window.open(link, '_blank')
		},
		reqPostRedirect: function (link) {
			this.$root.requestPost(this, link, this.httpAppend, function (response) {
				if (response instanceof Object == false) return false

				if (typeof response.data === 'string') window.location.href = response.data
				else this.$emit('refreshTable')
			})
		},
		getAction: function (item) {
			if (!item.type || !item.link) return false

			switch (item.type.toUpperCase()) {
				case 'DELETE':
				case 'REMOVE':
					return this.reqDel.bind(this, item.link)
					break
				case 'CLONE':
					return this.reqPost.bind(this, item.link)
					break
				case 'CLONE_REDIRECT':
					return this.reqPostRedirect.bind(this, item.link)
					break
				case 'EDIT_MODAL':
				case 'VIEW_MODAL':
					return this.openModal.bind(this, item)
					break
				case 'EDIT_ROW':
					return this.editRow.bind(this, item)
					break
				case 'ARCHIVE':
				case 'DEARCHIVE':
				case 'CHANGE':
					return this.reqPost.bind(this, item.link)
					break
				case 'UP':
				case 'DOWN':
					return this.reqPost.bind(this, item.link)
				case 'GENERATE_INVOICE':
					return this.reqPost.bind(this, item.link)
				case 'ADD_RE_CONTEST':
					return this.reqPost.bind(this, item.link)
				case 'REMOVE_RE_CONTEST':
					return this.reqDel.bind(this, item.link)
				case 'PDF_POST':
					return this.exportFile.bind(this, item.link)
				case 'REMOVE_CONDITION':
					return this.reqPost.bind(this, item.link)
				case 'OPEN_NEGOTIATION':
					return this.reqPost.bind(this, item.link)
				case 'PDF':
				case 'TXT':
				case 'CSV':
				case 'XML':
				case 'EXCEL':
					return this.newWindow.bind(this, item.link)
				case 'TEST_MAIL':
				case 'GENERATE_XML':
				case 'EXERCISE_APPROVE':
					return this.reqPost.bind(this, item.link, 'Sei sicuro di volerlo approvare?')
				default:
					if (!!item.method)
						this.$root.request(
							this,
							item.link,
							item.method.toUpperCase(),
							function (data) {
								this.$emit('refreshTable')
							}
						)
					return false
			}
		},
		getFormMethod: function (item) {
			if (!item.type || !item.link) return false

			switch (item.type.toUpperCase()) {
				case 'DELETE_FORM':
					return 'DELETE'
					break
				case 'CLONE_FORM':
					return 'POST'
					break
				default:
					return false
			}
		},
		normalizeChildrens: function () {
			let output = []

			for (var i = 0; i < this.actions.length; i++) {
				let element = this.actions[i]

				output.push({
					link: !!element.link ? element.link : '',
					target: !!element.link ? '_blank' : '',
					title: this.getTitle(element),
					icon: this.getIcon(element),
					separator: !!element.separator,
					destructive: this.isDestructive(element),
					action: this.getAction(element),
					formMethod: this.getFormMethod(element),
				})
			}

			return output
		},
		closeDropdown: function () {
			if (!!this.open) this.open = false
		},
		toggleOpen: function () {
			if (!!this.link) return

			this.open = !!this.open ? false : true
		},
	},
}
</script>
