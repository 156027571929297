<template>
	<div class="form-group">
		<label :for="getId" v-if="!!label"
			>{{ label }} <span class="form-required" v-if="!!required">*</span></label
		>
		<div class="form-wrap">
			<ckeditor
				:editor="editorType"
				tag-name="textarea"
				:disabled="disabled"
				v-model="internalValue"
				:config="editorConfig"
				@input="emitEvent"
				v-if="!!editor"
			></ckeditor>
			<textarea
				:name="name"
				class="form-control"
				:class="{ 'is-invalid': !!error }"
				:id="getId"
				:placeholder="placeholder"
				:required="required"
				:disabled="disabled"
				:autofocus="autofocus"
				:readonly="readonly"
				v-model="internalValue"
				@input="emitEvent"
				v-else
			></textarea>
			<input
				type="hidden"
				:name="name"
				v-model="internalValue"
				:disabled="disabled"
				:readonly="readonly"
				:required="required"
				v-if="!!editor"
			/>
			<form-link-icon
				:value="internalValue"
				:customFormLink="customFormLink"
				:customFormText="customFormText"
				:customFormIcon="customFormIcon"
				v-if="!!showFormLink"
			></form-link-icon>
		</div>
		<small
			v-if="!!small"
			:id="getId + 'Help'"
			class="form-text text-muted"
			v-html="small"
		></small>
		<span class="invalid-feedback" role="alert" v-if="!!error">
			<strong>{{ error }}</strong>
		</span>
	</div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
	data: () => ({
		editorType: ClassicEditor,
		editorConfig: {
			toolbar: ['Heading', '|', 'Bold', 'Italic', 'BlockQuote'],
			removePlugins: [
				'CKFinder',
				'CKFinderUploadAdapter',
				'EasyImage',
				'ImageCaption',
				'ImageStyle',
				'ImageToolbar',
				'ImageUpload',
				'Table',
				'TableToolbar',
				'MediaEmbed',
				'Indent',
				'Image',
			],
		},
		internalValue: '',
	}),
	props: {
		required: {
			type: Boolean,
			default: false,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		name: {
			type: String,
			default: '',
			required: false,
		},
		id: {
			type: String,
			default: '',
			required: false,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		placeholder: {
			type: String,
			default: '',
			required: false,
		},
		small: {
			type: String,
			default: '',
			required: false,
		},
		value: {
			type: String,
			default: '',
			required: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
			required: false,
		},
		showFormLink: {
			type: Boolean,
			default: true,
			required: false,
		},
		customFormLink: {
			type: String,
			default: null,
			required: false,
		},
		customFormText: {
			type: String,
			default: null,
			required: false,
		},
		customFormIcon: {
			type: Array,
			default: () => [],
			required: false,
		},
		tableRef: {
			type: String,
			default: null,
			required: false,
		},
		tableFilterType: {
			type: String,
			default: null,
			required: false,
		},
		editor: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	created: function() {
		this.internalValue = this.value
	},
	computed: {
		getId: function() {
			return !!this.id ? this.id : !!this.name ? this.name : ''
		},
	},
	methods: {
		emitEvent: function() {
			if (!this.tableRef) return false

			this.$root.updateFilterTable(
				this.tableRef,
				this.tableFilterType,
				this.name,
				this.internalValue
			)
		},
		reset: function() {
			this.internalValue = ''
			this.emitEvent()
		},
	},
}
</script>
