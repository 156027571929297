<template>
	<span class="form-link" :class="{ disabled: !value }">
		<a
			v-if="!!customFormLink && !!customFormText"
			class="form-link-content"
			:href="customFormLink"
			data-toggle="tooltip"
			:title="customFormText"
			target="_blank"
		>
			<font-awesome-icon
				:icon="
					!!customFormIcon && !!customFormIcon.length
						? customFormIcon
						: ['fad', 'info-circle']
				"
			></font-awesome-icon>
		</a>
		<span
			v-else-if="!customFormLink && !!customFormText"
			class="form-link-content"
			data-toggle="tooltip"
			:title="customFormText"
		>
			<font-awesome-icon :icon="['fad', 'info-circle']"></font-awesome-icon>
		</span>
		<a
			v-else-if="type.toUpperCase() == 'EMAIL'"
			class="form-link-content"
			:class="{ disabled: !value }"
			:href="'mailto:' + value"
			data-toggle="tooltip"
			title="Invia Email"
		>
			<font-awesome-icon :icon="['fad', 'paper-plane']"></font-awesome-icon>
		</a>
		<a
			v-else-if="type.toUpperCase() == 'TEL'"
			class="form-link-content"
			:class="{ disabled: !value }"
			:href="'tel:' + value"
			data-toggle="tooltip"
			title="Chiama"
		>
			<font-awesome-icon :icon="['fad', 'phone']"></font-awesome-icon>
		</a>
	</span>
</template>

<script>
export default {
	props: {
		value: {
			type: String | Number,
			default: '',
			required: false,
		},
		type: {
			type: String,
			default: '',
			required: false,
		},
		customFormLink: {
			type: String,
			default: null,
			required: false,
		},
		customFormText: {
			type: String,
			default: null,
			required: false,
		},
		customFormIcon: {
			type: Array,
			default: () => [],
			required: false,
		},
	},
}
</script>
