<template>
	<ul :id="id" class="nav root-menu">
		<nav-item v-for="(item, index) in items" :key="index" :item="item">
			<nav-item
				v-if="!!item.childrens"
				v-for="(item2, index2) in item.childrens"
				:key="index2"
				:item="item2"
			></nav-item>
		</nav-item>
	</ul>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => [],
			required: true,
		},
		name: {
			type: String,
			default: '',
			required: false,
		},
	},
	computed: {
		id: function() {
			return !!this.name ? this.name + '_menu' : null
		},
	},
}
</script>
