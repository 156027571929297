<template>
	<div v-if="!!visibility">
		<div :class="{ loader: true, boxed: !!boxed }">
			<spinner dark></spinner>
		</div>
	</div>
	<div v-else></div>
</template>

<script>
export default {
	data: () => ({
		visibility: true,
	}),
	props: {
		boxed: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	methods: {
		toggle: function() {
			this.visibility = !!this.visibility ? false : true
		},
		hide: function() {
			if (!!this.visibility) this.visibility = false
		},
		show: function() {
			if (!this.visibility) this.visibility = true
		},
	},
}
</script>
