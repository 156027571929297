const Custom = {
	install(Vue, options) {
		Vue.mixin({
			methods: {
				formatContenciousTableCell: function(item, row, meta) {
					if (item === 0 || item === '0')
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'balance-scale-left'],
								class: { 'text-danger': true },
							},
							title: 'Contenziosi Aperti',
						}
					else if (item == 1)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'balance-scale-left'],
								class: { 'text-success': true },
							},
							title: 'Contenziosi Chiusi',
						}
					else return ''
				},
				formatPaidSentTableCell: function(item, row, meta) {
					if (item == 0)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'times'],
								class: { 'text-danger': true },
							},
							title: 'Non Pagata',
						}
					else if (item == 1) {
						if (!!row.data_pagamento)
							return {
								name: 'font-awesome-icon',
								props: {
									icon: ['fad', 'calendar-day'],
									class: { 'text-success': true },
								},
								title: 'Pagata',
							}
						else
							return {
								name: 'font-awesome-icon',
								props: {
									icon: ['fad', 'calendar-day'],
									class: { 'text-warning': true },
								},
								title: 'In Pagamento',
							}
					} else
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'hourglass-half'],
								class: { 'text-danger': true },
							},
							title: 'In Sospeso',
						}
				},
				formatInvoiceSdiTableCell: function(item, row, meta) {
					if (item == 0)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'share'],
								class: { 'text-danger': true },
							},
							title: 'XML Non Generato',
						}
					else if (item == 1) {
						if (!!row.sent_xml)
							return {
								name: 'font-awesome-icon',
								props: {
									icon: ['fad', 'share'],
									class: { 'text-success': true },
								},
								title: 'XML Inviato allo Sdi',
							}
						else
							return {
								name: 'font-awesome-icon',
								props: {
									icon: ['fad', 'share'],
									class: { 'text-warning': true },
								},
								title: 'XML Generato',
							}
					} else return null
				},
				formatContestStatusRETableCell: function(item, row, meta) {
					if (item == 1)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'plus-square'],
								class: { 'text-success': true },
							},
							title: 'Aggiunto',
						}
					else
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'minus-square'],
								class: { 'text-secondary': true },
							},
							title: null,
						}
				},
				formatReportSentTableCell: function(item, row, meta) {
					if (item == 1)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'envelope-open-text'],
								class: { 'text-success': true },
							},
							title: 'Report Inviato',
						}
					else
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'paper-plane'],
								class: { 'text-danger': true },
							},
							title: 'Report non Inviato',
						}
				},
				formatRegistryTypeTableCell: function(item, row, meta) {
					if (item === 0 || item === '0')
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'user'],
								class: { 'text-secondary': true },
							},
							title: 'Persona Fisica',
						}
					else if (item == 1)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'building'],
								class: { 'text-secondary': true },
							},
							title: 'Persona Giuridica',
						}
					else return ''
				},
				formatREStatusTableCell: function(item, row, meta) {
					if (item === 0 || item === '0')
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'lock-alt'],
								class: { 'text-danger': true },
							},
							title: 'Occupata',
						}
					else if (item == 1)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'thumbs-up'],
								class: { 'text-success': true },
							},
							title: 'Libera',
						}
					else if (item == 2)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'hourglass-half'],
								class: { 'text-warning': true },
							},
							title: 'Rilascio in Corso',
						}
					else return ''
				},
				formatNoticeStatusTableCell: function(item, row, meta) {
					let colorClass =
						typeof row.color !== 'undefined' && !!row.color
							? 'text-' + row.color
							: 'text-secondary'

					let title =
						typeof row.priority !== 'undefined' && !!row.priority ? row.priority : ''

					if (!item)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'lock-alt'],
								class: [colorClass],
							},
							title: 'Chiusa',
						}
					else
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'pennant'],
								class: [colorClass],
							},
							title: title,
						}
				},
				formatRootCurrentTableCell: function(item, row, meta) {
					let output = []

					if (!!item) output.push('Principale')
					if (!!row.is_active) output.push('Corrente')

					return '<b>' + output.join(', ') + '</b>'
				},
				formatBoldTableCell: function(item, row, meta) {
					return '<b>' + item + '</b>'
				},
				formatTenantOrAuthorizedTableCell: function(item, row, meta) {
					if (!!item) return '<b>' + 'Inquilino' + '</b>'
					else return '<b>' + 'Autorizzato' + '</b>'
				},
				formatBoolAltTableCell: function(item, row, meta) {
					if (item == 1 || item == true) return 'Si'
					else if (item == 0 || item == false) return 'No'
					else return ''
				},
				formatBoolIconTableCell: function(item, row, meta, titles) {
					if (!!item)
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'check'],
								class: ['text-success'],
							},
							title: titles[0],
						}
					else
						return {
							name: 'font-awesome-icon',
							props: {
								icon: ['fad', 'times'],
								class: ['text-danger'],
							},
							title: titles[1],
						}
				},
			},
		})
	},
}

export default Custom
