<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Azienda" large>
		<form :id="refForm" :ref="refForm">
			<div class="row">
				<div class="col-md-6">
					<form-segment
						id="primary"
						name="primary"
						label="Pincipale"
						:options="[
							{ id: '1', label: 'Si' },
							{ id: '0', label: 'No' },
						]"
						item-value="id"
						item-label="label"
						:value="getValue('primary', '0')"
						required
					></form-segment>
				</div>
			</div>

			<br />
			<h4>Anagrafica</h4>

			<form-input
				label="Ragione Sociale"
				id="ragione_sociale"
				name="ragione_sociale"
				:value="getValue('ragione_sociale')"
				required
			></form-input>

			<div class="row">
				<div class="col-md-4">
					<form-input
						label="P.Iva"
						id="p_iva_azienda"
						name="p_iva_azienda"
						:value="getValue('p_iva_azienda')"
					></form-input>
				</div>
				<div class="col-md-4">
					<form-input
						label="Codice Fiscale"
						id="codice_fiscale_azienda"
						name="codice_fiscale_azienda"
						:value="getValue('codice_fiscale_azienda')"
					></form-input>
				</div>
				<div class="col-md-4">
					<form-input
						label="Regime Fiscale"
						id="regime_fiscale"
						name="regime_fiscale"
						:value="getValue('regime_fiscale')"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<form-input
						label="Conto Corrente"
						id="conto_corrente"
						name="conto_corrente"
						:value="getValue('conto_corrente')"
					></form-input>
				</div>
				<div class="col-md-4">
					<form-input
						label="C/C Postale"
						id="cc_postale"
						name="cc_postale"
						:value="getValue('cc_postale')"
					></form-input>
				</div>
			</div>

			<br />
			<h4>Contatti</h4>

			<div class="row">
				<div class="col-md-4">
					<form-input
						label="Telefono"
						id="phone"
						name="phone"
						:value="getValue('phone')"
					></form-input>
				</div>
				<div class="col-md-3">
					<form-input
						label="Fax"
						id="fax"
						name="fax"
						:value="getValue('fax')"
					></form-input>
				</div>
				<div class="col-md-5">
					<form-input
						type="email"
						label="Email"
						id="email"
						name="email"
						:value="getValue('email')"
					></form-input>
				</div>
			</div>

			<br />
			<h4>Sede</h4>

			<div class="row">
				<div class="col-md-8">
					<form-input
						label="Via"
						id="via"
						name="via"
						:value="getValue('via')"
					></form-input>
				</div>
				<div class="col-md-4">
					<form-input
						label="Civico"
						id="civico"
						name="civico"
						:value="getValue('civico')"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<form-autocomplete
						id="municipality_id"
						name="municipality_id"
						label="Città"
						:url="municipalities"
						:value="getValue('municipality_id')"
					></form-autocomplete>
				</div>
				<div class="col-md-4">
					<form-input
						label="CAP"
						id="cap"
						name="cap"
						:value="getValue('cap')"
					></form-input>
				</div>
			</div>

			<br />
			<h4>Registro delle Imprese</h4>

			<div class="row">
				<div class="col-md-4">
					<form-autocomplete
						id="ufficio_rea_id"
						name="ufficio_rea_id"
						label="Ufficio REA"
						:options="provinces"
						item-value="id"
						item-label="name"
						:value="getValue('ufficio_rea_id')"
					></form-autocomplete>
				</div>
				<div class="col-md-4">
					<form-input
						label="Numero REA"
						id="numero_rea"
						name="numero_rea"
						:value="getValue('numero_rea')"
					></form-input>
				</div>
				<div class="col-md-4">
					<form-select
						id="socio_unico"
						name="socio_unico"
						label="Numero Soci"
						:options="soci"
						item-value="value"
						item-label="label"
						:value="getValue('socio_unico')"
					></form-select>
				</div>
				<div class="col-md-4">
					<form-select
						id="liquidazione"
						name="liquidazione"
						label="Stato Liquidazione"
						:options="liquidazione"
						item-value="value"
						item-label="label"
						:value="getValue('liquidazione')"
					></form-select>
				</div>
			</div>

			<br />
			<h4>Riferimento Amministrativo</h4>

			<div class="row">
				<div class="col-md-6">
					<form-input
						label="Nome"
						id="riferimento_amministrativo_first_name"
						name="riferimento_amministrativo_first_name"
						:value="getValue('riferimento_amministrativo_first_name')"
					></form-input>
				</div>
				<div class="col-md-6">
					<form-input
						label="Cognome"
						id="riferimento_amministrativo_last_name"
						name="riferimento_amministrativo_last_name"
						:value="getValue('riferimento_amministrativo_last_name')"
					></form-input>
				</div>
			</div>

			<br />
			<h4>Notifica Fatturazione</h4>

			<div class="row">
				<div class="col-md-7">
					<form-input
						type="email"
						label="Email Notifica Generazione XML"
						id="notifica_fatturazione_xml"
						name="notifica_fatturazione_xml"
						:value="getValue('notifica_fatturazione_xml')"
					></form-input>
				</div>
			</div>

			<br />
			<h4>Data Retenction</h4>

			<div class="row">
				<div class="col-md-7">
					<form-input
						type="email"
						label="Notifica Email"
						id="fine_rapporto_email"
						name="fine_rapporto_email"
						:value="getValue('fine_rapporto_email')"
					></form-input>
				</div>
				<div class="col-md-5">
					<form-input
						type="number"
						min="0"
						step="1"
						label="Periodo"
						append-text="Anni"
						id="fine_rapporto_anni"
						name="fine_rapporto_anni"
						:value="getValue('fine_rapporto_anni')"
					></form-input>
				</div>
			</div>

			<br />
			<h4>Rappresentante Legale</h4>

			<div class="row">
				<div class="col-md-6">
					<form-input
						label="Nome"
						id="representative_first_name"
						name="representative_first_name"
						:value="getValue('representative_first_name')"
					></form-input>
				</div>
				<div class="col-md-6">
					<form-input
						label="Cognome"
						id="representative_last_name"
						name="representative_last_name"
						:value="getValue('representative_last_name')"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<form-select
						id="sesso"
						name="sesso"
						label="Sesso"
						:options="[
							{ id: 'M', label: 'Maschio' },
							{ id: 'F', label: 'Femmina' },
						]"
						item-value="id"
						item-label="label"
						:value="getValue('sesso')"
					></form-select>
				</div>
				<div class="col-md-4">
					<form-input
						label="Codice Fiscale"
						id="representative_codice_fiscale"
						name="representative_codice_fiscale"
						:value="getValue('representative_codice_fiscale')"
					></form-input>
				</div>
				<div class="col-md-4">
					<form-input
						label="Telefono"
						id="representative_phone"
						name="representative_phone"
						:value="getValue('representative_phone')"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<form-input
						type="date"
						placeholder="YYYY-MM-DD"
						label="Data di Nascita"
						id="data_nascita"
						name="data_nascita"
						:value="getValue('data_nascita_formatted')"
					></form-input>
				</div>
				<div class="col-md-4">
					<form-autocomplete
						id="representative_nascita_citta_id"
						name="representative_nascita_citta_id"
						label="Luogo di Nascita"
						:url="municipalities"
						:value="getValue('representative_nascita_citta_id')"
					></form-autocomplete>
				</div>
			</div>

			<div class="row">
				<div class="col-md-8">
					<form-input
						label="Indirizzo di Residenza"
						id="representative_residenza_indirizzo"
						name="representative_residenza_indirizzo"
						:value="getValue('representative_residenza_indirizzo')"
					></form-input>
				</div>
				<div class="col-md-4">
					<form-autocomplete
						id="representative_residenza_citta_id"
						name="representative_residenza_citta_id"
						label="Città di Residenza"
						:url="municipalities"
						:value="getValue('representative_residenza_citta_id')"
					></form-autocomplete>
				</div>
			</div>

			<br />
			<h4>Note</h4>

			<form-textarea id="note" name="note" :value="getValue('note')"></form-textarea>
		</form>

		<template v-slot:footer>
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'former_companies',
		refForm: '',
		urlupdate: '',
		id: '',
		datas: {},
	}),
	props: {
		urlstore: {
			type: String,
			default: '',
			required: true,
		},
		municipalities: {
			type: String,
			default: '',
			required: true,
		},
		provinces: {
			type: Array,
			default: null,
			required: true,
		},
		soci: {
			type: Array,
			default: null,
			required: true,
		},
		liquidazione: {
			type: Array,
			default: null,
			required: true,
		},
		reftable: {
			type: String,
			default: '',
			required: false,
		},
	},
	methods: {
		close: function() {
			this.assignData({})
			this.urlupdate = ''

			this.$refs[this.ref].close()
		},
		open: function(data) {
			if (!!data && typeof data === 'object') {
				if (!!data.urlupdate) this.urlupdate = data.urlupdate
				if (!!data.datas) this.assignData(data.datas)
			} else if (!!data && Array.isArray(data)) {
				this.assignData(data)
			} else {
				this.assignData({})
			}

			this.$refs[this.ref].open()
		},
		getValue: function(key, defaultValue) {
			if (!!this.datas[key]) return this.datas[key]
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		getAction: function() {
			return !!this.id ? this.urlupdate : this.urlstore
		},
		getMethod: function() {
			return !!this.id ? 'PATCH' : 'POST'
		},
		assignData: function(datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
				if (!!datas.id) this.id = datas.id
			} else {
				this.datas = {}
				this.id = ''
			}
		},
		submitForm: function() {
			return this.$root.request(
				this,
				this.getAction(),
				this.getMethod(),
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function(data) {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function() {
				self.close()
			})
		},
	},
	created: function() {
		this.refForm = this.ref + 'DataForm'
	},
}
</script>
