<template>
	<div :class="{ hero: true, 'auth-false': !auth }">
		<h2 className="hero-title" v-if="!!title">{{ title }}</h2>
		<p className="hero-subtitle headline-font" v-if="!!subtitle">{{ subtitle }}</p>
		<p className="hero-text" v-if="!!text" v-html="text"></p>
	</div>
</template>

<script>
export default {
	props: {
		auth: {
			type: Boolean,
			default: true,
			required: false,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		subtitle: {
			type: String,
			default: '',
			required: false,
		},
		text: {
			type: String,
			default: '',
			required: false,
		},
	},
}
</script>
