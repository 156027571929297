<template>
	<div>
		<datatable-info :ref-table="refTable"></datatable-info>
		<datatable-bulks :ref-table="refTable"></datatable-bulks>
		<datatable-exports :ref-table="refTable"></datatable-exports>
		<datatable-filters :ref-table="refTable"></datatable-filters>
		<datatable-length :ref-table="refTable"></datatable-length>
	</div>
</template>

<script>
export default {
	props: {
		refTable: {
			type: String,
			default: '',
			required: true,
		},
	},
}
</script>
