<template>
	<div class="bulk-item" ref="single_action">
		<form-select
			v-if="!!props.type && props.type.toLowerCase() == 'select'"
			:name="props.name"
			:label="props.label"
			:options="props.options"
			:item-label="props.itemLabel"
			:item-value="props.itemValue"
			:multiple="props.multiple"
			:value="value"
			:values="values"
			required
		></form-select>
		<form-autocomplete
			v-else-if="!!props.type && props.type.toLowerCase() == 'autocomplete'"
			:name="props.name"
			:label="props.label"
			:options="props.options"
			:url="props.optionsUrl"
			:item-label="props.itemLabel"
			:item-value="props.itemValue"
			:multiple="props.multiple"
			:value="value"
			:values="values"
			required
		></form-autocomplete>
		<form-segment
			v-else-if="!!props.type && props.type.toLowerCase() == 'segment'"
			:name="props.name"
			:label="props.label"
			:options="props.options"
			:item-label="props.itemLabel"
			:item-value="props.itemValue"
			:value="value"
			required
		></form-segment>
		<form-checkbox
			v-else-if="!!props.type && props.type.toLowerCase() == 'checkbox'"
			:name="props.name"
			:label="props.label"
			:options="props.options"
			:item-label="props.itemLabel"
			:item-value="props.itemValue"
			:value="value"
			:values="values"
			required
		></form-checkbox>
		<form-radio
			v-else-if="!!props.type && props.type.toLowerCase() == 'radio'"
			:name="props.ame"
			:label="props.label"
			:options="props.options"
			:item-label="props.itemLabel"
			:item-value="props.itemValue"
			:value="value"
			required
		></form-radio>
		<form-textarea
			v-else-if="!!props.type && props.type.toLowerCase() == 'textarea'"
			:name="props.name"
			:label="props.label"
			:value="value"
			required
		></form-textarea>
		<form-input
			v-else-if="!!props.type"
			:type="props.type"
			:name="props.name"
			:label="props.label"
			:showFormLink="false"
			:placeholder="props.type.toLowerCase() == 'date' ? 'YYYY-MM-DD' : null"
			:value="value"
			:min="props.numberMin"
			:max="props.numberMax"
			:step="props.numberStep"
			required
		></form-input>

		<btn
			block
			:title="props.title"
			:type="!!props.destructive ? 'danger' : 'secondary'"
			:action="executeBulk"
			:href="link"
			:disabled="!!getTable.isBulkDisabled"
		></btn>
	</div>
</template>

<script>
export default {
	props: {
		refTable: {
			type: String,
			default: '',
			required: true,
		},
		props: {
			type: Object,
			default: null,
			required: true,
		},
	},
	computed: {
		getTable: function() {
			return this.$root.$refs[this.refTable]
		},
		value: function() {
			return typeof this.defaultValue === 'string' || typeof this.defaultValue === 'number'
				? this.defaultValue
				: null
		},
		values: function() {
			return typeof this.defaultValue === 'array' ? this.defaultValue : null
		},
		isLink: function() {
			return this.props.method.toUpperCase() == 'LINK' ? true : false
		},
		link: function() {
			return !!this.isLink && !this.getTable.isBulkDisabled ? this.props.url : null
		},
	},
	methods: {
		getRequestBody: function() {
			let indexs = this.getTable.selectedsRow
			if (!indexs || !indexs.length) return false

			let output = {}

			output = this.getTable.exportParams()
			if (!output) return false

			let form = this.$root.getParamsFromDiv(this.$refs['single_action'])
			if (!!form) output.form = form

			return output
		},
		executeBulk: function() {
			if (!!this.isLink) return null

			this.getTable.showLoader()

			let body = this.getRequestBody()
			if (!body) {
				alert('Attenzione!\nDevi selezionare almeno un elemento')
				return this.getTable.hideLoader()
			}

			this.$root.request(
				this,
				this.props.url,
				this.props.method,
				body,
				response => {
					this.getTable.updateSelectedsRow([])
					this.$root.tableRefresh(this.refTable)

					this.getTable.hideLoader()

					if (response instanceof Object && typeof response.data === 'string')
						window.location.href = response.data
					else alert('Successo!\nOperazione eseguita correttamente.')
				},
				({ response, message }) => {
					this.getTable.hideLoader()
					alert('Errore!\nQualcosa è andato storto, si è pregati di riprovare.')
				}
			)
		},
	},
}
</script>
