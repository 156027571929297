<template>
	<div class="d-flex justify-content-center m-3">
		<div class="spinner-border text-primary" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
</template>

<script>
export default {}
</script>
