<template>
	<tbody v-if="!!loading">
		<tr>
			<td :colspan="getColsNumber">
				<datatable-loader></datatable-loader>
			</td>
		</tr>
	</tbody>
	<tbody
		v-else-if="!!tbody && !!tbody.length"
		:data-page="current"
		:data-selecteds="selectedsRow"
		:edit-all="editAll"
	>
		<tr v-if="!!selectable && !isEditMode && !editable" class="selected_info">
			<td :colspan="getColsNumber" v-if="!!selectedAllPages">
				Tutte le pagine selezionate (<btn
					type="link-text"
					title="Seleziona questa pagina"
					:action="selectThisPage"
				></btn
				>)
			</td>
			<td :colspan="getColsNumber" v-else-if="!!selectedThisPage">
				Questa pagina selezionata (<btn
					type="link-text"
					title="Seleziona tutte le pagine"
					:action="selectAllPages"
				></btn
				>)
			</td>
			<td :colspan="getColsNumber" v-else-if="!!selectedElement">
				{{ selectedsRow.length }} elemento selezionato
			</td>
			<td :colspan="getColsNumber" v-else-if="!!selectedElements">
				{{ selectedsRow.length }} elementi selezionato
			</td>
			<td :colspan="getColsNumber" v-else>
				Nessun elemento selezionato
			</td>
		</tr>

		<template v-for="(item, row_index) in items">
			<tr
				class="group"
				:key="row_index + '_1'"
				v-if="
					groupField &&
						!!item[groupField] &&
						((!!items[row_index - 1] &&
							items[row_index - 1][groupField] != item[groupField]) ||
							!items[row_index - 1])
				"
			>
				<td v-if="!!responsive && !!hasChild"></td>
				<td v-if="!!draggable"></td>
				<td v-if="!!selectable"></td>

				<td
					v-if="!!groupRow"
					:key="row_index + '_1' + row_index2"
					v-for="(itemGroup, row_index2) in groupRow"
					:colspan="itemGroup.colspan"
				>
					{{ item[itemGroup.data] }}
				</td>
				<td v-if="!groupRow" :colspan="getColsNumberGroup">{{ item[groupField] }}</td>

				<td v-if="!!showActions || !!editable"></td>
			</tr>
			<tr
				:key="row_index + '_2'"
				:ref="'tbody_tr_' + row_index"
				:class="{
					editable: !!editable && selectedIndex.indexOf(row_index) != -1 ? true : false,
					selected:
						!!selectable && selectedIndex.indexOf(item[selectable]) != -1
							? true
							: false,
					parent:
						!!responsive &&
						!!hasChild &&
						responsiveIndex.indexOf(item[responsive]) != -1
							? true
							: false,
				}"
			>
				<td v-if="!!responsive && !!hasChild" class="icon">
					<btn
						light
						table
						:action="() => toggleChild(item[responsive])"
						:icon="[
							'fad',
							responsiveIndex.indexOf(item[responsive]) != -1 ? 'minus' : 'plus',
						]"
					></btn>
				</td>
				<td v-if="!!draggable" class="icon">
					<div class="btn-group">
						<btn
							light
							table
							label
							:action="() => moveDown(item, row_index)"
							:icon="['fad', 'chevron-down']"
							v-if="row_index + 1 < items.length"
						></btn>
						<btn
							light
							table
							label
							:action="() => moveUp(item, row_index)"
							:icon="['fad', 'chevron-up']"
							v-if="row_index > 0"
						></btn>
					</div>
				</td>
				<td v-if="!!selectable" class="form-td">
					<div :style="{ display: 'flex' }">
						<form-checkbox
							:toggle="false"
							name="row_selected[]"
							:options="[{ value: item[selectable], label: '' }]"
							:checked="selectedIndex"
							@change="() => clickedRow(item[selectable])"
						></form-checkbox>
					</div>
				</td>

				<datatable-body-cell
					v-for="(value, col_index) in theader"
					:key="row_index + '_' + col_index"
					:rowIndex="row_index"
					:colIndex="col_index"
					:row="item"
					:col="value"
					:customRender="returnCustomRender(col_index)"
					:editable="!!editable && selectedIndex.indexOf(row_index) != -1 ? true : false"
				></datatable-body-cell>

				<td
					v-if="!!editable && !editAll && selectedIndex.indexOf(row_index) != -1"
					class="actions"
				>
					<div :style="{ display: 'flex' }">
						<btn
							:action="() => submitFormRow(row_index)"
							type="success"
							light
							table
							label="Salva"
							:icon="['fad', 'check']"
						/>
						<btn
							:action="() => unselectRow(row_index)"
							type="danger"
							light
							table
							label="Annulla"
							:icon="['fad', 'times']"
						/>
					</div>
				</td>

				<datatable-row-actions
					v-else-if="!!showActions && !selectedIndex.length"
					:actions="getActions(item)"
					:row="item"
					:editRefmodal="editRefmodal"
					:row_index="row_index"
					:httpAppend="httpAppend"
					@refreshTable="refreshTable"
				></datatable-row-actions>
				<td v-else-if="!!editable"></td>
			</tr>
			<tr
				:key="row_index + '_3'"
				class="child"
				v-if="!!responsive && !!hasChild && responsiveIndex.indexOf(item[responsive]) != -1"
			>
				<td :colspan="getColsNumber">
					<ul>
						<datatable-body-child-cell
							v-for="(value, col_index) in tchild"
							:key="row_index + '_' + col_index"
							:rowIndex="row_index"
							:colIndex="col_index"
							:row="item"
							:col="value"
							:customRender="returnCustomRender(col_index)"
						></datatable-body-child-cell>
					</ul>
				</td>
			</tr>
		</template>
	</tbody>
	<tbody v-else>
		<tr>
			<td :colspan="getColsNumber">
				<i>Nessun record presente...</i>
			</td>
		</tr>
	</tbody>
</template>

<script>
export default {
	data: () => ({
		items: [],
		selectedUrls: {},
		selectedIndex: [],
		responsiveIndex: [],
		resultsNumber: 0,
	}),
	props: {
		loading: {
			type: Boolean,
			default: false,
			required: false,
		},
		thead: {
			type: Array,
			default: () => [],
			required: true,
		},
		tbody: {
			type: Array,
			default: () => [],
			required: true,
		},
		length: {
			type: Number,
			default: 1,
			required: true,
		},
		current: {
			type: Number,
			default: 1,
			required: true,
		},
		showActions: {
			type: Boolean,
			default: true,
			required: true,
		},
		fieldActions: {
			type: String,
			default: 'actions',
			required: false,
		},
		editRefmodal: {
			type: String,
			default: null,
			required: false,
		},
		customRender: {
			type: Array,
			default: () => [],
			required: true,
		},
		editable: {
			type: Boolean,
			default: false,
			required: false,
		},
		selectable: {
			type: String,
			default: null,
			required: false,
		},
		draggable: {
			type: String,
			default: null,
			required: false,
		},
		updateSelectedsRow: {
			type: Function,
			default: null,
			required: false,
		},
		selectedsRow: {
			type: Array,
			default: () => [],
			required: false,
		},
		hasChild: {
			type: Boolean,
			default: false,
			required: false,
		},
		responsive: {
			type: String,
			default: null,
			required: false,
		},
		move: {
			type: Function,
			default: null,
			required: false,
		},
		groupField: {
			type: String,
			default: null,
			required: false,
		},
		editAll: {
			type: Boolean,
			default: false,
			required: false,
		},
		groupRow: {
			type: Array,
			default: null,
			required: false,
		},
		httpAppend: {
			type: Object,
			default: null,
			required: false,
		},
	},
	computed: {
		getColsNumber: function() {
			let num = this.thead.filter(element =>
				!element.responsive && !element.hidden ? true : false
			)

			num = num.length

			if (!!this.showActions || !!this.editable) num++
			if (!!this.selectable) num++
			if (!!this.draggable) num++
			if (!!this.responsive && !!this.hasChild) num++

			return num
		},
		getColsNumberGroup: function() {
			let num = this.thead.filter(element =>
				!element.responsive && !element.hidden ? true : false
			)

			num = num.length

			return num
		},
		theader: function() {
			let t = this.thead

			if (!!this.groupField && !this.editable) {
				for (var i = 0; i < t.length; i++) {
					if (t[i].data == this.groupField) {
						t[i + 1].colspan = 2
					}
				}
			}

			return t.filter(element =>
				!element.responsive &&
				!element.hidden &&
				(this.groupField != element.data || !!this.editable)
					? true
					: false
			)
		},
		tchild: function() {
			return this.thead.filter(element =>
				!!element.responsive && !element.hidden ? true : false
			)
		},
		isEditMode: function() {
			return (
				!!this.selectedUrls &&
				!!Object.keys(this.selectedUrls) &&
				!!Object.keys(this.selectedUrls).length
			)
		},
		selectedAllPages: function() {
			return (
				!!this.selectedsRow &&
				this.selectedsRow.length == 1 &&
				this.selectedsRow.indexOf(-1) != -1
			)
		},
		selectedThisPage: function() {
			return (
				!!this.selectedsRow &&
				this.selectedsRow.length >= this.resultsNumber &&
				this.selectedsRow.indexOf(-1) != -1
			)
		},
		selectedElement: function() {
			return (
				!!this.selectedsRow &&
				this.selectedsRow.length == 1 &&
				this.selectedsRow.indexOf(-1) == -1
			)
		},
		selectedElements: function() {
			return (
				!!this.selectedsRow &&
				this.selectedsRow.length > 0 &&
				this.selectedsRow.indexOf(-1) == -1
			)
		},
		getAllItemsSelectable: function() {
			if (!this.selectable || !this.items || !this.items.length) return []

			return this.items
				.filter(element => typeof element[this.selectable] !== 'undefined')
				.map(element => element[this.selectable])
		},
	},
	methods: {
		clickedRow: function(index) {
			if (!!this.selectable) {
				if (this.selectedIndex.indexOf(index) != -1) this.unselectRow(index)
				else {
					this.selectedIndex.push(index)

					if (this.selectedIndex.indexOf(-1) != -1) {
						if (
							!!this.selectedIndex &&
							this.selectedIndex.length == this.resultsNumber + 1
						)
							this.selectedIndex.push(-1)
						else if (
							!!this.selectedIndex &&
							this.selectedIndex.length > this.resultsNumber + 1
						)
							this.selectedIndex = [-1]
						else if (
							!!this.selectedIndex &&
							this.selectedIndex.length < this.resultsNumber + 1
						)
							this.unselectRow(-1)
					} else {
						if (!!this.selectedIndex && this.selectedIndex.length == this.resultsNumber)
							this.selectedIndex.push(-1)
						else if (
							!!this.selectedIndex &&
							this.selectedIndex.length > this.resultsNumber
						)
							this.selectedIndex = [-1]
					}
				}
			}

			this.updateSelectedsRow(this.selectedIndex)
		},
		selectEditRow: function(urlupdate, index) {
			this.selectedIndex.push(index)
			this.selectedUrls[index] = urlupdate

			this.updateSelectedsRow(this.selectedIndex)
		},
		unselectRow: function(index) {
			let indexArr = this.selectedIndex.indexOf(index)
			if (indexArr != -1) {
				this.selectedIndex.splice(indexArr, 1)
				if (!!this.selectedUrls[index] && index != -1) delete this.selectedUrls[index]

				if (index != -1) {
					let indexAll = this.selectedIndex.indexOf(-1)

					if (indexAll != -1) {
						if (
							!!this.selectedIndex &&
							this.selectedIndex.length < this.resultsNumber + 1
						)
							this.selectedIndex.splice(indexAll, 1)
					} else {
						if (!!this.selectedIndex && this.selectedIndex.length == this.resultsNumber)
							this.selectedIndex.push(-1)
						else if (
							!!this.selectedIndex &&
							this.selectedIndex.length > this.resultsNumber
						)
							this.selectedIndex = [-1]
					}
				}
			}

			this.updateSelectedsRow(this.selectedIndex)
		},
		submitFormRow: function(index) {
			if (!this.selectedUrls[index]) return false

			return this.$root.requestPatch(
				this,
				this.selectedUrls[index],
				this.$root.getParamsFromDiv(this.$refs['tbody_tr_' + index][0]),
				function(data) {
					this.submitFormCallback(index, data)
				}
			)
		},
		submitFormCallback: function(index, data) {
			this.refreshTable()
			this.unselectRow(index)
		},
		returnCustomRender: function(indexCol) {
			for (var i = 0; i < this.customRender.length; i++) {
				let element = this.customRender[i]

				if (
					typeof element.index !== 'undefined' &&
					element.index === indexCol &&
					typeof element.render !== 'undefined'
				)
					return element.render
			}

			return null
		},
		setItems: function() {
			this.items = this.tbody.filter(element =>
				typeof element.tfoot !== 'undefined' && !!element.tfoot ? false : true
			)

			this.resultsNumber = this.items.length
		},
		init: function(reset = false) {
			if (!!reset) {
				this.selectedUrls = {}
				this.selectedIndex = []
			}

			this.setItems()

			this.selectedIndex = this.selectedsRow

			if (!!this.editAll) {
				for (var i = 0; i < this.items.length; i++) {
					this.selectEditRow(null, i)
				}
			}
		},
		refreshTable: function() {
			this.$parent.refreshTable()
		},
		getActions: function(item) {
			return !!item[this.fieldActions] ? item[this.fieldActions] : []
		},
		unselectChild: function(index) {
			let indexArr = this.responsiveIndex.indexOf(index)
			if (indexArr != -1) this.responsiveIndex.splice(indexArr, 1)
		},
		toggleChild: function(index) {
			if (!!this.responsive && !!this.hasChild) {
				if (this.responsiveIndex.indexOf(index) != -1) {
					this.unselectChild(index)
				} else {
					this.responsiveIndex.push(index)
				}
			}
		},
		moveDown: function(item, index) {
			this.move('down', item, index)
		},
		moveUp: function(item, index) {
			this.move('up', item, index)
		},
		getItemFromIndex: function(index) {
			return typeof this.items[index] !== 'undefined' ? this.items[index] : null
		},
		selectThisPage: function() {
			if (
				!!this.selectedIndex &&
				this.selectedIndex.length >= this.resultsNumber &&
				this.selectedIndex.indexOf(-1) != -1
			)
				return false

			let output = this.getAllItemsSelectable
			output.push(-1)

			this.selectedIndex = output

			this.updateSelectedsRow(this.selectedIndex)
		},
		selectAllPages: function() {
			if (
				!!this.selectedIndex &&
				this.selectedIndex.length == 1 &&
				this.selectedIndex.indexOf(-1) != -1
			)
				return false

			this.selectedIndex = [-1]

			this.updateSelectedsRow(this.selectedIndex)
		},
	},
	created: function() {
		this.init()
	},
	beforeUpdate: function() {
		this.init()
	},
	mounted: function() {
		this.$nextTick(function() {
			this.$parent.setHeight()
		})
	},
	updated: function() {
		this.$nextTick(function() {
			this.$parent.setHeight()
		})
	},
}
</script>
