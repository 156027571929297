<template>
	<li :class="navItemClassObject">
		<span class="nav-link" @click="toggle">
			<div :class="navIconClassObject">
				<font-awesome-icon :icon="icon" v-if="icon && icon.length"></font-awesome-icon>
			</div>
			<div class="nav-title">{{ title }}</div>
			<div class="nav-arrow" v-if="navArrow">
				<font-awesome-icon :icon="['fad', 'chevron-up']" v-if="open"></font-awesome-icon>
				<font-awesome-icon :icon="['fad', 'chevron-down']" v-else></font-awesome-icon>
			</div>
		</span>
		<ul class="nav sub-menu" v-if="subMenu">
			<slot></slot>
		</ul>
	</li>
</template>

<script>
export default {
	data: () => ({
		open: true,
	}),
	props: {
		icon: {
			type: Array,
			default: null,
			required: false,
		},
		title: {
			type: String,
			default: null,
			required: true,
		},
	},
	computed: {
		navItemClassObject: function() {
			return {
				'nav-item': true,
				active: !!this.$slots.default && this.open,
			}
		},
		navIconClassObject: function() {
			return {
				'nav-icon': true,
				full: !!this.$root.asideCompressed && !this.$root.asideHover,
			}
		},
		navArrow: function() {
			return this.$slots.default
		},
		subMenu: function() {
			return (
				!!this.$slots.default &&
				!!this.open &&
				(!this.$root.asideCompressed || !!this.$root.asideHover)
			)
		},
	},
	methods: {
		toggle: function() {
			if (!this.$slots.default) return

			this.open = !!this.open ? false : true
		},
	},
}
</script>
