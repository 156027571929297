<template>
	<block-sidebar title="Numero Risultati">
		<template v-slot:icon>
			<font-awesome-icon :icon="['fad', 'list-ol']"></font-awesome-icon>
		</template>

		<form-select
			name="LENGTH_FILTER"
			:options="options"
			:value="defaultValue"
			ref="LENGTH_FILTER"
			v-if="defaultValue"
			required
		></form-select>

		<div class="btn-group btn-block">
			<btn type="success" block :action="execute" title="Applica"></btn>
		</div>
	</block-sidebar>
</template>

<script>
export default {
	data: () => ({
		options: [
			{ label: '25', value: 25 },
			{ label: '50', value: 50 },
			{ label: '75', value: 75 },
			{ label: '100', value: 100 },
			//{ label: 'Tutti', value: -1 },
		],
		defaultValue: null,
	}),
	props: {
		refTable: {
			type: String,
			default: '',
			required: true,
		},
	},
	mounted: function () {
		if (typeof this.getTable !== 'undefined')
			this.defaultValue = this.getTable.pagination.length
	},
	computed: {
		getTable: function () {
			return this.$root.$refs[this.refTable]
		},
	},
	methods: {
		getValue: function () {
			let output = null
			if (!!this.$refs['LENGTH_FILTER']) output = this.$refs['LENGTH_FILTER'].internalValue

			return output
		},
		execute: function () {
			let body = this.getValue()

			this.getTable.updateLength(body)
		},
	},
}
</script>
