<template>
	<div :class="classObject">
		<div class="left">
			<span class="breadcrumb-icon">
				<font-awesome-icon :icon="['fad', 'stream']"></font-awesome-icon>
			</span>
			<slot name="breadcrumbs"></slot>
		</div>

		<div class="right">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		classObject: function() {
			return {
				bar: true,
				'aside-collapsed': !!this.$root.asideCompressed,
				'no-sidebar': true,
			}
		},
	},
}
</script>
