import moment from 'moment'

const Helper = {
    install(Vue, options) {
        Vue.directive('click-outside', {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    // here I check that click was outside the el and his childrens
                    if (!(el == event.target || el.contains(event.target))) {
                        // and if it did, call method provided in attribute value
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener('click', el.clickOutsideEvent)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.clickOutsideEvent)
            },
        })

        Vue.mixin({
            data: () => ({
                asideCompressed: true,
                sidebarHidden: true,
                asideHover: false,
                requestHeaders: {
                    Accept: 'application/json; charset=utf-8',
                    'Content-Type': 'application/json; charset=utf-8',
                },
            }),
            methods: {
                showModal: function (modalName) {
                    this.$refs[modalName].open()
                },
                showModalWithData: function (modalName, datas) {
                    this.$refs[modalName].open(datas)
                },
                toogleSidebar: function () {
                    this.sidebarHidden = !!this.sidebarHidden ? false : true
                },
                toogleAside: function () {
                    this.asideCompressed = !!this.asideCompressed ? false : true
                },
                toogleAsideHover: function () {
                    this.asideHover = !!this.asideHover ? false : true
                },
                request: function (that, url, method, params, callback, errCallback) {
                    if (!method) method = 'GET'

                    return axios({
                        url: url,
                        method: method.toUpperCase(),
                        params: !!params && method.toUpperCase() == 'GET' ? params : {},
                        data: !!params && method.toUpperCase() != 'GET' ? params : {},
                        headers: this.requestHeaders,
                    })
                        .then(({ data, status, statusText }) => {
                            if (typeof callback === 'function') return callback.apply(that, [data])
                        })
                        .catch(({ response, request, message, config }) => {
                            if (typeof errCallback === 'function')
                                return errCallback.apply(that, [{ response, message }])
                            else return false
                        })
                },
                requestGet: function (that, url, callback) {
                    return this.request(that, url, 'GET', {}, callback)
                },
                requestPost: function (that, url, params, callback) {
                    return this.request(that, url, 'POST', params, callback)
                },
                requestPatch: function (that, url, params, callback) {
                    return this.request(that, url, 'PATCH', params, callback)
                },
                requestDelete: function (that, url, callback) {
                    if (!!confirm("Sei sicuro? L'operazione non è reversibile."))
                        return this.request(that, url, 'DELETE', {}, callback)
                },
                getParamsFromForm: function (formEl) {
                    let params = {}

                    let formData = new FormData(formEl)
                    formData.forEach((value, key) => {
                        if (key.includes('[]')) {
                            if (typeof params[key.replace('[]', '')] === 'undefined')
                                params[key.replace('[]', '')] = []

                            params[key.replace('[]', '')].push(value)
                        } else {
                            params[key] = value
                        }
                    })

                    return params
                },
                getSelectValues: function (select) {
                    var result = []
                    var options = select && select.options
                    var opt

                    for (var i = 0, iLen = options.length; i < iLen; i++) {
                        opt = options[i]

                        if (opt.selected) {
                            result.push(opt.value || opt.text)
                        }
                    }
                    return result
                },
                getParamsFromDiv: function (divEl) {
                    let params = {}
                    let elements = divEl.querySelectorAll('input, select, textarea')

                    elements.forEach((value, key) => {
                        let selectorName = false

                        if (!value.name) return false

                        switch (value.tagName.toUpperCase()) {
                            case 'INPUT':
                                selectorName =
                                    value.tagName.toUpperCase() + '.' + value.type.toUpperCase()
                                break
                            default:
                                selectorName = value.tagName.toUpperCase()
                        }

                        switch (selectorName) {
                            case 'INPUT.CHECKBOX':
                                let checkedArrCheck = []

                                document.getElementsByName(value.name).forEach(element2 => {
                                    if (!!element2.checked) {
                                        checkedArrCheck.push(element2.value.toString())
                                    }
                                })

                                params[value.name] = !!checkedArrCheck ? checkedArrCheck : ''
                                break
                            case 'INPUT.RADIO':
                                let checkedArrRad = ''

                                document.getElementsByName(value.name).forEach(element2 => {
                                    if (!!element2.checked) {
                                        checkedArrRad = element2.value.toString()
                                    }
                                })

                                params[value.name] = checkedArrRad
                                break
                            case 'SELECT':
                                if (!value.multiple) {
                                    params[value.name] = value.value.toString()
                                } else {
                                    params[value.name.replace('[]', '')] = this.getSelectValues(
                                        value
                                    )
                                }
                                break
                            default:
                                params[value.name] = value.value.toString()
                        }
                    })

                    return params
                },
                resetsFromDiv: function (divEl) {
                    let elements = divEl.querySelectorAll('input, select, textarea')

                    elements.forEach((value, key) => {
                        let selectorName = false

                        switch (value.tagName.toUpperCase()) {
                            case 'INPUT':
                                selectorName =
                                    value.tagName.toUpperCase() + '.' + value.type.toUpperCase()
                                break
                            default:
                                selectorName = value.tagName.toUpperCase()
                        }

                        switch (selectorName) {
                            case 'INPUT.CHECKBOX':
                                document.getElementsByName(value.name).forEach(element2 => {
                                    if (!!element2.checked) {
                                        element2.checked = false
                                    }
                                })
                                break
                            case 'INPUT.RADIO':
                                document.getElementsByName(value.name).forEach(element2 => {
                                    if (!!element2.checked) {
                                        element2.checked = false
                                    }
                                })
                                break
                            default:
                                value.value = ''
                        }
                    })

                    return true
                },
                submitForm: function (id) {
                    document.getElementById(id).click()
                },
                tableRefresh: function (table, related) {
                    if (typeof related === 'undefined') related = false

                    this.$refs[table].refreshTable(related)
                },
                normalize: function (item) {
                    return item.toString().toLowerCase()
                },
                updateFilterTable: function (table, type, field, value) {
                    this.$refs[table].updateFilter(type, field, value)
                },
                filterTable: function (table) {
                    this.$refs[table].filter()
                },
                resetFilterTable: function (table) {
                    this.$refs[table].resetFilters()
                },
                formatFileTableCell: function (item, row, meta) {
                    if (!!item) return "<a href=' " + item.url + " '>" + item.filename + '</a>'
                    else return ''
                },
                formatBoolTableCell: function (item, row, meta) {
                    if (!!item && item != false) return 'Si'
                    else return 'No'
                },
                formatDateTableCell: function (item, format, row, meta) {
                    if (!item) return ''

                    return moment(item).format(format)
                },
                reload: function () {
                    window.location.reload(true)
                },
                issetValueInArray: function (array, field, value) {
                    if (!array || !field) return false

                    for (let index = 0; index < array.length; index++) {
                        if (
                            typeof array[index][field] !== 'undefined' &&
                            array[index][field] == value
                        )
                            return index
                    }

                    return false
                },
            },
        })
    },
}

export default Helper
