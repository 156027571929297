<template>
	<div style="display: flex">
		<header-button
			v-for="(item, index) in items"
			:key="index"
			:icon="item.icon"
			:link="item.link"
			:items="item.childrens"
		></header-button>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => [],
			required: true,
		},
	},
}
</script>
