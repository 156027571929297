<template>
	<btn
		light
		type="light"
		header
		:icon="['fad', !!this.$root.asideCompressed ? 'bars' : 'bars']"
		:action="toogleAside"
	></btn>
</template>

<script>
export default {
	methods: {
		toogleAside: function() {
			this.$root.toogleAside()
		},
	},
}
</script>
