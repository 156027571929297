<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Unità Immobiliari">
		<form :id="refForm" :ref="refForm">
			<form-autocomplete
				label="Unità Immobiliare"
				id="real_estate_id"
				name="real_estate_id"
				:url="real_estates"
				:value="getValue('real_estate_id')"
				:readonly="!!getValue('real_estate_id')"
				required
			></form-autocomplete>

			<form-input
				type="number"
				id="preventivo"
				name="preventivo"
				step="0.01"
				min="0"
				label="Preventivo"
				:value="getValue('preventivo')"
				append-text="EUR"
				required
				v-if="!id || !!getValue('is_preventivo_editable')"
			></form-input>
			<form-input
				label="Preventivo"
				:value="getValue('preventivo_display')"
				:readonly="true"
				v-else
			></form-input>

			<form-input
				type="number"
				id="integrazione"
				name="integrazione"
				step="0.01"
				min="0"
				label="Integrazione"
				:value="getValue('integrazione')"
				append-text="EUR"
				:required="!!getValue('integrazione')"
				v-if="!!id && !!getValue('is_integrazione_editable')"
			></form-input>
			<form-input
				label="Integrazione"
				:value="getValue('integrazione_display')"
				:readonly="true"
				v-else-if="!!id && !getValue('is_integrazione_editable')"
			></form-input>

			<form-input
				type="number"
				id="consuntivo"
				name="consuntivo"
				step="0.01"
				min="0"
				label="Consuntivo"
				:value="getValue('consuntivo')"
				append-text="EUR"
				:required="!!getValue('consuntivo')"
				v-if="!!id && !!getValue('is_consuntivo_editable')"
			></form-input>
			<form-input
				label="Consuntivo"
				:value="getValue('consuntivo_display')"
				:readonly="true"
				v-else-if="!!id && !getValue('is_consuntivo_editable')"
			></form-input>
		</form>

		<template v-slot:footer>
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"> </btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'real_estate_modal',
		refForm: '',
		urlupdate: '',
		id: '',
		datas: {},
	}),
	props: {
		urlstore: {
			type: String,
			default: '',
			required: true,
		},
		reftable: {
			type: String,
			default: '',
			required: false,
		},
		real_estates: {
			type: String,
			default: '',
			required: false,
		},
	},
	created: function () {
		this.refForm = this.ref + 'DataForm'
	},
	methods: {
		close: function () {
			this.assignData({})
			this.urlupdate = ''

			this.$refs[this.ref].close()
		},
		open: function (data) {
			if (!!data && typeof data === 'object') {
				if (!!data.urlupdate) this.urlupdate = data.urlupdate
				if (!!data.datas) this.assignData(data.datas)
			} else if (!!data && Array.isArray(data)) {
				this.assignData(data)
			} else {
				this.assignData({})
			}

			this.$refs[this.ref].open()
		},
		getValue: function (key, defaultValue) {
			if (key === '') return this.datas
			else if (this.datas[key] !== null) return this.datas[key]
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		getAction: function () {
			return !!this.id ? this.urlupdate : this.urlstore
		},
		getMethod: function () {
			return !!this.id ? 'PATCH' : 'POST'
		},
		assignData: function (datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
				if (!!datas.id) this.id = datas.id
			} else {
				this.datas = {}
				this.id = ''
			}
		},
		submitForm: function () {
			return this.$root.request(
				this,
				this.getAction(),
				this.getMethod(),
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function (data) {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function () {
				self.close()
			})
		},
	},
}
</script>
