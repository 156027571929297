<template>
	<div class="form-group">
		<label v-if="!!label">{{ label }} <span class="form-required" v-if="!!required">*</span></label>
		<div
			v-for="option in options"
			class="custom-control"
			:class="{ 'custom-switch': !!toggle, 'custom-checkbox': !toggle }"
		>
			<input
				type="checkbox"
				class="custom-control-input"
				:class="{ 'is-invalid': !!error }"
				:id="name + '_' + getValue(option)"
				:name="name"
				:value="getValue(option)"
				:required="required"
				:disabled="disabled"
				:readonly="readonly"
				v-model="internalChecked"
				v-on:change="$emit('change', $event.target.checked)"
				@change="emitEvent"
			/>
			<label
				class="custom-control-label"
				:class="{ 'no-label': !getLabel(option) }"
				:for="name + '_' + getValue(option)"
				>{{ getLabel(option) }}</label
			>
			<span class="invalid-feedback" role="alert" v-if="!!error">
				<strong>{{ error }}</strong>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		internalChecked: [],
	}),
	props: {
		required: {
			type: Boolean,
			default: false,
			required: false,
		},
		toggle: {
			type: Boolean,
			default: true,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		name: {
			type: String,
			default: '',
			required: false,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		itemValue: {
			type: String,
			default: 'value',
			required: false,
		},
		itemLabel: {
			type: String,
			default: 'label',
			required: false,
		},
		options: {
			type: Array,
			default: () => [],
			required: true,
		},
		checked: {
			type: Array,
			default: () => [],
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
			required: false,
		},
		tableRef: {
			type: String,
			default: null,
			required: false,
		},
		tableFilterType: {
			type: String,
			default: null,
			required: false,
		},
	},
	created: function() {
		this.internalChecked = this.checked
	},
	beforeUpdate: function() {
		this.internalChecked = this.checked
	},
	methods: {
		getLabel: function(option) {
			return option[this.itemLabel]
		},
		getValue: function(option) {
			return option[this.itemValue]
		},
		emitEvent: function() {
			if (!this.tableRef) return false

			this.$root.updateFilterTable(
				this.tableRef,
				this.tableFilterType,
				this.name,
				this.internalChecked
			)
		},
		reset: function() {
			this.internalChecked = []
			this.emitEvent()
		},
	},
}
</script>
