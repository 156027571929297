<template>
	<div class="footer">
		<div class="pagination-info">
			<span class="info-text">
				<span class="from">{{ from }}</span
				>—<span class="to">{{ to }}</span> di <span class="total">{{ total }}</span></span
			>
		</div>
		<ul class="pagination">
			<datatable-pagination-item
				v-if="!!showFirstLast"
				:icon="['fad', 'angle-double-left']"
				label="Inizio"
				:action="goFirst"
				:disabled="!!isFirst"
				classType="first"
			></datatable-pagination-item>

			<datatable-pagination-item
				:icon="['fad', 'angle-left']"
				label="Precedente"
				:action="goPrev"
				:disabled="!!isFirst"
				classType="prev"
			></datatable-pagination-item>

			<datatable-pagination-item
				:icon="['fad', 'angle-right']"
				label="Successiva"
				:action="goNext"
				:disabled="!!isLast"
				classType="next"
			></datatable-pagination-item>

			<datatable-pagination-item
				v-if="!!showFirstLast"
				:icon="['fad', 'angle-double-right']"
				label="Fine"
				:action="goLast"
				:disabled="!!isLast"
				classType="last"
			></datatable-pagination-item>
		</ul>
	</div>
</template>

<script>
export default {
	data: () => ({
		showFirstLast: true,
	}),
	props: {
		current: {
			type: Number,
			default: 1,
			required: true,
		},
		first: {
			type: Number,
			default: 1,
			required: true,
		},
		last: {
			type: Number,
			default: 1,
			required: true,
		},
		from: {
			type: Number,
			default: 1,
			required: true,
		},
		to: {
			type: Number,
			default: 1,
			required: true,
		},
		total: {
			type: Number,
			default: 1,
			required: true,
		},
		setPage: {
			type: Function,
			default: null,
			required: true,
		},
	},
	computed: {
		isFirst: function() {
			return this.current == 1
		},
		isLast: function() {
			return this.current == this.last
		},
		prev: function() {
			if (this.current < 1) return 1
			else return this.current - 1
		},
		next: function() {
			if (this.current > this.total) return this.total
			else return this.current + 1
		},
	},
	methods: {
		goFirst: function() {
			return !!this.isFirst ? null : this.setPage(this.first)
		},
		goPrev: function() {
			return !!this.isFirst ? null : this.setPage(this.prev)
		},
		goNext: function() {
			return !!this.isLast ? null : this.setPage(this.next)
		},
		goLast: function() {
			return !!this.isLast ? null : this.setPage(this.last)
		},
	},
}
</script>
