<template>
	<div
		ref="datatable"
		class="datatable"
		:class="{ scrollable: !!scrollable }"
		:style="{ 'min-height': minHeight + 'px' }"
	>
		<table ref="datatable_table" :id="id" :class="classObject">
			<thead>
				<tr>
					<th v-if="!!hasChild" :style="{ width: '50px' }"></th>
					<th v-if="!!draggable" :style="{ width: '75px' }"></th>
					<th v-if="!!selectable" class="form-th" :style="{ width: '50px' }">
						<div :style="{ display: 'flex' }">
							<form-checkbox
								:toggle="false"
								:options="[{ value: -1, label: '' }]"
								:checked="selectedsRow"
								@change="selectAll"
							></form-checkbox>
						</div>
					</th>
					<datatable-header-item
						v-for="(item, index) in theader"
						:key="index"
						:item="item"
						:index="index"
						:orderingEnabled="sort.enabled"
						:ordering="sort.params"
						:groupField="groupField"
						:editable="editable"
					></datatable-header-item>
					<th
						class="actions"
						v-if="!!showActions || !!editable"
						:style="{
							'min-width': !scrollable ? '50px' : null,
							padding: !!scrollable ? '0' : null,
						}"
					></th>
				</tr>
			</thead>
			<datatable-body
				ref="tbody"
				:loading="http.loader"
				:thead="thead"
				:tbody="tbody"
				:length="pagination.length"
				:current="pagination.pages.current"
				:showActions="showActions"
				:fieldActions="fieldActions"
				:customRender="defs"
				:editRefmodal="editRefmodal"
				:editable="editable"
				:selectable="selectable"
				:draggable="draggable"
				:updateSelectedsRow="updateSelectedsRow"
				:selectedsRow="selectedsRow"
				:hasChild="hasChild"
				:responsive="responsive"
				:move="move"
				:groupField="groupField"
				:editAll="editAll"
				:groupRow="groupRowMap"
				:httpAppend="httpAppend"
			></datatable-body>
			<tfoot
				v-if="
					(!!editable && !!createUrl) ||
						!!showEditAll ||
						!!showSaveAll ||
						(!!bodyFooter && !!bodyFooter.length)
				"
			>
				<tr v-for="(item, row_index) in bodyFooter" :key="row_index">
					<td v-if="!!hasChild"></td>
					<td v-if="!!draggable"></td>
					<td v-if="!!selectable"></td>
					<datatable-body-cell
						v-for="(value, col_index) in theader"
						:key="row_index + '_' + col_index"
						:rowIndex="row_index"
						:colIndex="col_index"
						:row="item"
						:col="value"
						:customRender="returnCustomRender(col_index)"
					></datatable-body-cell>
					<td v-if="!!showActions || !!editable"></td>
				</tr>

				<tr v-if="!!showEditAll || !!showSaveAll" class="bulk-edit">
					<td :colspan="colspan">
						<div :style="{ display: 'flex' }">
							<btn
								:action="startEditAll"
								light
								table
								title="Modifica"
								:icon="['fad', 'pencil']"
								v-if="!!showEditAll"
							/>
							<btn
								:action="startSaveAll"
								type="success"
								light
								table
								title="Salva"
								:icon="['fad', 'check']"
								v-if="!!showSaveAll"
							/>
						</div>
					</td>
				</tr>

				<tr class="editable" v-if="!!editable && !!createUrl" ref="tfoot_create">
					<td v-if="!!hasChild"></td>
					<td v-if="!!draggable"></td>
					<td v-if="!!selectable"></td>
					<datatable-form-cell
						v-for="(value, col_index) in thead"
						:key="col_index"
						:colIndex="col_index"
						:col="value"
					></datatable-form-cell>
					<td class="actions">
						<div :style="{ display: 'flex' }">
							<btn
								:action="submitFormRow"
								type="success"
								light
								table
								label="Salva"
								:icon="['fad', 'plus']"
							/>
						</div>
					</td>
				</tr>
			</tfoot>
		</table>
		<datatable-pagination
			v-if="!draggable && !!pagination.enabled"
			:current="pagination.pages.current"
			:first="pagination.pages.first"
			:last="pagination.pages.last"
			:from="pagination.results.from"
			:to="pagination.results.to"
			:total="pagination.results.total"
			:set-page="changePage"
		></datatable-pagination>
	</div>
</template>

<script>
export default {
	data: () => ({
		http: {
			url: null,
			summary: null,
			loader: true,
		},
		pagination: {
			enabled: true,
			length: 25,
			pages: {
				first: 1,
				last: 1,
				current: 1,
			},
			results: {
				from: 0,
				to: 0,
				total: 0,
			},
		},
		sort: {
			enabled: true,
			params: null,
		},
		search: {
			enabled: true,
			params: null,
		},
		selectedsRow: [],
		thead: [],
		tbody: [],
		summary: null,
		refreshFilters: false,
		editAll: false,
		minHeight: null,
	}),
	props: {
		id: {
			type: String,
			default: null,
			required: false,
		},
		hideHeader: {
			type: Boolean,
			default: false,
			required: false,
		},
		showActions: {
			type: Boolean,
			default: true,
			required: false,
		},
		fieldActions: {
			type: String,
			default: 'actions',
			required: false,
		},
		editRefmodal: {
			type: String,
			default: null,
			required: false,
		},
		url: {
			type: String,
			default: null,
			required: true,
		},
		summaryUrl: {
			type: String,
			default: null,
			required: false,
		},
		cols: {
			type: Array,
			default: () => [],
			required: false,
		},
		length: {
			type: Number,
			default: 25,
			required: false,
		},
		refTable2: {
			type: String,
			default: null,
			required: false,
		},
		defs: {
			type: Array,
			default: () => [],
			required: false,
		},
		createUrl: {
			type: String,
			default: null,
			required: false,
		},
		editable: {
			type: Boolean,
			default: false,
			required: false,
		},
		selectable: {
			type: String,
			default: null,
			required: false,
		},
		draggable: {
			type: String,
			default: null,
			required: false,
		},
		border: {
			type: Boolean,
			default: false,
			required: false,
		},
		bulks: {
			type: Array,
			default: () => [],
			required: false,
		},
		responsive: {
			type: String,
			default: null,
			required: false,
		},
		pagingEnabled: {
			type: Boolean,
			default: true,
			required: false,
		},
		groupField: {
			type: String,
			default: null,
			required: false,
		},
		orderEnabled: {
			type: Boolean,
			default: true,
			required: false,
		},
		scrollable: {
			type: Boolean,
			default: false,
			required: false,
		},
		defaultsParams: {
			type: Object,
			default: null,
			required: false,
		},
		exports: {
			type: Object,
			default: null,
			required: false,
		},
		editAllInline: {
			type: String,
			default: null,
			required: false,
		},
		idField: {
			type: String,
			default: 'id',
			required: false,
		},
		groupRow: {
			type: Array,
			default: null,
			required: false,
		},
		httpAppend: {
			type: Object,
			default: null,
			required: false,
		},
	},
	computed: {
		classObject: function() {
			return {
				table: true,
				'no-thead': !!this.hideHeader,
				border: !!this.border,
				scrollable: !!this.scrollable,
			}
		},
		theader: function() {
			return this.thead.filter(element =>
				!element.responsive && !element.hidden ? true : false
			)
		},
		theaderExport: function() {
			return this.theader.map(element => ({
				title: !!element.title ? element.title : null,
				data: !!element.data ? element.data : null,
				colspan: !!element.colspan ? element.colspan : null,
			}))
		},
		bulksActions: function() {
			return this.bulks.map(element => ({
				title: typeof element.title !== 'undefined' ? element.title : '',
				url: typeof element.url !== 'undefined' ? element.url : '',
				method: typeof element.method !== 'undefined' ? element.method : 'GET',
				destructive:
					typeof element.destructive !== 'undefined' ? !!element.destructive : false,
				type: typeof element.type !== 'undefined' ? element.type : null,
				name: typeof element.name !== 'undefined' ? element.name : null,
				options: typeof element.options !== 'undefined' ? element.options : [],
				optionsUrl: typeof element.optionsUrl !== 'undefined' ? element.optionsUrl : null,
				itemLabel: typeof element.itemLabel !== 'undefined' ? element.itemLabel : 'label',
				itemValue: typeof element.itemValue !== 'undefined' ? element.itemValue : 'value',
				multiple: typeof element.multiple !== 'undefined' ? !!element.multiple : false,
				numberMin: typeof element.numberMin !== 'undefined' ? element.numberMin : null,
				numberMax: typeof element.numberMax !== 'undefined' ? element.numberMax : null,
				numberStep: typeof element.numberStep !== 'undefined' ? element.numberStep : null,
				defaultValue:
					typeof element.defaultValue !== 'undefined' ? element.defaultValue : null,
			}))
		},
		groupRowMap: function() {
			if (!!this.groupRow && !!this.groupRow.length)
				return this.groupRow.map(element => ({
					data: typeof element.data !== 'undefined' ? element.data : null,
					colspan:
						typeof element.colspan !== 'undefined' && element.colspan > 0
							? element.colspan
							: 1,
				}))
			else return null
		},
		colspan: function() {
			let cols = this.theader.length + 1

			if (!!this.responsive && !!this.hasChild) cols + 1
			if (!!this.draggable) cols + 1
			if (!!this.selectable) cols + 1

			return cols
		},
		hasChild: function() {
			if (!this.responsive) return false

			for (var i = 0; i < this.thead.length; i++) {
				if (!!this.thead[i].responsive) return true
			}

			return false
		},
		bodyFooter: function() {
			return this.tbody.filter(element =>
				typeof element.tfoot !== 'undefined' && !!element.tfoot ? true : false
			)
		},
		showEditAll: function() {
			if (!!this.editAllInline && (!this.selectedsRow || !this.selectedsRow.length))
				return true

			return false
		},
		showSaveAll: function() {
			if (
				!!this.editAllInline &&
				!!this.selectedsRow &&
				!!this.selectedsRow.length &&
				this.selectedsRow.length > 0
			)
				return true

			return false
		},
		isBulkDisabled: function() {
			if (!this.selectedsRow || !this.selectedsRow.length) return true
			else return false
		},
	},
	methods: {
		mapThead: function(cols) {
			return cols.map(element => ({
				title: !!element.title ? element.title : '',
				data: !!element.data ? element.data : null,
				class: !!element.class ? element.class : null,
				responsive: !!this.responsive && !!element.responsive ? true : false,
				hidden: !!element.hidden ? true : false,
				type: !!element.type ? element.type : 'string',
				typeFormat: !!element.typeFormat ? element.typeFormat : '',
				orderable: typeof element.orderable == 'undefined' ? false : !!element.orderable,
				searchable: typeof element.searchable == 'undefined' ? true : !!element.searchable,
				formType: !!element.formType ? element.formType : 'text',
				formData: !!element.formData ? element.formData : '',
				formHidden: !!element.formHidden ? element.formHidden : false,
				formOptions: !!element.formOptions ? element.formOptions : [],
				formOptionsUrl: !!element.formOptionsUrl ? element.formOptionsUrl : null,
				formItemLabel: !!element.formItemLabel ? element.formItemLabel : 'label',
				formItemValue: !!element.formItemValue ? element.formItemValue : 'value',
				formRequired:
					typeof element.formRequired != 'undefined' ? !!element.formRequired : true,
				formMultiple: !!element.formMultiple ? element.formMultiple : false,
				formNumberMin: !!element.formNumberMin ? element.formNumberMin : null,
				formNumberMax: !!element.formNumberMax ? element.formNumberMax : null,
				formNumberStep: !!element.formNumberStep ? element.formNumberStep : null,
				formReadOnly: !!element.formReadOnly ? element.formReadOnly : false,
				filterType: !!element.filterEquals ? 'EQUALS' : 'CONTAINS',
				filterHidden: !!element.filterHidden ? true : false,
				filterTitle: !!element.filterTitle ? element.filterTitle : '',
				filterDefaultValue:
					typeof element.filterDefaultValue !== 'undefined'
						? element.filterDefaultValue
						: null,
				orderDefault:
					typeof element.orderDefault !== 'undefined' && !!element.orderDefault
						? true
						: false,
				orderReverseDefault:
					typeof element.orderReverseDefault !== 'undefined' &&
					!!element.orderReverseDefault
						? true
						: false,
				fieldLink: !!element.fieldLink ? element.fieldLink : null,
				colspan: 1,
			}))
		},
		mapSummary: function(summary = null) {
			if (!summary || !summary.length) return null

			return summary
				.map(element => ({
					label: typeof element.label !== 'undefined' ? element.label : null,
					value: typeof element.value !== 'undefined' ? element.value : null,
				}))
				.filter(element => (element.label == null && element.value == null ? false : true))
		},
		selectAll: function() {
			if (this.selectedsRow.indexOf(-1) != -1) this.selectedsRow = []
			else this.selectedsRow = [-1]

			this.summaryRequest()
		},
		resetFilters: function(refresh = true) {
			this.search.params = null
			this.sort.params = null
			this.selectedsRow = []

			this.setTableDefaults()

			if (!!refresh) {
				this.init(true)
				if (!this.refreshFilters) this.refreshFilters = true
			}
		},
		exportParams: function() {
			return {
				page: this.pagination.pages.current,
				length: this.pagination.length,
				cols: this.theaderExport,
				order: this.sort.params,
				filter: this.search.params,
				indexs: this.selectedsRow,
				origin: !!location.pathname.substr(1) ? location.pathname.substr(1) : '/',
			}
		},
		httpParams: function() {
			return {
				page: this.pagination.pages.current,
				length: this.pagination.length,
				order: this.sort.params,
				filter: this.search.params,
				origin: !!location.pathname.substr(1) ? location.pathname.substr(1) : '/',
			}
		},
		updateLength: function(length) {
			if (length != this.pagination.length) this.pagination.length = length

			this.refreshTable(false)
		},
		submitFormRow: function(el) {
			return this.$root.requestPost(
				this,
				this.createUrl,
				this.$root.getParamsFromDiv(this.$refs['tfoot_create']),
				this.submitFormCallback
			)
		},
		submitFormCallback: function(data) {
			this.refreshTable(false)
			this.$root.resetsFromDiv(this.$refs['tfoot_create'])
		},
		setInfo: function(info = {}) {
			if (!!info.per_page) this.pagination.length = parseInt(info.per_page)
			else this.pagination.length = -1

			if (!!info.current_page) this.pagination.pages.current = parseInt(info.current_page)
			else this.pagination.pages.current = 1

			if (!!info.last_page) this.pagination.pages.last = parseInt(info.last_page)
			else this.pagination.pages.last = 1

			if (!!info.from) this.pagination.results.from = parseInt(info.from)
			else this.pagination.results.from = 0

			if (!!info.to) this.pagination.results.to = parseInt(info.to)
			else this.pagination.results.to = 0

			if (!!info.total) this.pagination.results.total = parseInt(info.total)
			else this.pagination.results.total = 0
		},
		processResponse: function(response) {
			if (response instanceof Object == false && response instanceof Array == false) {
				this.http.loader = false
				return false
			}

			if (typeof response.thead !== 'undefined' && response.data instanceof Array) {
				this.thead = this.mapThead(response.thead)
				this.resetFilters(false)
			}

			if (typeof response.data !== 'undefined') {
				this.tbody =
					response.data instanceof Object ? Object.values(response.data) : response.data
				if (!!this.pagination.enabled) this.setInfo(response)
				else this.setInfo()
			} else {
				this.tbody = response instanceof Object ? Object.values(response) : response
				this.pagination.enabled = false
				this.setInfo()
			}

			this.http.loader = false
		},
		summaryRequest: function() {
			if (!this.http.summary) return false

			return this.$root.request(
				this,
				this.http.summary,
				'GET',
				this.exportParams(),
				response => {
					if (
						response instanceof Object &&
						typeof response.data !== 'undefined' &&
						response.data instanceof Array
					) {
						this.summary = this.mapSummary(response.data)
					} else {
						this.summary = this.mapSummary()
					}
				}
			)
		},
		setOrdering: function() {
			if (!this.orderEnabled || !!this.groupField) this.sort.enabled = false

			if (!!this.groupField) this.sortDataColumn(this.groupField)
		},
		setFiltersDefault: function(element) {
			if (element.filterDefaultValue === false || element.filterDefaultValue === null)
				return false

			let name = !!element.formData ? element.formData : !!element.data ? element.data : null

			if (!name) return false

			this.updateFilter(element.filterType, name, element.filterDefaultValue)
		},
		setOrderDefault: function(element) {
			if (!element.orderDefault && !element.orderReverseDefault) return false

			let name = !!element.formData ? element.formData : !!element.data ? element.data : null

			if (!name) return false

			let reverse = !!element.orderReverseDefault ? true : false

			this.sortDataColumn(name, reverse)
		},
		issetDefault: function(key) {
			if (!this.defaultsParams) return false
			if (!Object.keys(this.defaultsParams).length) return false
			if (typeof this.defaultsParams[key] === 'undefined') return false

			switch (key.toUpperCase) {
				case 'FILTER':
					if (this.defaultsParams[key] instanceof Array === false) return false
					if (!this.defaultsParams[key].length) return false
					break
				case 'ORDER':
					if (this.defaultsParams[key] instanceof Object === false) return false
					if (!Object.keys(this.defaultsParams[key]).length) return false
					if (!this.defaultsParams[key].field || !this.defaultsParams[key].sort)
						return false
					break
				case 'PAGE':
				case 'LENGTH':
					if (
						this.defaultsParams[key] instanceof Integer === false &&
						this.defaultsParams[key] instanceof String === false
					)
						return false
					break
				default:
					break
			}

			return true
		},
		setTableDefaults: function() {
			this.setOrdering()

			if (!this.pagingEnabled) this.pagination.enabled = false

			for (let index = 0; index < this.thead.length; index++) {
				this.setOrderDefault(this.thead[index])
				this.setFiltersDefault(this.thead[index])
			}

			if (!!this.pagination.enabled) this.pagination.length = this.length
			else this.pagination.length = -1
		},
		setDefaults: function() {
			if (!!this.cols && !!this.cols.length) this.thead = this.mapThead(this.cols)

			this.setTableDefaults()

			if (!!this.pagination.enabled && !!this.issetDefault('page'))
				this.pagination.pages.current = this.defaultsParams.page

			if (!!this.pagination.enabled && !!this.issetDefault('length'))
				this.pagination.length = this.defaultsParams.length

			if (!!this.issetDefault('order')) this.sort.params = this.defaultsParams.order

			if (!!this.issetDefault('filter')) {
				let filters = []

				for (let index = 0; index < this.defaultsParams.filter.length; index++) {
					const element = this.defaultsParams.filter[index]

					if (
						typeof element.field === 'undefined' ||
						typeof element.field_original === 'undefined' ||
						typeof element.value === 'undefined' ||
						typeof element.operator === 'undefined' ||
						!element.field ||
						!element.field_original ||
						element.value === '' ||
						element.value == null ||
						!element.operator
					)
						continue

					filters.push(element)
				}

				if (!!filters.length) this.search.params = filters
			}
		},
		setHttp: function() {
			this.http.url = this.url

			if (!!this.summaryUrl) this.http.summary = this.summaryUrl
		},
		makeRequest: function() {
			return this.$root.request(
				this,
				this.http.url,
				'GET',
				this.httpParams(),
				this.processResponse
			)
		},
		toggleSort: function(sort = false) {
			if (!sort) return 'ASC'

			return sort.toUpperCase() == 'ASC' ? 'DESC' : 'ASC'
		},
		sortDataColumn: function(data, reverse = null) {
			if (!!this.sort.params && this.sort.params.field == data) {
				let sort =
					reverse === true
						? 'DESC'
						: reverse === false
						? 'ASC'
						: this.toggleSort(this.sort.params.sort)

				this.sort.params.sort = sort
			} else {
				let sort = reverse === true ? 'DESC' : 'ASC'

				this.sort.params = {
					field: data,
					sort: sort,
				}
			}
		},
		sortColumn: function(data) {
			this.sortDataColumn(data)

			this.init(true)
		},
		changePage: function(page) {
			this.pagination.pages.current = page
			this.init()
		},
		reset: function() {
			return this.init(true)
		},
		init: function(reset = false) {
			if (!this.http.loader) this.http.loader = true

			if (!!reset && this.pagination.pages.current != 1) this.pagination.pages.current = 1

			this.selectedsRow = []

			this.makeRequest()
			this.summaryRequest()
		},
		refreshTable: function(related = false) {
			this.init()

			if (!!this.refTable2 && !related) this.$root.tableRefresh(this.refTable2, true)
		},
		updateFilter: function(type, field, value) {
			if (!this.search.params) this.search.params = []

			let fieldE = field.replace(/\[(.+?)\]/g, '')

			let index = this.$root.issetValueInArray(this.search.params, 'field_original', field)

			if (index !== false && (value === '' || value == null)) {
				this.search.params.splice(index, 1)
			} else if (value !== '' && value != null) {
				if (index !== false) {
					this.search.params[index] = {
						field_original: field,
						field: fieldE,
						operator: type,
						value: value,
					}
				} else {
					this.search.params.push({
						field_original: field,
						field: fieldE,
						operator: type,
						value: value,
					})
				}
			}
		},
		filter: function() {
			this.selectedsRow = []
			this.init(true)
		},
		updateSelectedsRow: function(value) {
			this.selectedsRow = value

			this.summaryRequest()
		},
		move: function(direction, item, index) {
			let tbody = this.tbody
			if (!tbody[index]) return false

			if (direction.toUpperCase() == 'UP') {
				tbody[index][this.draggable] = tbody[index][this.draggable] - 1

				if (!!tbody[index - 1]) {
					tbody[index - 1][this.draggable] = tbody[index][this.draggable] + 1
				}
			} else if (direction.toUpperCase() == 'DOWN') {
				tbody[index][this.draggable] = tbody[index][this.draggable] + 1

				if (!!tbody[index + 1]) {
					tbody[index + 1][this.draggable] = tbody[index][this.draggable] - 1
				}
			}

			// HTTP REQUEST

			this.tbody = tbody
			//console.log(this.tbody)
		},
		returnCustomRender: function(indexCol) {
			for (var i = 0; i < this.defs.length; i++) {
				let element = this.defs[i]

				if (
					typeof element.index !== 'undefined' &&
					element.index === indexCol &&
					typeof element.render !== 'undefined'
				)
					return element.render
			}

			return null
		},
		startEditAll: function() {
			if (!this.editAll) this.editAll = true
		},
		startSaveAll: function() {
			let _that = this

			let datas = {}

			if (!!this.$refs['tbody'] && !!this.$refs['tbody'].$refs) {
				for (let index = 0; index < this.selectedsRow.length; index++) {
					if (!this.$refs['tbody'].$refs['tbody_tr_' + this.selectedsRow[index]][0])
						continue

					let item = this.$refs['tbody'].getItemFromIndex(index)

					if (!item || !item[this.idField]) continue

					datas[item[this.idField]] = this.$root.getParamsFromDiv(
						this.$refs['tbody'].$refs['tbody_tr_' + this.selectedsRow[index]][0]
					)
				}
			}

			return this.$root.requestPatch(this, this.editAllInline, datas, function(data) {
				if (!!_that.editAll) _that.editAll = false
				_that.selectedsRow = []
				_that.refreshTable()
			})
		},
		setHeight: function() {
			if (!this.$refs['datatable']) return false

			setTimeout(() => {
				let height = this.$refs['datatable'].clientHeight

				if (this.minHeight == height) return false

				this.minHeight = height
			}, 1000)
		},
		showLoader: function() {
			if (!this.http.loader) this.http.loader = true
		},
		hideLoader: function() {
			if (!!this.http.loader) this.http.loader = false
		},
	},
	created: function() {
		this.setDefaults()
		this.setHttp()

		this.init(true)
	},
}
</script>
