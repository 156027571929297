<template>
	<header :class="classObject">
		<div class="container-fluid">
			<slot></slot>
		</div>
	</header>
</template>

<script>
export default {
	computed: {
		classObject: function() {
			return {
				header: true,
				'aside-collapsed': !!this.$root.asideCompressed,
			}
		},
	},
}
</script>
