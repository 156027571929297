<template>
	<main :class="classObject">
		<slot></slot>
	</main>
</template>

<script>
export default {
	computed: {
		classObject: function() {
			return {
				main: true,
				'aside-collapsed': !!this.$root.asideCompressed,
				'sidebar-open': !this.$root.sidebarHidden,
			}
		},
	},
}
</script>
