<template>
	<div :id="id" class="block-sidebar">
		<div class="block-sidebar-head" v-if="!!title || !!$slots.icon">
			<div class="block-sidebar-icon" v-if="!!$slots.icon">
				<slot name="icon"></slot>
			</div>
			<div class="block-sidebar-title headline-font" v-if="!!title">{{ title }}</div>
		</div>

		<div class="block-sidebar-content" v-if="!!$slots.default">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: '',
			required: false,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
	},
}
</script>
