<template>
	<hero
		title="403"
		subtitle="Accesso Negato"
		text="Scusaci! Non sei autorizzato ad accedere a questa pagina."
	></hero>
</template>

<script>
export default {}
</script>
