<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Tipologia Spesa">
		<form :id="refForm" :ref="refForm">
			<form-input
				type="number"
				min="1"
				step="1"
				label="Progressivo"
				id="progressivo"
				name="progressivo"
				:value="getValue('progressivo')"
			></form-input>

			<form-textarea
				label="Descrizione"
				id="description"
				name="description"
				:value="getValue('description')"
				required
			></form-textarea>

			<form-input
				type="number"
				min="0"
				step="1"
				label="Competenze Inquilino"
				id="competenze"
				name="competenze"
				:value="getValue('competenze')"
				append-text="%"
			></form-input>
			<form-segment
				id="use_gas_readings"
				name="use_gas_readings"
				label="Usa Letture Gas"
				:options="[
					{ value: 0, label: 'No' },
					{ value: 1, label: 'Si' },
				]"
				:value="!!getValue('use_gas_readings') ? 1 : 0"
			></form-segment>
		</form>

		<template v-slot:footer>
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'expense',
		refForm: '',
		urlupdate: '',
		id: '',
		datas: {},
	}),
	props: {
		urlstore: {
			type: String,
			default: '',
			required: true,
		},
		reftable: {
			type: String,
			default: '',
			required: false,
		},
	},
	created: function () {
		this.refForm = this.ref + 'DataForm'
	},
	methods: {
		close: function () {
			this.assignData({})
			this.urlupdate = ''

			this.$refs[this.ref].close()
		},
		open: function (data) {
			if (!!data && typeof data === 'object') {
				if (!!data.urlupdate) this.urlupdate = data.urlupdate
				if (!!data.datas) this.assignData(data.datas)
			} else if (!!data && Array.isArray(data)) {
				this.assignData(data)
			} else {
				this.assignData({})
			}

			this.$refs[this.ref].open()
		},
		getValue: function (key, defaultValue) {
			if (key === '') return this.datas
			else if (!!this.datas[key]) return this.datas[key]
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		getAction: function () {
			return !!this.id ? this.urlupdate : this.urlstore
		},
		getMethod: function () {
			return !!this.id ? 'PATCH' : 'POST'
		},
		assignData: function (datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
				if (!!datas.id) this.id = datas.id
			} else {
				this.datas = {}
				this.id = ''
			}
		},
		submitForm: function () {
			return this.$root.request(
				this,
				this.getAction(),
				this.getMethod(),
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function (data) {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function () {
				self.close()
			})
		},
	},
}
</script>
