<template>
	<td v-if="!col.formHidden" class="form-td" :colspan="col.colspan">
		<form-input
			v-if="!!col.formReadOnly"
			:showFormLink="false"
			:value="display"
			readonly
		></form-input>
		<form-select
			v-else-if="col.formType.toLowerCase() == 'select'"
			:name="name"
			:options="col.formOptions"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:multiple="col.formMultiple"
			:required="col.formRequired"
			:value="value"
			:values="values"
			:readonly="!!col.formReadOnly"
		></form-select>
		<form-autocomplete
			v-else-if="col.formType.toLowerCase() == 'autocomplete'"
			:name="name"
			:options="col.formOptions"
			:url="col.formOptionsUrl"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:multiple="col.formMultiple"
			:required="col.formRequired"
			:value="value"
			:values="values"
			:placeholder="col.title"
			:readonly="!!col.formReadOnly"
		></form-autocomplete>
		<form-segment
			v-else-if="col.formType.toLowerCase() == 'segment'"
			:name="name"
			:options="col.formOptions"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:required="col.formRequired"
			:value="value"
			:readonly="!!col.formReadOnly"
		></form-segment>
		<form-checkbox
			v-else-if="col.formType.toLowerCase() == 'checkbox'"
			:name="name"
			:options="col.formOptions"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:required="col.formRequired"
			:value="value"
			:values="values"
			:readonly="!!col.formReadOnly"
		></form-checkbox>
		<form-radio
			v-else-if="col.formType.toLowerCase() == 'radio'"
			:name="name"
			:options="col.formOptions"
			:item-label="col.formItemLabel"
			:item-value="col.formItemValue"
			:required="col.formRequired"
			:value="value"
			:readonly="!!col.formReadOnly"
		></form-radio>
		<form-file
			v-else-if="col.formType.toLowerCase() == 'file'"
			:name="name"
			:multiple="col.formMultiple"
			:required="col.formRequired"
			:value="value"
			:values="values"
			:readonly="!!col.formReadOnly"
		></form-file>
		<form-textarea
			v-else-if="col.formType.toLowerCase() == 'textarea'"
			:name="name"
			:placeholder="col.title"
			:required="col.formRequired"
			:value="value"
			:readonly="!!col.formReadOnly"
		></form-textarea>
		<form-input
			v-else
			:type="col.formType"
			:name="name"
			:placeholder="col.title"
			:showFormLink="false"
			:required="col.formRequired"
			:value="value"
			:min="col.formNumberMin"
			:max="col.formNumberMax"
			:step="col.formNumberStep"
			:readonly="!!col.formReadOnly"
		></form-input>
	</td>
	<td v-else></td>
</template>

<script>
export default {
	props: {
		col: {
			type: Object,
			default: null,
			required: true,
		},
		colIndex: {
			type: Number,
			default: 0,
			required: true,
		},
		defaultValue: {
			type: [String, Number, Array],
			default: null,
			required: false,
		},
		displayValue: {
			type: [String, Number],
			default: null,
			required: false,
		},
	},
	computed: {
		name: function() {
			return !!this.col.formData
				? this.col.formData
				: !!this.col.data
				? this.col.data
				: 'formitem_' + this.colIndex
		},
		value: function() {
			return typeof this.defaultValue === 'string' || typeof this.defaultValue === 'number'
				? this.defaultValue
				: null
		},
		values: function() {
			return typeof this.defaultValue === 'array' ? this.defaultValue : null
		},
		display: function() {
			return !!this.displayValue ? this.displayValue : this.value
		},
	},
}
</script>
