<template>
	<div :id="id" class="block-aside" v-if="!!$slots.default">
		<div class="block-aside-content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: '',
			required: false,
		},
	},
}
</script>
