<template>
	<li :class="navSectionClassObject">
		<span class="nav-separator"></span>
		<div class="nav-section-title headline-font" v-if="!!showTitle">{{ title }}</div>
	</li>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
			required: false,
		},
	},
	computed: {
		navSectionClassObject: function() {
			return {
				'nav-section': true,
				full: !!this.$root.asideCompressed && !this.$root.asideHover,
			}
		},
		showTitle: function() {
			return !!this.title && (!this.$root.asideCompressed || !!this.$root.asideHover)
		},
	},
}
</script>
