<template>
	<block-sidebar title="Azioni" v-if="!!bulks && !!bulks.length">
		<template v-slot:icon>
			<font-awesome-icon :icon="['fad', 'check']"></font-awesome-icon>
		</template>

		<datatable-bulk-item
			v-for="(value, index) in bulks"
			:key="index"
			:props="value"
			:ref-table="refTable"
		></datatable-bulk-item>
	</block-sidebar>
</template>

<script>
export default {
	data: () => ({
		bulks: [],
	}),
	props: {
		refTable: {
			type: String,
			default: '',
			required: true,
		},
	},
	mounted: function() {
		if (typeof this.getTable !== 'undefined') this.bulks = this.getTable.bulksActions
	},
	computed: {
		getTable: function() {
			return this.$root.$refs[this.refTable]
		},
	},
}
</script>
