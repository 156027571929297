<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Associa Anagrafica">
		<form :id="refForm" :ref="refForm">
			<form-select
				id="registry_type"
				label="Tipo"
				:options="[{ id: '0', label: 'Inquilino' }, { id: '1', label: 'Autorizzato' }]"
				item-value="id"
				item-label="label"
				required
				v-if="!!showType"
			></form-select>
			<form-input type="hidden" id="type" name="type" value="1" required v-else></form-input>

			<form-autocomplete
				id="registry_id"
				name="registry_id"
				label="Associa Inquilino"
				:url="anagrafiche"
				required
			></form-autocomplete>
		</form>

		<template v-slot:footer>
			<btn type="success" :icon="['fad', 'check']" title="Associa" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'tenant',
		refForm: '',
		urlupdate: '',
		id: '',
		datas: {},
	}),
	props: {
		urlassociateT: {
			type: String,
			default: '',
			required: true,
		},
		urlassociateA: {
			type: String,
			default: '',
			required: true,
		},
		reftable: {
			type: String,
			default: '',
			required: false,
		},
		anagrafiche: {
			type: String,
			default: '',
			required: true,
		},
		showType: {
			type: Boolean,
			default: true,
			required: false,
		},
	},
	created: function() {
		this.refForm = this.ref + 'DataForm'
	},
	computed: {
		action: function() {
			let type = document.getElementById('registry_type').value
			return !!parseInt(type) ? this.urlassociateA : this.urlassociateT
		},
	},
	methods: {
		close: function() {
			this.assignData()

			this.$refs[this.ref].close()
		},
		open: function() {
			this.assignData()

			this.$refs[this.ref].open()
		},
		assignData: function() {
			this.datas = {}
			this.id = ''
		},
		submitForm: function() {
			return this.$root.request(
				this,
				this.action,
				'POST',
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function(data) {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function() {
				self.close()
			})
		},
	},
}
</script>
