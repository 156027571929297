<template>
	<div class="dropdown-menu dropdown-menu-right" :class="{ small: !!small }">
		<dropdown-child v-if="!!header" :item="header"></dropdown-child>

		<dropdown-child v-for="(item, index) in items" :item="item" :key="index"></dropdown-child>

		<dropdown-child v-if="!!footer" :item="{ separator: true }"></dropdown-child>
		<dropdown-child v-if="!!footer" :item="footer"></dropdown-child>
	</div>
</template>

<script>
export default {
	props: {
		header: {
			type: Object,
			default: null,
			required: false,
		},
		items: {
			type: Array,
			default: () => [],
			required: true,
		},
		footer: {
			type: Object,
			default: null,
			required: false,
		},
		small: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	methods: {
		closeDropdown: function() {
			if (!!this.$parent.closeDropdown) this.$parent.closeDropdown()
		},
	},
}
</script>
