<template>
	<li class="page-item" :class="{ disabled: !!disabled, [classType]: !!classType }">
		<span class="page-link" :aria-label="label" v-if="!!disabled">
			<font-awesome-icon :icon="icon" v-if="!!icon"></font-awesome-icon>
		</span>
		<button class="page-link" :title="label" :aria-label="label" @click="action" v-else>
			<font-awesome-icon v-if="!!icon" :icon="icon"></font-awesome-icon>
		</button>
	</li>
</template>

<script>
export default {
	props: {
		icon: {
			type: Array,
			default: null,
			required: true,
		},
		label: {
			type: String,
			default: null,
			required: false,
		},
		classType: {
			type: String,
			default: '',
			required: false,
		},
		action: {
			type: Function,
			default: null,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
}
</script>
