<template>
	<block-sidebar title="Filtri Avanzati">
		<template v-slot:icon>
			<font-awesome-icon :icon="['fad', 'filter']"></font-awesome-icon>
		</template>

		<div class="btn-group btn-block">
			<btn type="success" block :action="submitFilter" title="Applica"></btn>
			<btn type="primary" block light :action="resetFilter" title="Reset"></btn>
		</div>

		<datatable-filter-single
			v-for="(value, colIndex) in thead"
			:ref="'filter_' + colIndex"
			:key="colIndex + keybase"
			:ref-table="refTable"
			:col="value"
			:params="params"
			:keybase-change="!!keybase"
		></datatable-filter-single>

		<div class="btn-group btn-block">
			<btn type="success" block :action="submitFilter" title="Applica"></btn>
			<btn type="primary" block light :action="resetFilter" title="Reset"></btn>
		</div>
	</block-sidebar>
</template>

<script>
export default {
	data: () => ({
		thead: null,
		params: [],
		keybase: 0,
	}),
	props: {
		refTable: {
			type: String,
			default: '',
			required: true,
		},
	},
	mounted: function() {
		let _that = this
		let setTheadInterval = null
		let startSecond = false

		if (
			typeof this.getTable !== 'undefined' &&
			!!_that.getTable.thead &&
			!!_that.getTable.thead.length
		) {
			this.params = this.getTable.search.params
			this.thead = this.getTable.thead

			startSecond = true
		} else {
			setTheadInterval = setInterval(function() {
				if (
					typeof _that.getTable !== 'undefined' &&
					!!_that.getTable.thead &&
					!!_that.getTable.thead.length
				) {
					_that.params = _that.getTable.search.params
					_that.thead = _that.getTable.thead

					clearInterval(setTheadInterval)
					startSecond = true
				}
			}, 100)
		}

		setInterval(function() {
			if (
				!!startSecond &&
				typeof _that.getTable !== 'undefined' &&
				!!_that.getTable.refreshFilters
			) {
				_that.getTable.refreshFilters = false

				_that.params = _that.getTable.search.params
				_that.thead = _that.getTable.thead

				_that.keybase++
			}
		}, 100)
	},
	computed: {
		getTable: function() {
			return this.$root.$refs[this.refTable]
		},
	},
	methods: {
		getFilters: function() {
			return Object.keys(this.$refs).filter(element =>
				element.toUpperCase().indexOf('FILTER_') !== -1 ? true : false
			)
		},
		submitFilter: function() {
			this.$root.filterTable(this.refTable)
		},
		resetFilter: function() {
			let filters = this.getFilters()

			for (let index = 0; index < filters.length; index++) {
				if (typeof this.$refs[filters[index]] === 'undefined') continue

				if (
					typeof this.$refs[filters[index]][0] !== 'undefined' &&
					this.$refs[filters[index]][0].reset instanceof Function
				)
					this.$refs[filters[index]][0].reset()
				else if (this.$refs[filters[index]].reset instanceof Function)
					this.$refs[filters[index]].reset()
			}

			this.$root.resetFilterTable(this.refTable)
		},
	},
}
</script>
