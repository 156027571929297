<template>
	<nav-section :title="item.title" v-if="!!item.section"></nav-section>
	<nav-section v-else-if="!!item.separator"></nav-section>
	<nav-disabled :icon="item.icon" :title="item.title" v-else-if="!!item.disabled"></nav-disabled>
	<nav-link
		:active="!!item.current_route"
		:icon="item.icon"
		:title="item.title"
		:link="item.link"
		v-else-if="!!item.link"
	></nav-link>
	<nav-child :icon="item.icon" :title="item.title" v-else>
		<slot></slot>
	</nav-child>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			default: null,
			required: true,
		},
	},
}
</script>
