<template>
	<div :class="{ spinner: !small, 'spinner-small': !!small, 'spinner-dark': !!dark }">
		<span v-if="!small"></span>
	</div>
</template>

<script>
export default {
	props: {
		small: {
			type: Boolean,
			default: false,
			required: false,
		},
		dark: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
}
</script>
