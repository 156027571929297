<template>
	<div class="form-group">
		<file-modal ref="file_modal" :afterupload="afterUpload" :multiple="multiple"></file-modal>

		<label>{{ label }} <span class="form-required" v-if="!!required">*</span></label>
		<div
			:id="getId"
			class="custom-file-upload"
			:class="{
				'is-invalid': !!error,
				readonly: !!readonly,
				disabled: !!disabled,
				multiple: !!multiple,
			}"
			v-if="
				(!multiple && !!selectedItems) ||
					(!!Array.isArray(selectedItems) && !!selectedItems.length)
			"
		>
			<div v-if="!!multiple">
				<span v-for="(element, index) in selectedItems" class="custom-file-element">
					<a :href="element.url">{{ element.filename }}</a>
					<button
						class="custom-file-element-remove"
						title="Rimuovi"
						@click="() => removeFile(index)"
						v-if="!!canRemove && !disabled && !readonly"
					>
						<font-awesome-icon :icon="['fad', 'times']"></font-awesome-icon>
					</button>
				</span>
			</div>
			<span class="custom-file-element" v-else>
				<a :href="selectedItems.url">{{ selectedItems.filename }}</a>
				<btn
					type="danger"
					light
					:icon="['fad', 'times']"
					label="Rimuovi"
					:action="() => removeFile(false)"
					v-if="!!canRemove && !disabled && !readonly"
				></btn>
			</span>
		</div>
		<div
			:id="getId"
			class="custom-file-upload"
			:class="{
				'is-invalid': !!error,
				readonly: !!readonly,
				disabled: !!disabled,
			}"
			v-if="
				!!multiple ||
					!selectedItems ||
					(!!Array.isArray(selectedItems) && !selectedItems.length)
			"
		>
			<span class="text-muted">Selezionare...</span>
			<btn
				light
				:icon="['fad', 'file-search']"
				label="Scegli"
				:action="openModal"
				v-if="!!canUpload && !disabled && !readonly"
			></btn>
		</div>
		<small
			v-if="!!small"
			:id="getId + 'Help'"
			class="form-text text-muted"
			v-html="small"
		></small>
		<span class="invalid-feedback" role="alert" v-if="!!error">
			<strong>{{ error }}</strong>
		</span>
		<input
			type="hidden"
			:id="getId"
			:name="name"
			:required="required"
			:disabled="disabled"
			:readonly="readonly"
			v-if="!multiple"
			v-model="internalValue"
		/>
		<select
			:id="getId"
			:name="name + '[]'"
			:required="required"
			:readonly="readonly"
			multiple
			style="display:none; height: 0; width: 0"
			v-else
		>
			<option v-for="element in internalValue" :value="element" selected></option>
		</select>
	</div>
</template>

<script>
export default {
	data: () => ({
		internalValue: null,
		selectedItems: null,
	}),
	props: {
		required: {
			type: Boolean,
			default: false,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		name: {
			type: String,
			default: '',
			required: false,
		},
		id: {
			type: String,
			default: '',
			required: false,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		small: {
			type: String,
			default: '',
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		multiple: {
			type: Boolean,
			default: false,
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
			required: false,
		},
		value: {
			type: [String, Object],
			default: null,
			required: false,
		},
		values: {
			type: Array,
			default: () => [],
			required: false,
		},
		canUpload: {
			type: Boolean,
			default: true,
			required: false,
		},
		canRemove: {
			type: Boolean,
			default: true,
			required: false,
		},
	},
	computed: {
		getId: function() {
			return !!this.id ? this.id : !!this.name ? this.name : ''
		},
	},
	methods: {
		openModal: function() {
			this.$refs.file_modal.open()
		},
		afterUpload: function(data) {
			if (!!this.multiple) {
				if (!!Array.isArray(data)) {
					for (var i = 0; i < data.length; i++) {
						this.selectedItems.push(data[i])
					}
				} else this.selectedItems.push(data)
			} else {
				if (!!Array.isArray(data)) this.selectedItems = data[0]
				else this.selectedItems = data
			}

			this.updateValues()
		},
		updateValues: function() {
			if (!!this.multiple) {
				let arr = []
				for (var i = 0; i < this.selectedItems.length; i++) {
					arr.push(this.selectedItems[i].id)
				}

				this.internalValue = arr
			} else {
				if (!this.selectedItems) this.internalValue = null
				else this.internalValue = this.selectedItems.id
			}
		},
		removeFile: function(index) {
			if (!!this.multiple) {
				if (!!index || index === 0) this.selectedItems.splice(index, 1)
				if (!this.selectedItems) this.selectedItems = null
			} else {
				this.selectedItems = null
			}

			this.updateValues()
		},
		reset: function() {
			this.selectedItems = null
			this.updateValues()
		},
	},
	created: function() {
		if (!!this.multiple) {
			if (!!this.values && this.values.length > 0) {
				this.selectedItems = this.values
			} else this.selectedItems = []
		} else {
			if (!this.value || this.value == null) this.selectedItems = null
			else if (typeof this.value == 'string') this.selectedItems = null
			else if (typeof this.value == 'object' && !!Object.keys(this.value).length)
				this.selectedItems = this.value
			else this.selectedItems = null
		}

		this.updateValues()
	},
}
</script>
