<template>
	<div class="form-group">
		<label :for="getId" v-if="!!label">{{ label }} <span class="form-required" v-if="!!required">*</span></label>
		<div class="form-wrap">
			<span
				class="form-control-placeholder-icon icon-right"
				@click="triggerSelect"
				v-if="!multiple"
				style="cursor: pointer"
			>
				<font-awesome-icon :icon="['fad', 'angle-down']"></font-awesome-icon>
			</span>
			<select
				ref="formSelect"
				class="custom-select has-placeholder-icon-right"
				:class="{ 'is-invalid': !!error }"
				:name="name"
				:id="getId"
				:required="required"
				:disabled="disabled"
				:autofocus="autofocus"
				:multiple="multiple"
				v-model="internalValue"
				@change="emitEvent"
			>
				<option value :disabled="!!required">- Seleziona -</option>
				<option v-for="option in options" :value="getValue(option)">
					{{ getLabel(option) }}
				</option>
			</select>
			<form-link-icon
				:value="internalValue"
				:customFormLink="customFormLink"
				:customFormText="customFormText"
				:customFormIcon="customFormIcon"
				v-if="!!showFormLink"
			></form-link-icon>
		</div>
		<span class="invalid-feedback" role="alert" v-if="!!error">
			<strong>{{ error }}</strong>
		</span>
	</div>
</template>

<script>
export default {
	data: () => ({
		internalValue: null,
	}),
	props: {
		required: {
			type: Boolean,
			default: false,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		name: {
			type: String,
			default: '',
			required: false,
		},
		id: {
			type: String,
			default: '',
			required: false,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		itemLabel: {
			type: String,
			default: 'label',
			required: false,
		},
		itemValue: {
			type: String,
			default: 'value',
			required: false,
		},
		options: {
			default: () => [],
			type: Array,
			required: true,
		},
		value: {
			type: [String, Number],
			default: '',
			required: false,
		},
		values: {
			type: Array,
			default: () => [],
			required: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		multiple: {
			type: Boolean,
			default: false,
			required: false,
		},
		showFormLink: {
			type: Boolean,
			default: true,
			required: false,
		},
		customFormLink: {
			type: String,
			default: null,
			required: false,
		},
		customFormText: {
			type: String,
			default: null,
			required: false,
		},
		customFormIcon: {
			type: Array,
			default: () => [],
			required: false,
		},
		tableRef: {
			type: String,
			default: null,
			required: false,
		},
		tableFilterType: {
			type: String,
			default: null,
			required: false,
		},
	},
	created: function() {
		if (!!this.multiple) {
			if (!!this.values && this.values.length > 0) this.internalValue = this.values
			else this.internalValue = []
		} else {
			if (!!this.value || this.value == 0) this.internalValue = this.value
			else this.internalValue = ''
		}
	},
	computed: {
		getId: function() {
			return !!this.id ? this.id : !!this.name ? this.name : ''
		},
	},
	methods: {
		getValue: function(item) {
			return item[this.itemValue]
		},
		getLabel: function(item) {
			return item[this.itemLabel]
		},
		emitEvent: function() {
			if (!this.tableRef) return false

			this.$root.updateFilterTable(
				this.tableRef,
				this.tableFilterType,
				this.name,
				this.internalValue
			)
		},
		triggerSelect: function() {
			if (document.createEvent) {
				var e = document.createEvent('MouseEvents')
				e.initMouseEvent('mousedown', true, true, window)
				this.$refs.formSelect.dispatchEvent(e)
			} else if (element.fireEvent) {
				this.$refs.formSelect.fireEvent('onmousedown')
			}
		},
		reset: function() {
			this.internalValue = !!this.multiple ? [] : ''
			this.emitEvent()
		},
	},
}
</script>
