<template>
	<div v-if="!!item.section" class="dropdown-header headline-font">
		<span v-html="item.title"></span>
	</div>

	<div v-else-if="!!item.separator" class="dropdown-divider"></div>

	<span v-else-if="!!item.disabled" :class="classObject">
		<div v-if="!!item.icon" class="dropdown-icon disabled">
			<font-awesome-icon :icon="item.icon"></font-awesome-icon>
		</div>
		<div v-if="!!item.title" class="dropdown-title disabled">
			{{ item.title }}
		</div>
	</span>

	<button v-else-if="!!item.action" :class="classObject" @click.prevent="action">
		<div v-if="!!item.icon" class="dropdown-icon">
			<font-awesome-icon :icon="item.icon"></font-awesome-icon>
		</div>
		<div v-if="!!item.title" class="dropdown-title">
			{{ item.title }}
		</div>
	</button>

	<form
		v-else-if="!!item.formMethod && !!item.link"
		class="dropdown-form"
		method="POST"
		:action="item.link"
	>
		<form-input type="hidden" name="_token" :value="token"></form-input>
		<form-input
			type="hidden"
			name="_method"
			:value="item.formMethod.toUpperCase()"
		></form-input>

		<button
			:class="classObject"
			type="submit"
			:id="id"
			:title="!!item.title ? item.title : null"
			:disabled="disabled"
			:formtarget="item.target || '_self'"
		>
			<!-- Loader v-if="loading" -->
			<div v-if="!!item.icon" class="dropdown-icon">
				<font-awesome-icon :icon="item.icon"></font-awesome-icon>
			</div>
			<div v-if="!!item.title" class="dropdown-title">
				{{ item.title }}
			</div>
		</button>
	</form>

	<a
		v-else-if="!!item.link && !item.formMethod"
		:class="classObject"
		:href="item.link"
		:target="item.target || '_self'"
		@click.passive="closeDropdown"
	>
		<div v-if="!!item.icon" class="dropdown-icon">
			<font-awesome-icon :icon="item.icon"></font-awesome-icon>
		</div>
		<div v-if="!!item.title" class="dropdown-title">
			{{ item.title }}
		</div>
	</a>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			default: null,
			required: true,
		},
	},
	computed: {
		classObject: function () {
			return {
				'dropdown-item': true,
				destructive: !!this.item.destructive,
				disabled: !!this.item.disabled,
			}
		},
		token: function () {
			if (!!this.delform || !!this.postform) {
				let token = document.head.querySelector('meta[name="csrf-token"]')
				return !!token ? token.content : null
			}
		},
	},
	methods: {
		closeDropdown: function () {
			if (!!this.$parent.closeDropdown) this.$parent.closeDropdown()
		},
		action: function () {
			if (!this.item.action) return

			this.closeDropdown()

			if (typeof this.item.action == 'function') return this.item.action.apply(this)
			else if (typeof this.$root[this.item.action] !== 'undefined')
				return this.$root[this.item.action].apply(this)
			else return false
		},
	},
}
</script>
