<template>
	<modal :id="ref" :ref="ref" :closeProp="close" :title="!!bid ? 'Rilancio' : 'Offerta'" large>
		<form :id="refForm" :ref="refForm">
			<form-input type="hidden" name="rilancio" value="1" required v-if="!!bid"></form-input>

			<div class="row" v-if="!!realEstates">
				<div class="col-md-6" v-if="!!realEstates">
					<form-autocomplete
						id="contest_real_estate_id"
						name="contest_real_estate_id"
						label="Unità Immobiliare"
						:url="realEstates"
						:value="getValue('contest_real_estate_id')"
						required
						v-if="!!editable && !!realEstates"
					></form-autocomplete>
					<form-input
						label="Unità Immobiliare"
						:value="getValue('real_estate_address')"
						readonly
						v-else-if="!editable && !!realEstates"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<form-input
						type="date"
						placeholder="YYYY-MM-DD"
						id="data"
						name="data"
						label="Data Offerta"
						:value="getValue('data_formatted')"
						required
						v-if="!!editable"
					></form-input>
					<form-input
						label="Data Offerta"
						:value="getValue('data_display')"
						readonly
						v-else
					></form-input>
				</div>
				<div class="col-md-6" v-if="!!id">
					<form-input
						id="protocollo"
						name="protocollo"
						label="Protocollo"
						:value="getValue('protocollo')"
						v-if="!!id"
						:readonly="!editable"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<form-input
						id="nome_offerente"
						name="nome_offerente"
						label="Nome Offerente"
						:value="getValue('nome_offerente')"
						required
						:readonly="!editable"
					></form-input>
				</div>
				<div class="col-md-6">
					<form-input
						id="cognome_offerente"
						name="cognome_offerente"
						label="Cognome Offerente"
						:value="getValue('cognome_offerente')"
						required
						:readonly="!editable"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<form-select
						id="sesso"
						name="sesso"
						label="Sesso"
						:options="sesso"
						:value="getValue('sesso')"
						:readonly="!editable"
					></form-select>
				</div>
				<div class="col-md-6">
					<form-textarea
						id="dati_anagrafici"
						name="dati_anagrafici"
						label="Dati Anagrafici"
						:value="getValue('dati_anagrafici')"
						:readonly="!editable"
					></form-textarea>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<form-segment
						id="vendita"
						name="vendita"
						label="Tipologia"
						:options="[
							{ value: 0, label: 'Locazione' },
							{ value: 1, label: 'Vendita' },
						]"
						:value="!!getValue('vendita') ? 1 : 0"
						required
						v-if="!!editable"
					></form-segment>
					<form-input
						label="Tipologia"
						:value="!!getValue('vendita') ? 'Vendita' : 'Locazione'"
						readonly
						v-else
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<form-input
						type="number"
						step="0.01"
						min="0"
						id="offerta"
						name="offerta"
						label="Importo"
						append-text="EUR"
						:value="getValue('offerta')"
						required
						v-if="!!editable"
					></form-input>
					<form-input
						label="Importo"
						:value="getValue('offerta_display')"
						readonly
						v-else
					></form-input>
				</div>
				<div class="col-md-6">
					<form-input
						id="offerta_string"
						name="offerta_string"
						label="Importo (Lettere)"
						append-text="EUR"
						:value="getValue('offerta_string')"
						required
						v-if="!!editable"
					></form-input>
					<form-input
						label="Importo (Lettere)"
						:value="getValue('offerta_string')"
						readonly
						v-else
					></form-input>
				</div>
			</div>

			<div class="row" v-if="!!negotiation">
				<div class="col-md-6">
					<form-segment
						id="pubblicato"
						name="pubblicato"
						label="Pubblicato"
						:options="[
							{ value: 0, label: 'No' },
							{ value: 1, label: 'Si' },
						]"
						:value="!!getValue('pubblicato') ? 1 : 0"
						required
						v-if="!!editable"
					></form-segment>
					<form-input
						label="Pubblicato"
						:value="!!getValue('pubblicato') ? 'Si' : 'No'"
						readonly
						v-else
					></form-input>
				</div>
				<div class="col-md-6">
					<form-input
						type="date"
						placeholder="YYYY-MM-DD"
						id="pubblicazione"
						name="pubblicazione"
						label="Data Pubblicazione"
						:value="getValue('pubblicazione_formatted')"
						v-if="!!editable"
					></form-input>
					<form-input
						label="Data Pubblicazione"
						:value="getValue('pubblicazione_display')"
						readonly
						v-else
					></form-input>
				</div>
			</div>

			<div class="row" v-if="!!negotiation">
				<div class="col-md-6">
					<form-input
						type="date"
						placeholder="YYYY-MM-DD"
						id="scadenza"
						name="scadenza"
						label="Data Scadenza"
						:value="getValue('scadenza_formatted')"
						v-if="!!editable"
					></form-input>
					<form-input
						label="Data Scadenza"
						:value="getValue('scadenza_display')"
						readonly
						v-else
					></form-input>
				</div>
				<div class="col-md-6" v-if="!!users">
					<form-autocomplete
						id="user_id"
						name="user_id"
						label="Evento per l'Utente"
						:url="users"
						:value="getValue('user_id')"
						v-if="!!users"
						:readonly="!editable"
					></form-autocomplete>
				</div>
			</div>

			<div class="row" v-if="!!id">
				<div class="col-md-6" v-if="!!id">
					<form-select
						id="esito"
						name="esito"
						label="Esito"
						:options="esito"
						:value="getValue('esito')"
						v-if="!!id && !!editable"
					></form-select>
					<form-input
						label="Esito"
						:value="getValue('esito_display')"
						readonly
						v-else-if="!!id && !editable"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<form-file
						label="Lettera Offerta Vendita"
						id="lettera_id"
						name="lettera_id"
						:value="getValue('lettera', {})"
						v-if="!!id"
						:readonly="!editable"
					></form-file>
				</div>
				<div class="col-md-6">
					<form-file
						label="Contratto Preliminare"
						id="contratto_preliminare_id"
						name="contratto_preliminare_id"
						:value="getValue('contratto_preliminare', {})"
						v-if="!!id"
						:readonly="!editable"
					></form-file>
				</div>
			</div>

			<div class="row" v-if="!!id">
				<div class="col-md-6" v-if="!!editable">
					<form-input
						type="date"
						placeholder="YYYY-MM-DD"
						id="data_rilancio"
						name="data_rilancio"
						label="Rilancio - Data"
						:value="getValue('data_rilancio_formatted')"
						v-if="!!id && !!editable"
					></form-input>
				</div>
				<div class="col-md-6" v-if="!!editable">
					<form-input
						type="time"
						placeholder="HH:ii"
						id="orario_rilancio"
						name="orario_rilancio"
						label="Rilancio - Orario"
						:value="getValue('orario_rilancio_formatted')"
						v-if="!!id && !!editable"
					></form-input>
				</div>
				<div class="col-md-6" v-if="!editable">
					<form-input
						label="Rilancio"
						:value="getValue('data_rilancio_display')"
						readonly
						v-if="!!id && !editable"
					></form-input>
				</div>
			</div>
		</form>

		<template v-slot:footer v-if="!!editable">
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'offer_bid',
		refForm: '',
		urlupdate: '',
		id: '',
		datas: {},
	}),
	props: {
		urlstore: {
			type: String,
			default: null,
			required: false,
		},
		reftable: {
			type: String,
			default: null,
			required: false,
		},
		realEstates: {
			type: String,
			default: null,
			required: false,
		},
		esito: {
			type: Array,
			default: null,
			required: true,
		},
		sesso: {
			type: Array,
			default: null,
			required: true,
		},
		editable: {
			type: Boolean,
			default: true,
			required: true,
		},
		bid: {
			type: Boolean,
			default: false,
			required: false,
		},
		negotiation: {
			type: Boolean,
			default: false,
			required: false,
		},
		users: {
			type: String,
			default: null,
			required: false,
		},
	},
	created: function() {
		this.refForm = this.ref + 'DataForm'
	},
	methods: {
		close: function() {
			this.assignData({})
			this.urlupdate = ''

			this.$refs[this.ref].close()
		},
		open: function(data) {
			if (!!data && typeof data === 'object') {
				if (!!data.urlupdate) this.urlupdate = data.urlupdate
				if (!!data.datas) this.assignData(data.datas)
			} else if (!!data && Array.isArray(data)) {
				this.assignData(data)
			} else {
				this.assignData({})
			}

			this.$refs[this.ref].open()
		},
		getValue: function(key, defaultValue) {
			if (this.datas[key] != null) return this.datas[key]
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		getAction: function() {
			return !!this.id ? this.urlupdate : this.urlstore
		},
		getMethod: function() {
			return !!this.id ? 'PATCH' : 'POST'
		},
		assignData: function(datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
				if (!!datas.id) this.id = datas.id
			} else {
				this.datas = {}
				this.id = ''
			}
		},
		submitForm: function() {
			return this.$root.request(
				this,
				this.getAction(),
				this.getMethod(),
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function(data) {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function() {
				self.close()
			})
		},
	},
}
</script>
