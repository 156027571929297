<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Dati Catastali" large>
		<form :id="refForm" :ref="refForm">
			<div class="row">
				<div class="col-lg-3">
					<form-input
						label="Sezione/Foglio"
						id="foglio"
						name="foglio"
						:value="getValue('foglio')"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						label="Particella"
						id="particella"
						name="particella"
						:value="getValue('particella')"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						label="Sub."
						id="sub"
						name="sub"
						:value="getValue('sub')"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-select
						id="ici_category_id"
						name="ici_category_id"
						label="Categoria Catastale"
						:value="getValue('ici_category_id')"
						:options="ici_categories"
						item-value="id"
						item-label="name"
						required
					></form-select>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="1"
						label="Z.C."
						id="zc"
						name="zc"
						:value="getValue('zc')"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="1"
						label="Classe"
						id="classe"
						name="classe"
						:value="getValue('classe')"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						label="Piano"
						id="piano"
						name="piano"
						:value="getValue('piano')"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-select
						id="floor_coefficient_id"
						name="floor_coefficient_id"
						label="Coefficiente di Piano"
						:value="getValue('floor_coefficient_id')"
						:options="floor_coefficients"
						item-value="id"
						item-label="description_coefficient"
						required
					></form-select>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-3">
					<form-input
						label="Consistenza"
						id="consistenza"
						name="consistenza"
						:value="getValue('consistenza')"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="0.01"
						label="Rendita"
						id="rendita"
						name="rendita"
						:value="getValue('rendita')"
						append-text="EUR"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="1"
						label="Anno Costruzione"
						id="anno_costruzione"
						name="anno_costruzione"
						:value="getValue('anno_costruzione')"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-select
						id="age_coefficient_id"
						name="age_coefficient_id"
						label="Coefficiente di Vetustà"
						:options="age_coefficients"
						item-value="id"
						item-label="display_value"
						:value="getValue('age_coefficient_id')"
						required
					></form-select>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-3">
					<form-select
						id="usage_id"
						name="usage_id"
						label="Uso"
						:value="getValue('usage_id')"
						:options="usages"
						item-value="id"
						item-label="value"
					></form-select>
				</div>
				<div class="col-lg-3">
					<form-select
						id="usage_type_id"
						name="usage_type_id"
						label="Tipo Uso"
						:value="getValue('usage_type_id')"
						:options="usage_types"
						item-value="id"
						item-label="value"
					></form-select>
				</div>
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="0.01"
						label="Superficie Catastale"
						id="superficie"
						name="superficie"
						:value="getValue('superficie')"
						append-text="MQ"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-xl-7">
					<table class="table border">
						<thead>
							<tr>
								<th></th>
								<th>N.</th>
								<th>MQ</th>
								<th>H < 1,70</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Vani Utili</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="vani_utili_n"
										name="vani_utili_n"
										:value="getValue('vani_utili_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="vani_utili_mq"
										name="vani_utili_mq"
										:value="getValue('vani_utili_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="vani_utili_h1_70"
										name="vani_utili_h1_70"
										:value="getValue('vani_utili_h1_70')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Cucina</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="cucina_n"
										name="cucina_n"
										:value="getValue('cucina_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="cucina_mq"
										name="cucina_mq"
										:value="getValue('cucina_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="cucina_h1_70"
										name="cucina_h1_70"
										:value="getValue('cucina_h1_70')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Cucinino</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="cucinino_n"
										name="cucinino_n"
										:value="getValue('cucinino_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="cucinino_mq"
										name="cucinino_mq"
										:value="getValue('cucinino_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="cucinino_h1_70"
										name="cucinino_h1_70"
										:value="getValue('cucinino_h1_70')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Servizi Igienici</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="servizi_igienici_n"
										name="servizi_igienici_n"
										:value="getValue('servizi_igienici_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="servizi_igienici_mq"
										name="servizi_igienici_mq"
										:value="getValue('servizi_igienici_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="servizi_igienici_h1_70"
										name="servizi_igienici_h1_70"
										:value="getValue('servizi_igienici_h1_70')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Soppalco</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="soppalco_n"
										name="soppalco_n"
										:value="getValue('soppalco_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="soppalco_mq"
										name="soppalco_mq"
										:value="getValue('soppalco_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="soppalco_h1_70"
										name="soppalco_h1_70"
										:value="getValue('soppalco_h1_70')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Distribuzione</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="distribuzione_n"
										name="distribuzione_n"
										:value="getValue('distribuzione_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="distribuzione_mq"
										name="distribuzione_mq"
										:value="getValue('distribuzione_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="distribuzione_h1_70"
										name="distribuzione_h1_70"
										:value="getValue('distribuzione_h1_70')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Ripostiglio</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="rispostiglio_n"
										name="rispostiglio_n"
										:value="getValue('rispostiglio_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="rispostiglio_mq"
										name="rispostiglio_mq"
										:value="getValue('rispostiglio_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="rispostiglio_h1_70"
										name="rispostiglio_h1_70"
										:value="getValue('rispostiglio_h1_70')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Pertinenze</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="pertinenze_n"
										name="pertinenze_n"
										:value="getValue('pertinenze_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="pertinenze_mq"
										name="pertinenze_mq"
										:value="getValue('pertinenze_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="pertinenze_h1_70"
										name="pertinenze_h1_70"
										:value="getValue('pertinenze_h1_70')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Cantina</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="cantina_n"
										name="cantina_n"
										:value="getValue('cantina_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="cantina_mq"
										name="cantina_mq"
										:value="getValue('cantina_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="cantina_h1_70"
										name="cantina_h1_70"
										:value="getValue('cantina_h1_70')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Box</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="box_n"
										name="box_n"
										:value="getValue('box_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										name="box_mq"
										:value="getValue('box_mq')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="box_h1_70"
										name="box_h1_70"
										:value="getValue('box_h1_70')"
									></form-input>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="col-xl-5">
					<table class="table border">
						<thead>
							<tr>
								<th></th>
								<th>N.</th>
								<th>MQ</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Angolo Cottura</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="angolo_cottura_n"
										name="angolo_cottura_n"
										:value="getValue('angolo_cottura_n')"
									></form-input>
								</td>
								<td></td>
							</tr>
							<tr>
								<td>Balcone</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="balcone_n"
										name="balcone_n"
										:value="getValue('balcone_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="balcone_mq"
										name="balcone_mq"
										:value="getValue('balcone_mq')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Balcone Verandato</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="balcone_verandato_n"
										name="balcone_verandato_n"
										:value="getValue('balcone_verandato_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="balcone_verandato_mq"
										name="balcone_verandato_mq"
										:value="getValue('balcone_verandato_mq')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Terrazzo</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="terrazzo_n"
										name="terrazzo_n"
										:value="getValue('terrazzo_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="terrazzo_mq"
										name="terrazzo_mq"
										:value="getValue('terrazzo_mq')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Terrazzo Verandato</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="terrazzo_verandato_n"
										name="terrazzo_verandato_n"
										:value="getValue('terrazzo_verandato_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="terrazzo_verandato_mq"
										name="terrazzo_verandato_mq"
										:value="getValue('terrazzo_verandato_mq')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>Giardini</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="giardini_n"
										name="giardini_n"
										:value="getValue('giardini_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="giardini_mq"
										name="giardini_mq"
										:value="getValue('giardini_mq')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>P. Macchina Coperto</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="p_macch_coperto_n"
										name="p_macch_coperto_n"
										:value="getValue('p_macch_coperto_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="p_macch_coperto_mq"
										name="p_macch_coperto_mq"
										:value="getValue('p_macch_coperto_mq')"
									></form-input>
								</td>
							</tr>
							<tr>
								<td>P. Macchina Scoperto</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="1"
										id="p_macch_scoperto_n"
										name="p_macch_scoperto_n"
										:value="getValue('p_macch_scoperto_n')"
									></form-input>
								</td>
								<td>
									<form-input
										type="number"
										min="0"
										step="0.01"
										id="p_macch_scoperto_mq"
										name="p_macch_scoperto_mq"
										:value="getValue('p_macch_scoperto_mq')"
									></form-input>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<br />

			<div class="row">
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="1"
						label="Superficie Lorda"
						id="superficie_lorda"
						name="superficie_lorda"
						:value="getValue('superficie_lorda')"
						append-text="MQ"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="1"
						label="Superficie Lorda Escl. Pertinenze"
						id="superficie_scoperta_pert_escl"
						name="superficie_scoperta_pert_escl"
						:value="getValue('superficie_scoperta_pert_escl')"
						append-text="MQ"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="1"
						label="Superficie Utile"
						id="superficie_utile_calp_mq"
						name="superficie_utile_calp_mq"
						:value="getValue('superficie_utile_calp_mq')"
						append-text="MQ"
						required
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="1"
						label="Superficie Utile (H<1,70)"
						id="superficie_utile_calp_h1_70"
						name="superficie_utile_calp_h1_70"
						:value="getValue('superficie_utile_calp_h1_70')"
						append-text="MQ"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-3">
					<form-input
						type="number"
						min="0"
						step="1"
						label="Superficie Scoperta Cond."
						id="superficie_scoperta_cond"
						name="superficie_scoperta_cond"
						:value="getValue('superficie_scoperta_cond')"
						append-text="MQ"
					></form-input>
				</div>
				<div class="col-lg-3">
					<form-input
						label="Millesimi Cond."
						:value="getValue('millesimi_condominiali_formatted')"
						readonly
					></form-input>
				</div>
				<div class="col-lg-6">
					<form-input
						label="Confini"
						id="confini"
						name="confini"
						:value="getValue('confini')"
					></form-input>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-4">
					<form-file
						label="Visura Catastale"
						id="visura_catastale_id"
						name="visura_catastale_id"
						:value="getValue('visura_catastale', {})"
					></form-file>
				</div>
				<div class="col-lg-4">
					<form-file
						label="Planimetria"
						id="planimetria_id"
						name="planimetria_id"
						:value="getValue('planimetria', {})"
					></form-file>
				</div>
			</div>
		</form>

		<template v-slot:footer>
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'cadastral',
		refForm: '',
		id: '',
		datas: {},
	}),
	props: {
		urlstore: {
			type: String,
			default: '',
			required: true,
		},
		urlupdate: {
			type: String,
			default: '',
			required: true,
		},
		cadastral: {
			type: Object,
			default: null,
			required: false,
		},
		ici_categories: {
			type: Array,
			default: () => [],
			required: true,
		},
		usages: {
			type: Array,
			default: () => [],
			required: true,
		},
		usage_types: {
			type: Array,
			default: () => [],
			required: true,
		},
		age_coefficients: {
			type: Array,
			default: () => [],
			required: true,
		},
		floor_coefficients: {
			type: Array,
			default: () => [],
			required: true,
		},
	},
	methods: {
		close: function () {
			this.$refs[this.ref].close()
		},
		open: function () {
			this.$refs[this.ref].open()
		},
		getValue: function (key) {
			if (!!this.datas[key]) return this.datas[key]
			else return ''
		},
		getAction: function () {
			return !!this.id ? this.urlupdate : this.urlstore
		},
		getMethod: function () {
			return !!this.id ? 'PATCH' : 'POST'
		},
		assignData: function (datas) {
			if (!!datas && !!Object.keys(datas).length) {
				this.datas = datas

				if (!!datas.id) this.id = datas.id
			}
		},
		submitForm: function () {
			return this.$root.request(
				this,
				this.getAction(),
				this.getMethod(),
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function (data) {
			this.assignData(data)

			let self = this
			Vue.nextTick().then(function () {
				self.close()
			})
		},
	},
	created: function () {
		this.refForm = this.ref + 'DataForm'
		this.assignData(this.cadastral)
	},
}
</script>
