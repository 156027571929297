<template>
	<div class="form-group">
		<label :for="getId" v-if="!!label">{{ label }} <span class="form-required" v-if="!!required">*</span></label>
		<div class="form-wrap">
			<span class="form-control-placeholder-icon" v-if="!!hasPlaceholderIcon">
				<font-awesome-icon :icon="placeholderIcon"></font-awesome-icon>
			</span>
			<datepicker
				v-if="type.toUpperCase() == 'DATE' && !readonly"
				:value="internalValue"
				v-model="internalValue"
				:required="required"
				:disabled="disabled"
				:id="getId"
				:name="name"
				:input-class="{
					'form-control': true,
					'is-invalid': !!error,
					'has-placeholder-icon': !!hasPlaceholderIcon,
				}"
				:title="label"
				:format="customDateFormat"
				:minimum-view="minimumViewInternal"
				:monday-first="true"
				:typeable="true"
				:language="it"
				:full-month-name="true"
				:placeholder="placeholder"
				:highlighted="highlighted"
				:bootstrap-styling="false"
				@input="emitEvent"
			></datepicker>
			<div class="input-group" v-else-if="!!appendText || !!prependText">
				<div class="input-group-prepend" v-if="!!prependText">
					<span class="input-group-text" :id="getId + '_label'">{{ prependText }}</span>
				</div>
				<input
					:name="name"
					:type="type"
					class="form-control"
					:class="{ 'is-invalid': !!error, 'has-placeholder-icon': !!hasPlaceholderIcon }"
					:id="getId"
					:placeholder="placeholder"
					:required="required"
					:disabled="disabled"
					:autocomplete="autocomplete"
					:autofocus="autofocus"
					:title="label"
					:pattern="pattern"
					:accept="accept"
					:min="min"
					:max="max"
					:step="step"
					:multiple="multiple"
					:readonly="readonly"
					:ariaDescribedby="getId + '_label'"
					v-model="internalValue"
					@input="emitEvent"
				/>
				<div class="input-group-append" v-if="!!appendText">
					<span class="input-group-text" :id="getId + '_label'">{{ appendText }}</span>
				</div>
			</div>
			<input
				:name="name"
				:type="type"
				class="form-control"
				:class="{ 'is-invalid': !!error, 'has-placeholder-icon': !!hasPlaceholderIcon }"
				:id="getId"
				:placeholder="!!readonly ? 'Nessuno' : placeholder"
				:required="required"
				:disabled="disabled"
				:autocomplete="autocomplete"
				:autofocus="autofocus"
				:title="label"
				:pattern="pattern"
				:accept="accept"
				:min="min"
				:max="max"
				:step="step"
				:multiple="multiple"
				:readonly="readonly"
				v-model="internalValue"
				@input="emitEvent"
				v-else
			/>
			<form-link-icon
				:type="type"
				:value="internalValue"
				:customFormLink="customFormLink"
				:customFormText="customFormText"
				:customFormIcon="customFormIcon"
				v-if="!!showFormLink"
			></form-link-icon>
		</div>
		<small v-if="!!small" :id="getId + 'Help'" class="form-text text-muted" v-html="small"></small>
		<span class="invalid-feedback" role="alert" v-if="!!error">
			<strong>{{ error }}</strong>
		</span>
	</div>
</template>

<script>
import { it } from 'vuejs-datepicker/dist/locale'

export default {
	data: () => ({
		internalValue: '',
		it: it,
		minimumViewInternal: 'day',
	}),
	props: {
		required: {
			type: Boolean,
			default: false,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		name: {
			type: [String, Number],
			default: '',
			required: false,
		},
		id: {
			type: String,
			default: '',
			required: false,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		type: {
			type: String,
			default: 'text',
			required: false,
		},
		placeholder: {
			type: String,
			default: '',
			required: false,
		},
		small: {
			type: String,
			default: '',
			required: false,
		},
		value: {
			type: [String, Number],
			default: '',
			required: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
			required: false,
		},
		autocomplete: {
			type: String,
			default: '',
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		pattern: {
			type: String,
			default: null,
			required: false,
		},
		accept: {
			type: String,
			default: '',
			required: false,
		},
		min: {
			type: String,
			default: '',
			required: false,
		},
		max: {
			type: String,
			default: '',
			required: false,
		},
		step: {
			type: String,
			default: '',
			required: false,
		},
		multiple: {
			type: Boolean,
			default: false,
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
			required: false,
		},
		dateformat: {
			type: String,
			default: 'YYYY-MM-DD',
			required: false,
		},
		tableRef: {
			type: String,
			default: null,
			required: false,
		},
		tableFilterType: {
			type: String,
			default: null,
			required: false,
		},
		showFormLink: {
			type: Boolean,
			default: true,
			required: false,
		},
		customFormLink: {
			type: String,
			default: null,
			required: false,
		},
		customFormText: {
			type: String,
			default: null,
			required: false,
		},
		customFormIcon: {
			type: Array,
			default: () => [],
			required: false,
		},
		appendText: {
			type: String,
			default: null,
			required: false,
		},
		prependText: {
			type: String,
			default: null,
			required: false,
		},
	},
	created: function() {
		this.internalValue = this.value

		if (this.dateformat.toUpperCase().indexOf('D') === -1) {
			this.minimumViewInternal = 'month'
			if (this.dateformat.toUpperCase().indexOf('M') === -1) this.minimumViewInternal = 'year'
		}
	},
	computed: {
		highlighted: function() {
			return {
				dates: [new Date()],
			}
		},
		getId: function() {
			return !!this.id ? this.id : !!this.name ? this.name : ''
		},
		hasPlaceholderIcon: function() {
			switch (this.type.toUpperCase()) {
				case 'DATE':
					return true
					break
				default:
					return false
			}
		},
		placeholderIcon: function() {
			switch (this.type.toUpperCase()) {
				case 'DATE':
					return ['fad', 'calendar']
					break
				default:
					return []
			}
		},
	},
	methods: {
		customDateFormat: function(date) {
			if (moment(date).isValid()) return moment(date).format(this.dateformat)
			return ''
		},
		emitEvent: function() {
			if (this.type.toUpperCase() == 'DATE')
				this.internalValue = this.customDateFormat(this.internalValue)

			if (!this.tableRef) return false

			this.$root.updateFilterTable(
				this.tableRef,
				this.tableFilterType,
				this.name,
				this.internalValue
			)
		},
		reset: function() {
			this.internalValue = ''
			this.emitEvent()
		},
	},
}
</script>
