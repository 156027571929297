<template>
	<li :class="navItemClassObject">
		<a :class="classObject" :href="link">
			<div :class="navIconClassObject">
				<font-awesome-icon :icon="icon" v-if="icon && icon.length"></font-awesome-icon>
			</div>
			<div class="nav-title">{{ title }}</div>
		</a>
	</li>
</template>

<script>
export default {
	props: {
		icon: {
			type: Array,
			default: null,
			required: false,
		},
		title: {
			type: String,
			default: null,
			required: true,
		},
		link: {
			type: String,
			default: '#',
			required: true,
		},
		active: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	computed: {
		navItemClassObject: function() {
			return {
				'nav-item': true,
				active: !!this.$slots.default && !!this.active,
			}
		},
		classObject: function() {
			return {
				'nav-link': true,
				active: !!this.active,
			}
		},
		navIconClassObject: function() {
			return {
				'nav-icon': true,
				full: !!this.$root.asideCompressed && !this.$root.asideHover,
			}
		},
	},
}
</script>
