<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Carica CSV">
		<form :id="refForm" :ref="refForm">
			<form-file
				label="File CSV"
				id="file_id"
				name="file_id"
				small='<b>Colonne</b> (Tutte le colonne devono essere presenti, solo "email" non deve essere mai assente): email, nome, cognome, ragione_sociale<br/><b>Separatore CSV</b>: ";"'
				required
			></form-file>

			<form-input
				label="Descrizione"
				id="description"
				name="description"
				required
			></form-input>
		</form>

		<template v-slot:footer>
			<btn
				type="success"
				:icon="!loading ? ['fad', 'check'] : null"
				:title="!!loading ? 'Caricamento...' : 'Carica'"
				:action="submitForm"
				:disabled="disabled"
			></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'upload_csv',
		refForm: '',
		disabled: false,
		loading: false,
	}),
	props: {
		urlstore: {
			type: String,
			default: '',
			required: true,
		},
		reftable: {
			type: String,
			default: '',
			required: false,
		},
	},
	methods: {
		close: function() {
			this.$refs[this.ref].close()
		},
		open: function(data) {
			this.$refs[this.ref].open()
		},
		submitForm: function() {
			this.disabled = true
			this.loading = true

			return this.$root.request(
				this,
				this.urlstore,
				'POST',
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback,
				({ response, message }) => {
					this.disabled = false
					this.loading = false

					if (
						typeof response.data !== 'undefined' &&
						!!response.data &&
						typeof response.data.error !== 'undefined' &&
						!!response.data.error
					) {
						if (response.data.error instanceof Array)
							alert(response.data.error.join('\n'))
						else if (typeof response.data.error === 'string') alert(response.data.error)
						else alert('Si è verificato un errore, si è pregati di riprovare!')
					} else alert('Si è verificato un errore, si è pregati di riprovare!')
				}
			)
		},
		submitFormCallback: function(data) {
			if (typeof data.data !== 'undefined' && !!data.data && typeof data.data === 'string')
				alert(data.data)
			else alert('Caricamento in corso...')

			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function() {
				self.disabled = false
				self.loading = false
				self.close()
			})
		},
	},
	created: function() {
		this.refForm = this.ref + 'DataForm'
	},
}
</script>
