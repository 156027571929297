<template>
	<div class="form-group">
		<div
			class="custom-autocomplete"
			:class="{
				'is-invalid': !!error,
				readonly: !!readonly,
				disabled: !!disabled,
				multiple: !!multiple,
			}"
			v-click-outside="hideDropdown"
		>
			<label :for="getId" v-if="!!label" @click="hideDropdown">{{ label }} <span class="form-required" v-if="!!required">*</span></label>
			<div class="custom-autocomplete-selected-container" v-if="!!getSelecteds">
				<span
					:key="index_sel"
					v-for="(selected, index_sel) in getSelecteds"
					class="custom-autocomplete-selected"
				>
					{{ getLabel(selected) }}
					<button
						class="custom-autocomplete-selected-remove"
						v-if="!!canEdit()"
						@click="() => remove(selected)"
					>
						<font-awesome-icon :icon="['fad', 'times']"></font-awesome-icon>
					</button>
				</span>
			</div>
			<input
				v-if="!!showInput()"
				ref="autocompleteEl"
				type="text"
				class="form-control"
				:class="{ 'is-invalid': !!error }"
				:placeholder="placeholder"
				:disabled="disabled"
				autocomplete="off"
				:autofocus="autofocus"
				:title="label"
				:readonly="readonly"
				@click="showDropdown"
				@focus="showDropdown"
				@input="inputChange"
				v-model="currentInput"
			/>
			<input
				type="hidden"
				:id="getId"
				:name="name"
				:ref="getId"
				:required="required"
				:disabled="disabled"
				:readonly="readonly"
				v-if="!multiple"
				v-model="internalValue"
				@input="emitEvent"
			/>
			<select
				:id="getId"
				:name="name + '[]'"
				:ref="getId"
				:required="required"
				:readonly="readonly"
				multiple
				style="display:none; height: 0; width: 0"
				v-model="internalValue"
				@change="emitEvent"
				v-else
			>
				<option
					v-for="(element, index_el) in internalValue"
					:key="index_el"
					:value="element"
					selected
				></option>
			</select>
			<div
				class="custom-autocomplete-dropdown"
				v-if="!!openDropdown && !!getOptions && !!getOptions.length"
			>
				<button
					:key="index_opt"
					v-for="(option, index_opt) in getOptions"
					class="custom-autocomplete-item"
					@click="() => complete(option)"
				>
					{{ getLabel(option) }}
				</button>
				<button class="custom-autocomplete-item item-text item-more" v-if="!!moreText">
					{{ moreText }}
				</button>
				<button
					type="button"
					class="custom-autocomplete-create"
					title="Crea Elemento"
					@click="create"
					v-if="!!showCreate"
				>
					<font-awesome-icon :icon="['fad', 'plus']"></font-awesome-icon>
					Crea Elemento
				</button>
			</div>
			<div
				class="custom-autocomplete-dropdown"
				v-else-if="!!openDropdown && (!getOptions || !getOptions.length)"
			>
				<span class="custom-autocomplete-item item-text">Nessun Elemento...</span>
				<button
					type="button"
					class="custom-autocomplete-create"
					title="Crea Elemento"
					@click="create"
					v-if="!!showCreate"
				>
					<font-awesome-icon :icon="['fad', 'plus']"></font-awesome-icon>
					Crea Elemento
				</button>
			</div>
		</div>

		<span class="invalid-feedback" role="alert" v-if="!!error">
			<strong>{{ error }}</strong>
		</span>
	</div>
</template>

<script>
export default {
	data: () => ({
		openDropdown: false,
		selectedItems: null,
		currentInput: '',
		internalValue: null,
		internalOptions: null,
		more: 0,
		length: 25,
		setDefaults: true,
	}),
	props: {
		required: {
			type: Boolean,
			default: false,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		name: {
			type: String,
			default: '',
			required: false,
		},
		id: {
			type: String,
			default: '',
			required: false,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		itemLabel: {
			type: String,
			default: 'label',
			required: false,
		},
		itemValue: {
			type: String,
			default: 'value',
			required: false,
		},
		options: {
			type: Array,
			default: null,
			required: false,
		},
		value: {
			type: [String, Number],
			default: '',
			required: false,
		},
		values: {
			type: Array,
			default: () => [],
			required: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		multiple: {
			type: Boolean,
			default: false,
			required: false,
		},
		placeholder: {
			type: String,
			default: 'Scrivi per ricercare...',
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
			required: false,
		},
		url: {
			type: String,
			default: null,
			required: false,
		},
		tableRef: {
			type: String,
			default: null,
			required: false,
		},
		tableFilterType: {
			type: String,
			default: null,
			required: false,
		},
		createUrl: {
			type: String,
			default: null,
			required: false,
		},
	},
	created: function() {
		if (!!this.multiple) {
			this.selectedItems = []
			this.internalValue = []
		} else {
			this.selectedItems = null
			this.internalValue = null
		}

		this.inputChange()
	},
	computed: {
		getId: function() {
			return !!this.id ? this.id : !!this.name ? this.name : ''
		},
		getSelecteds: function() {
			if (!this.selectedItems) return false

			if (!this.multiple) {
				return [this.selectedItems]
			} else {
				return this.selectedItems
			}
		},
		getOptions: function() {
			return this.internalOptions
		},
		moreText: function() {
			if (!this.more || this.more <= 0) return null

			if (this.more == 1) return '1 altro elemento'

			return 'Altri ' + this.more + ' elementi'
		},
		showCreate: function() {
			return !!this.createUrl && !!this.currentInput
		},
	},
	methods: {
		selectDefaults: function() {
			this.setDefaults = false

			let values = []

			if (!!this.value || this.value === 0) values.push(this.value)

			if (!!this.values && !!this.values.length) values = this.values.concat(values)

			if (!values.length) return false

			if (!this.multiple && values.length > 1) values = [values[0]]

			if (!!this.url) {
				let valuesNotFound = []
				let valuesFound = []

				for (var i = 0; i < values.length; i++) {
					let item = this.getItemByValue(values[i])

					if (!item) valuesNotFound.push(values[i])
					else valuesFound.push(item)
				}

				if (!!valuesFound.length) {
					if (!!this.multiple) this.selectedItems = valuesFound
					else this.selectedItems = valuesFound[0]

					this.updateValues()
				}

				if (!!valuesNotFound.length) {
					this.$root.request(
						this,
						this.url,
						'GET',
						{ v: valuesNotFound },
						this.processResponseDefaults
					)
				}
			} else {
				if (!!this.multiple)
					this.selectedItems = values.map(t => this.getItemByValueFromStatic(t))
				else this.selectedItems = this.getItemByValueFromStatic(values[0])

				this.updateValues()
			}
		},
		processResponseDefaults: function(response) {
			let datas = null

			if (response.data instanceof Array) datas = response.data
			else if (response instanceof Array) datas = response
			else datas = []

			if (!datas.length) return false

			if (!!this.multiple) this.selectedItems = datas.concat(this.selectedItems)
			else this.selectedItems = datas[0]

			this.updateValues()
		},
		inputChange: function() {
			if (!!this.url) {
				this.makeRequest()
			} else {
				this.getStaticOptions()
			}
		},
		getStaticOptions: function() {
			let arr = []

			for (var i = 0; i < this.options.length; i++) {
				if (!this.showitem(this.options[i])) continue

				arr.push(this.options[i])
			}

			this.processData(arr, true)
		},
		makeRequest: function() {
			return this.$root.request(
				this,
				this.url,
				'GET',
				{ q: this.currentInput, l: this.length },
				this.processResponse
			)
		},
		processResponse: function(response) {
			if (response.data instanceof Array) {
				if (typeof response.more !== 'undefined') {
					this.more = response.more
					this.processData(response.data, false)
				} else {
					this.processData(response.data, true)
				}
			} else {
				this.processData(response, true)
			}
		},
		processData: function(data, addMore = false) {
			if (data instanceof Array == false) return false

			if (data.length <= this.length) {
				this.internalOptions = data
			} else {
				let arr = []

				for (let index = 0; index < this.length; index++) {
					arr.push(data[index])
				}

				this.internalOptions = arr
			}

			if (!!this.setDefaults) this.selectDefaults()

			if (!!addMore) {
				let diff = data.length - this.length
				if (diff < 0) diff = 0

				this.more = diff
			}
		},
		canEdit: function() {
			if (!this.readonly && !this.disabled) return true
			else return false
		},
		getValue: function(item) {
			if (!!item) return item[this.itemValue]
			else return null
		},
		getLabel: function(item) {
			if (!!item) return item[this.itemLabel]
			else return ''
		},
		getItemByValue: function(value) {
			if (!this.internalOptions) return null

			for (var i = 0; i < this.internalOptions.length; i++) {
				if (this.getValue(this.internalOptions[i]) == value) return this.internalOptions[i]
			}

			return null
		},
		getItemByValueFromStatic: function(value) {
			if (!this.options) return null

			for (var i = 0; i < this.options.length; i++) {
				if (this.getValue(this.options[i]) == value) return this.options[i]
			}

			return null
		},
		complete: function(item) {
			if (!this.canEdit()) return false

			this.currentInput = ''

			if (!!this.multiple) {
				this.selectedItems.push(item)
			} else {
				this.selectedItems = item
			}

			this.hideDropdown()
			this.updateValues()
		},
		remove: function(item) {
			if (!this.canEdit()) return false

			this.currentInput = ''

			if (!!this.multiple) {
				let index = false
				for (var i = 0; i < this.selectedItems.length; i++) {
					if (this.getValue(this.selectedItems[i]) == this.getValue(item)) {
						index = i
						break
					}
				}

				if (!!index || index === 0) this.selectedItems.splice(index, 1)
			} else {
				this.selectedItems = null
			}

			this.updateValues()
			this.inputChange()
		},
		isValueSelected: function(value) {
			if (!!this.multiple) {
				for (var i = 0; i < this.selectedItems.length; i++) {
					if (this.getValue(this.selectedItems[i]) == value) return true
				}
			} else {
				if (this.getValue(this.selectedItems) == value) return true
			}
		},
		updateValues: function() {
			if (!!this.multiple) {
				let arr = []
				for (var i = 0; i < this.selectedItems.length; i++) {
					arr.push(this.getValue(this.selectedItems[i]))
				}

				this.internalValue = arr
			} else {
				if (!this.selectedItems) this.internalValue = null
				else this.internalValue = this.getValue(this.selectedItems)
			}

			this.emitEvent()
		},
		showDropdown: function() {
			this.openDropdown = true
		},
		hideDropdown: function() {
			this.openDropdown = false
		},
		showitem: function(item) {
			let label = this.getLabel(item).toUpperCase()
			let value = this.getValue(item)

			//if (!this.currentInput) return false

			if (!!this.isValueSelected(value)) return false

			if (label.indexOf(this.currentInput.toUpperCase()) === -1) return false

			return true
		},
		showInput: function() {
			if (!this.canEdit()) return false

			if (!!this.multiple) return true
			else {
				if (!!this.selectedItems) return false
				else return true
			}
		},
		emitEvent: function() {
			if (!this.tableRef) return false

			this.$root.updateFilterTable(
				this.tableRef,
				this.tableFilterType,
				this.name,
				this.internalValue
			)
		},
		reset: function() {
			this.selectedItems = !!this.multiple ? [] : null
			this.internalValue = !!this.multiple ? [] : null
			this.updateValues()
		},
		addElementToOptions: function(element) {
			if (element instanceof Object == false) return false

			if (this.internalOptions instanceof Array) this.internalOptions.push(element)
			else this.internalOptions = [element]
		},
		create: function() {
			if (!this.createUrl || !this.currentInput) return false

			return this.$root.request(
				this,
				this.createUrl,
				'POST',
				{ value: this.currentInput },
				this.processResponseCreate
			)
		},
		processResponseCreate: function(response) {
			if (response instanceof Object == false) return false

			let element = response

			this.addElementToOptions(element)
			this.complete(element)
		},
	},
}
</script>
