<template>
	<hero
		title="404"
		subtitle="Pagina Non Trovata"
		text="Oops! La pagina richiesta non è stata trovata.<br />L'indirizzo che digitato potrebbe non essere corretto oppure questa pagina non esiste più o forse, non è mai esistita."
	></hero>
</template>

<script>
export default {}
</script>
