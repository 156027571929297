<template>
	<datatable-form-cell
		v-if="!!editable"
		:colIndex="colIndex"
		:col="col"
		:defaultValue="formItem"
		:displayValue="item"
	></datatable-form-cell>
	<td
		v-else-if="!!custom && !!custom.name && !!custom.props"
		:class="classObject"
		:data-row="rowIndex"
		:colspan="col.colspan"
		:data-toggle="!!custom.title ? 'tooltip' : ''"
		:title="!!custom.title ? custom.title : ''"
	>
		<component v-bind:is="custom.name" v-bind="custom.props"></component>
		<span v-if="!!custom.html" v-html="custom.html"></span>
	</td>
	<td v-else-if="!!link" :class="classObject" :data-row="rowIndex" :colspan="col.colspan">
		<a :href="link" target="_blank">{{ custom }}</a>
	</td>
	<td
		v-else
		:class="classObject"
		:data-row="rowIndex"
		:colspan="col.colspan"
		v-html="custom"
	></td>
</template>

<script>
export default {
	props: {
		row: {
			type: Object,
			default: null,
			required: true,
		},
		col: {
			type: Object,
			default: null,
			required: true,
		},
		rowIndex: {
			type: Number,
			default: 0,
			required: true,
		},
		colIndex: {
			type: Number,
			default: 0,
			required: true,
		},
		customRender: {
			type: Function,
			default: null,
			required: false,
		},
		editable: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	created: function() {
		$(function() {
			$('[data-toggle="tooltip"]').tooltip()
		})
	},
	computed: {
		classObject: function() {
			return {
				[this.col.class]: !!this.col.class,
				icon:
					!!this.custom &&
					!!this.custom.name &&
					this.custom.name.toLowerCase() == 'font-awesome-icon',
			}
		},
		item: function() {
			if (!!this.col.data && typeof this.row[this.col.data] !== 'undefined')
				return this.row[this.col.data]
			return null
		},
		formItem: function() {
			if (!!this.col.formData && typeof this.row[this.col.formData] !== 'undefined')
				return this.row[this.col.formData]
			else if (!!this.col.data && typeof this.row[this.col.data] !== 'undefined')
				return this.row[this.col.data]
			return null
		},
		custom: function() {
			if (typeof this.customRender === 'function') {
				return this.customRender(this.item, this.row, {
					row: this.rowIndex,
					col: this.colIndex,
				})
			}
			return this.item
		},
		link: function() {
			if (!this.col.fieldLink) return null

			if (typeof this.row[this.col.fieldLink] === 'undefined') return null

			return this.row[this.col.fieldLink]
		},
	},
}
</script>
