<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Evento">
		<form :id="refForm" :ref="refForm">
			<form-input
				id="title"
				name="title"
				label="Titolo"
				:value="getValue('title')"
				:readonly="!editable"
				required
			></form-input>

			<form-segment
				id="all_day"
				name="all_day"
				label="Tutto il Giorno"
				:options="[
					{ value: 0, label: 'No' },
					{ value: 1, label: 'Si' },
				]"
				:value="getValue('all_day', '0')"
				required
				@change="allDayChange"
				v-if="!!editable"
			></form-segment>
			<form-input
				label="Tutto il Giorno"
				:value="!!getValue('all_day') ? 'Si' : 'No'"
				readonly
				v-else
			></form-input>

			<div class="row" v-if="!!editable">
				<div class="col-lg-6">
					<form-input
						type="date"
						placeholder="YYYY-MM-DD"
						id="start_date"
						name="start_date"
						label="Inizo: Data"
						:value="getValue('start_date_formatted')"
						required
					></form-input>
				</div>
				<div class="col-lg-6">
					<form-input
						type="time"
						placeholder="HH:ii"
						id="start_time"
						name="start_time"
						label="Inizo: Orario"
						:value="getValue('start_time_display')"
						required
						v-if="!isAllDay"
					></form-input>
				</div>
			</div>

			<div class="row" v-if="!!editable">
				<div class="col-lg-6">
					<form-input
						type="date"
						placeholder="YYYY-MM-DD"
						id="end_date"
						name="end_date"
						label="Fine: Data"
						:value="getValue('end_date_formatted')"
						required
					></form-input>
				</div>
				<div class="col-lg-6">
					<form-input
						type="time"
						placeholder="HH:ii"
						id="end_time"
						name="end_time"
						label="Fine: Orario"
						:value="getValue('end_time_display')"
						required
						v-if="!isAllDay"
					></form-input>
				</div>
			</div>

			<div class="row" v-if="!editable">
				<div class="col-lg-6">
					<form-input
						label="Inizo"
						:value="getValue('start_display')"
						readonly
					></form-input>
				</div>
				<div class="col-lg-6">
					<form-input label="Fine" :value="getValue('end_display')" readonly></form-input>
				</div>
			</div>

			<form-autocomplete
				id="user_id"
				name="user_id"
				label="Utente"
				:url="users"
				:value="getValue('user_id', currentUser)"
				:readonly="!editable"
			></form-autocomplete>

			<form-textarea
				id="notes"
				name="notes"
				label="Note"
				:value="getValue('notes')"
				:readonly="!editable"
			></form-textarea>
		</form>

		<template v-slot:footer v-if="!!editable">
			<btn type="success" :icon="['fad', 'check']" title="Salva" :action="submitForm"></btn>
			<btn
				type="danger"
				:icon="['fad', 'times']"
				title="Elimina"
				:action="removeEvent"
				v-if="!!id"
			></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'events',
		refForm: '',
		urlupdate: '',
		urldestroy: '',
		id: '',
		datas: {},
		isAllDay: false,
		editable: true,
	}),
	props: {
		urlstore: {
			type: String,
			default: null,
			required: true,
		},
		reftable: {
			type: String,
			default: null,
			required: false,
		},
		users: {
			type: String,
			default: null,
			required: true,
		},
		currentUser: {
			type: [String, Number],
			default: null,
			required: true,
		},
	},
	created: function() {
		this.refForm = this.ref + 'DataForm'
	},
	methods: {
		close: function() {
			this.assignData({})
			this.urlupdate = ''
			this.urldestroy = ''
			this.editable = true

			this.$refs[this.ref].close()
		},
		open: function(data) {
			if (!!data && typeof data === 'object') {
				if (!!data.urlupdate) this.urlupdate = data.urlupdate
				if (!!data.urldestroy) this.urldestroy = data.urldestroy
				if (typeof data.editable !== 'undefined') this.editable = data.editable
				if (!!data.datas) this.assignData(data.datas)
			} else if (!!data && Array.isArray(data)) {
				this.assignData(data)
			} else {
				this.assignData({})
			}

			this.$refs[this.ref].open()
		},
		getValue: function(key, defaultValue) {
			if (!!this.datas[key]) return this.datas[key]
			else if (!!defaultValue) return defaultValue
			else return ''
		},
		getAction: function() {
			return !!this.id ? this.urlupdate : this.urlstore
		},
		getMethod: function() {
			return !!this.id ? 'PATCH' : 'POST'
		},
		assignData: function(datas) {
			if (!datas) return false

			if (!!Object.keys(datas).length) {
				this.datas = datas
				if (!!datas.id) this.id = datas.id
				this.isAllDay = this.datas.all_day
			} else {
				this.datas = {}
				this.id = ''
			}
		},
		submitForm: function() {
			if (!this.editable) return null

			return this.$root.request(
				this,
				this.getAction(),
				this.getMethod(),
				this.$root.getParamsFromForm(this.$refs[this.refForm]),
				this.submitFormCallback
			)
		},
		submitFormCallback: function(data) {
			if (!!this.reftable) this.$root.tableRefresh(this.reftable)

			let self = this
			Vue.nextTick().then(function() {
				self.close()
			})
		},
		removeEvent: function() {
			if (!this.urldestroy || !this.editable) return null

			return this.$root.request(this, this.urldestroy, 'DELETE', {}, this.submitFormCallback)
		},
		allDayChange: function(value) {
			this.isAllDay = value
		},
	},
}
</script>
