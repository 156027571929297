<template>
	<form v-if="(!!delform || !!postform) && !!href" class="btn-form" method="POST" :action="href">
		<form-input type="hidden" name="_token" :value="token"></form-input>
		<form-input type="hidden" name="_method" :value="formMethod"></form-input>

		<button
			:class="classObject"
			type="submit"
			:id="id"
			:title="!!label ? label : title"
			:disabled="disabled"
		>
			<!-- Loader v-if="loading" -->
			<span class="btn-content-icon" :class="classIconObject" v-if="!!icon && icon.length > 0"
				><font-awesome-icon :icon="icon"></font-awesome-icon
			></span>
			<span class="btn-content-title" v-if="!!title">{{ title }}</span>
		</button>
	</form>
	<a
		:class="classObject"
		:id="id"
		:href="href"
		:title="!!label ? label : title"
		:disabled="disabled"
		v-else-if="!delform && !postform && !!href"
		:target="target"
	>
		<span class="btn-content-icon" :class="classIconObject" v-if="!!icon && icon.length > 0"
			><font-awesome-icon :icon="icon"></font-awesome-icon
		></span>
		<div class="btn-content-image" v-if="!!src">
			<img :src="src" :alt="title" />
		</div>
		<span class="btn-content-title" v-if="!!title">{{ title }}</span>
		<div class="btn-content-badge" v-if="!!badge">{{ badge }}</div>
	</a>
	<button
		:class="classObject"
		:type="!!submit ? 'submit' : 'button'"
		:id="id"
		:title="!!label ? label : title"
		:disabled="disabled"
		@click="clickObject"
		v-else
	>
		<!-- Loader v-if="loading" -->
		<span class="btn-content-icon" :class="classIconObject" v-if="!!icon && icon.length > 0"
			><font-awesome-icon :icon="icon"></font-awesome-icon
		></span>
		<div class="btn-content-image" v-if="!!src">
			<img :src="src" :alt="title" />
		</div>
		<span class="btn-content-title" v-if="!!title">{{ title }}</span>
		<div class="btn-content-badge" v-if="!!badge">{{ badge }}</div>
	</button>
</template>

<script>
export default {
	data: () => ({
		token: '',
	}),
	props: {
		type: {
			type: String,
			default: 'primary',
			required: false,
		},
		icon: {
			type: Array,
			default: () => [],
			required: false,
		},
		size: {
			type: String,
			default: '',
			required: false,
		},
		iconSize: {
			type: String,
			default: '',
			required: false,
		},
		block: {
			type: Boolean,
			default: false,
			required: false,
		},
		bar: {
			type: Boolean,
			default: false,
			required: false,
		},
		header: {
			type: Boolean,
			default: false,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		active: {
			type: Boolean,
			default: false,
			required: false,
		},
		light: {
			type: Boolean,
			default: false,
			required: false,
		},
		table: {
			type: Boolean,
			default: false,
			required: false,
		},
		href: {
			type: String,
			default: '',
			required: false,
		},
		id: {
			type: String,
			default: '',
			required: false,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		action: {
			type: Function,
			default: null,
			required: false,
		},
		submit: {
			type: Boolean,
			default: false,
			required: false,
		},
		src: {
			type: String,
			default: '',
			required: false,
		},
		badge: {
			type: String,
			default: '',
			required: false,
		},
		postform: {
			type: Boolean,
			default: false,
			required: false,
		},
		delform: {
			type: Boolean,
			default: false,
			required: false,
		},
		formid: {
			type: String,
			default: '',
			required: false,
		},
		target: {
			type: String,
			default: '',
			required: false,
		},
		/*loading: {
        type: Boolean,
        default: false,
        required: false
      },*/
	},
	computed: {
		classObject: function() {
			return {
				btn: true,
				['btn-' + this.type]: !this.light,
				'btn-icon': !!this.icon && !this.title,
				'btn-sm': this.size == 'small',
				'btn-lg': this.size == 'large',
				'btn-block': !!this.block,
				'btn-bar': !!this.bar,
				'btn-table': !!this.table,
				'btn-header': !!this.header,
				'btn-disabled': !!this.disabled,
				active: !!this.active,
				['btn-outline-' + this.type]: !!this.light,
			}
		},
		classIconObject: function() {
			return {
				'icon-large': this.iconSize == 'large',
				'icon-small': this.iconSize == 'small',
			}
		},

		formMethod: function() {
			if (!!this.delform) return 'DELETE'
			if (!!this.postform) return 'POST'
			return null
		},
	},
	methods: {
		clickObject: function() {
			if (!!this.loading || !!this.disabled) return false

			if (!!this.formid) {
				let confirmation = confirm('Sei sicuro di voler continuare?')
				if (confirmation == true) return this.$root.submitForm(this.formid)
				else return false
			} else if (!!this.action) {
				return this.action.apply(this)
				this.loading = false
			}
		},
	},
	created: function() {
		if (!!this.delform || !!this.postform) {
			let token = document.head.querySelector('meta[name="csrf-token"]')

			if (token) this.token = token.content
		}
	},
}
</script>
