<template>
	<div :id="id" class="block-header" v-if="!!$slots.default">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: '',
			required: false,
		},
	},
}
</script>
