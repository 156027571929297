<template>
	<div class="form-group">
		<label v-if="!!label">{{ label }} <span class="form-required" v-if="!!required">*</span></label>
		<br />
		<div
			class="custom-segment"
			:class="{
				'is-invalid': !!error,
				disabled: !!disabled,
			}"
			:style="{ width: width }"
		>
			<div
				v-for="option in options"
				class="custom-segment-button"
				:class="{ checked: internalValue == getValue(option) }"
			>
				<input
					type="radio"
					class="custom-segment-input"
					:id="name + '_' + getValue(option)"
					:name="name"
					:value="getValue(option)"
					:required="required"
					:disabled="disabled"
					:readonly="readonly"
					v-model="internalValue"
					@change="emitEvent"
				/>
				<label class="custom-segment-label" :for="name + '_' + getValue(option)">
					{{ getLabel(option) }}
				</label>
			</div>
		</div>
		<span class="invalid-feedback" role="alert" v-if="!!error">
			<strong>{{ error }}</strong>
		</span>
	</div>
</template>

<script>
export default {
	data: () => ({
		internalValue: null,
	}),
	props: {
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		width: {
			type: String,
			default: '',
			required: false,
		},
		name: {
			type: String,
			default: '',
			required: false,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		itemValue: {
			type: String,
			default: 'value',
			required: false,
		},
		itemLabel: {
			type: String,
			default: 'label',
			required: false,
		},
		options: {
			default: () => [],
			type: Array,
			required: true,
		},
		value: {
			type: [String, Number],
			default: '',
			required: false,
		},
		error: {
			type: String,
			default: '',
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
			required: false,
		},
		required: {
			type: Boolean,
			default: false,
			required: false,
		},
		tableRef: {
			type: String,
			default: null,
			required: false,
		},
		tableFilterType: {
			type: String,
			default: null,
			required: false,
		},
	},
	created: function() {
		this.internalValue = this.value
	},
	methods: {
		getLabel: function(option) {
			return option[this.itemLabel]
		},
		getValue: function(option) {
			return option[this.itemValue]
		},
		emitEvent: function() {
			this.$emit('change', this.internalValue)

			if (!this.tableRef) return false

			this.$root.updateFilterTable(
				this.tableRef,
				this.tableFilterType,
				this.name,
				this.internalValue
			)
		},
		reset: function() {
			this.internalValue = null
			this.emitEvent()
		},
	},
}
</script>
