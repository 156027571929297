<template>
	<th @click="sortClick" :class="classObject" :colspan="item.colspan">
		{{ getTitle }}
		<span class="sort-icon">
			<font-awesome-icon
				:icon="['fad', 'sort-amount-up']"
				v-if="!!isActive && !!isSortAsc"
			></font-awesome-icon>
			<font-awesome-icon
				:icon="['fad', 'sort-amount-down']"
				v-if="!!isActive && !!isSortDesc"
			></font-awesome-icon>
		</span>
	</th>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			default: null,
			required: true,
		},
		index: {
			type: Number,
			default: 0,
			required: true,
		},
		orderingEnabled: {
			type: Boolean,
			default: true,
			required: false,
		},
		ordering: {
			type: Object,
			default: null,
			required: false,
		},
		groupField: {
			type: String,
			default: null,
			required: false,
		},
		editable: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	computed: {
		getTitle: function() {
			if (!this.item.title) return null

			if (!!this.groupField && !this.editable && this.item.data == this.groupField)
				return null

			return this.item.title
		},
		classObject: function() {
			return {
				'headline-font': true,
				sorting: !!this.isSortable,
				active: !!this.isActive,
			}
		},
		name: function() {
			return !!this.item.formData
				? this.item.formData
				: !!this.item.data
				? this.item.data
				: null
		},
		isSortable: function() {
			return !!this.orderingEnabled && !!this.item.orderable && !!this.name
		},
		isActive: function() {
			if (!this.ordering || this.ordering.field !== this.name) return false

			return true
		},
		isSortAsc: function() {
			if (
				!!this.ordering &&
				this.ordering.field === this.name &&
				this.ordering.sort.toUpperCase() == 'ASC'
			)
				return true

			return false
		},
		isSortDesc: function() {
			if (
				!!this.ordering &&
				this.ordering.field === this.name &&
				this.ordering.sort.toUpperCase() == 'DESC'
			)
				return true

			return false
		},
	},
	methods: {
		sortClick: function() {
			if (
				!this.orderingEnabled ||
				!this.item.orderable ||
				(!this.item.data && !this.item.formData)
			)
				return false

			this.$parent.sortColumn(this.name)
		},
	},
}
</script>
